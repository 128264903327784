/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS613P
 * 화면 설명: 지정대리청구인 상이 동의팝업
 */
<template> 
  <ur-page-container class="msp" title="지정대리청구인 상이 동의" :show-title="true" ref="page" type="subpage" :topButton="true" @on-header-left-click="fn_HeaderBackBtnHandler">
    
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="pb50">
        <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="pal1624">
          <span class="fs22rem fwb" v-if="currentStep === 1">계약체결•이행 동의</span>
          <span class="fs22rem fwb" v-if="currentStep === 2">본인인증</span>
          <div><span class="fs20rem crTy-blue2 fwb mr2">{{currentStep}}</span>/<span class="ml2">2</span></div>
        </ur-box-container>
        <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="bd-T-Ty1 h10px bgcolor-3"></ur-box-container>
        <ur-box-container alignV="start" componentid="" direction="column" class="con-area mt30">
          <div class="fs18rem fwm">지정대리청구인</div>
          <div class="full">
            <span>{{selectedValue.label}}</span><!-- 지정대리청구인 한명일 경우 -->
          </div>
          <div class="mt10 sub-text"><span></span>
            <span>{{InfoData.data.knb.length == 13 ? fn_GetInsuranceAge(InfoData.data.knb) : ''}}세</span><em>|</em><span>{{InfoData.data.knb.length == 13 ? InfoData.data.knb.substring(0, 6) : ''}}-{{InfoData.data.knb.length == 13 ? selectedValue.custRrnId.substring(6, 7) + '******' : ''}}</span>
          </div>
          <div class="gray-bar full48 mt30"></div>
        </ur-box-container>

        <ur-box-container v-if="selectedValue.isMinor" alignV="start" componentid="" direction="column" class="con-area mt30">
          <div class="fs18rem fwm">친권자</div>
          <div class="w100">
            <mo-text-field class="full" ref="inputName" underline v-model="inputName" placeholder="이름을 입력하세요" :disabled="allUserAuthFinish" :rules="validateRuleName"/>
            <div class="verify-birth mt20">
              <mo-text-field ref="inputBirthday1" v-model="inputBirthday1" type="number" mask="######" underline class="birth-input1 form-input-name" placeholder="생년월일" @input="fn_BirthFocusBlur" @blur="fn_BirthFocusBlur" :disabled="allUserAuthFinish" :rules="validateRuleKnbFrno"/>
              <span>-</span>
              <mo-text-field ref="inputBirthday2" v-model="inputBirthday2" type="number" mask="#" underline class="birth-input2 form-input-name w20px non-icon" placeholder="0" :rules="validateRuleKnbBkno"/>
              <em class="password">●●●●●●</em>
            </div>
          </div>
          <div class="gray-bar full48 mt30"></div>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="con-area mt30 bd-b-Ty1 pb30">
          <div class="chk-box">
            <div class="fwb fs16rem ls--10">
              계약체결ᆞ이행 등을 위한  동의, 개인(신용)정보 동의 관련 추가확인 사항,전자적 방법에 의한 계약체결동의 및 보험계약 서류 수령동의에 대한 전체동의
            </div>
            <div class="ns-check mt10 fexTy2">
              <mo-checkbox v-model="cardOne.isAllAgree" @input="fn_OnSelectAgreeAll">전체동의</mo-checkbox>
            </div>
          </div>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="" direction="column" class="con-area bd-b-Ty1 bgcolor-3 pt20 pb20"><!-- dsN : display:none -->
          <div class="fs16rem fwb">계약체결ᆞ이행 등을 위한  동의</div>
          <div class="fs14rem crTy-bk7 mt6">귀하는 개인(신용)정보의 수집ᆞ이용 및 조회, 제공에 관한 동의를 거부하실 수 있으며, 개인의 신용도 등을 평가하기 위한 목적 이외의 개인(신용)정보 제공 동의는 철회할 수 있습니다.다만, 
            <span class="crTy-orange2">본 동의는 ‘보험계약 인수심사 ᆞ체결 ᆞ이행 ᆞ유지 ᆞ관리’를 위해 필수적인 사항이므로 동의를 거부하시는 경우 관련 업무수행이 불가능합니다.</span>
          </div>
        </ur-box-container>

        <msp-expand ref="mspExpCardOne1" btn-area-first title-first expand-only-btn expanded class="mo-list-expand agree-expand" btn-area-class="fexTy3"  > 
          <template #title>
            <div class="ns-check fexTy2">
              <mo-checkbox v-model="cardOne.agreeCheckboxList" value="1" @input="fn_ClickedCheck(0)"><span class="fs16rem">{{requiredAgreementContent[0].title}}</span></mo-checkbox>
            </div>
          </template>
          <template #btn>
              <mo-button class="link-arrow down"></mo-button>
          </template>
          <template #content>
            <div class="con-area maxh200px">
              <div class="">
                <div class="fs14rem fwb">수집ᆞ이용 목적</div>
                <div class="fs14rem crTy-bk7 mt6">보험계약의 인수심사ᆞ체결ᆞ이행ᆞ유지ᆞ관리(부활 및 갱신 포함) ᆞ상담, 보험계약대출의 체결ᆞ유지ᆞ관리, 보험금 등 지급ᆞ심사, 위ᆞ수탁사무의 수행, 조사연구(리서치), 서비스 제공, 순보험요율의 산출ᆞ검증, 조사연구(리서치),서비스 제공, 순보험요율의 산출ᆞ검증, 보험원가관리,보험모집질서의 유지, 보험사기 조사, 분쟁대응 및 민원처리 적부 및 사고조사(보험사기 조사 포함), 법률 및 국제 협약 등의 의무 이행, 금융거래 관련업무(보험계약, 보험금청구, 보험계약대출,전자금융서비스 관련신청ᆞ해지ᆞ변경, 이체(자동-R/T 이체 포함),입출금 업무 등), 가입한 보험계약 상담, 새로운 보험계약 체결 시 기존 보험계약과의 중요사항 비교설명, 이상금융거래 탐지 등 금융사고 방지</div>
              </div>

              <div class="mt20">
                <div class="fs14rem fwb">보유 및 이용기간</div>
                <div class="fs14rem crTy-bk7 mt6">동의일로부터 거래종료 후 5년까지(단 다른 관련 법령에 해당하는 경우 해당 법령상의 보존기간을 따름)
  ※ 상기 ‘거래종료일＇이란 당사와의 모든 거래관계(보험,융자,수익증권,신탁,퇴직연금 등)에 대한＂①계약만기, 해지,취소,철회일 또는 소멸일,②보험금 청구권 소멸시효 완성일(상법 제662조)③채권ᆞ채무관계 소멸일 중 가장 나중에 도래한 사유를 기준으로 판단한날”을 뜻합니다.
                </div>
              </div>

              <div class="mt30">
                <div class="fs14rem crTy-bk7">■ 수집ᆞ이용 항목</div>
                <div class="fs14rem fwb mt10">고유식별정보</div>
                <div class="fs14rem crTy-bk7 mt6">
                  <span class="crTy-blue4 underline">주민등록번호, 외국인등록번호, 운전면허번호</span> 위 고유식별정보 수집ᆞ이용에 동의하십니까?                
                </div>
                <div class="ns-radio-list mt6 fexTy2">
                  <mo-radio v-model="cardOne.agreeRadioList[0]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[0]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                </div>
              </div>

              <div class="mt20">
                <div class="fs14rem fwb">민감정보</div>
                <div class="fs14rem crTy-bk7 mt6">
                  <span class="crTy-blue4 underline">피보험자의 질병ᆞ상해에 관한 정보(상병명,진료기록,기왕증 등),진료기록(건강검진 포함)</span> 위 민감정보 제공에 동의하십니까?                
                </div>
                <div class="ns-radio-list mt6 fexTy2">
                  <mo-radio v-model="cardOne.agreeRadioList[1]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[1]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                </div>
              </div>

              <div class="mt20">
                <div class="fs14rem fwb">개인(신용)정보</div>
                <div class="fs14rem mt6">[일반개인정보]</div>
                <div class="fs14rem crTy-bk7 mt4">성명,주소,전화번호,이메일,직업,직장,가족관계,취미,CI(연계정보)</div>
                <div class="fs14rem mt10">[신용거래정보]</div>
                <div class="fs14rem crTy-bk7 mt4">당사ᆞ타보험사(체신관서(우체국보험),공제사업자 포함) ᆞ신용정보집중기관 및 보험요율 산출기관의 보험계약정보(상품종류, 기간, 보험료, 보험가입금액 등) 및 보험금 지급관련정보(사고정보,본인의 위임을 받아 취득한 각종 조사서, 판결문,증명서,확인서 등),손해사정업무 및 그에 부수하는 업무 수행을 위해 필요한 정보(손해사정사 등),계약 전 알릴의무사항,법률 및 국제협약 등의 이무이행을 위한 정보,금융거래 관련 이체 및 입ᆞ출금정보(계좌개설 금융기관,예금주,계좌번호,이체내역,신청ᆞ해지 및 출금동의 관련정보 등)보험계약대출정보(대출실행일,대출금,금리,상환일자,대출번호 등)</div>
                <div class="fs14rem mt10">[신용능력정보]</div>
                <div class="fs14rem crTy-bk7 mt4">소득 및 재산정보</div>
                <div class="fs14rem mt10">[신용도판단정보]</div>
                <div class="fs14rem crTy-bk7 mt4">압류 및 체납처분 관련 정보
                  <span class="crTy-blue4 underline">위 고유식별정보 수집ᆞ이용에 동의하십니까?</span>
                </div>
                <div class="ns-radio-list mt6 fexTy2">
                  <mo-radio v-model="cardOne.agreeRadioList[2]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[2]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                </div>
              </div>
            </div>

          </template>
        </msp-expand>
        <!-- row01 end -->

        <msp-expand ref="mspExpCardOne2" btn-area-first title-first expand-only-btn expanded class="mo-list-expand agree-expand" btn-area-class="fexTy3"  > 
          <template #title>
            <div class="ns-check fexTy2">
              <mo-checkbox v-model="cardOne.agreeCheckboxList" value="2" @input="fn_ClickedCheck(1)"><span class="fs16rem">{{requiredAgreementContent[1].title}}</span></mo-checkbox>
            </div>
          </template>
          <template #btn>
              <mo-button class="link-arrow down"></mo-button>
          </template>
          <template #content>
            <div class="con-area maxh200px">
              <div class="">
                <div class="fs14rem fwb">제공받는자</div>
                <div class="fs14rem crTy-blue4 underline mt6">
                  국가기관 등 : 법원,검찰,경찰,국세청,금융위원회,금융감독원,행정안전부,서민금융진흥원, 보험요율 산출기관 등 법령상 업무수행기관 | 금융거래기관 : 계좌개설 금융기관,은행연합회,금융결제원 | 종합신용정보집중기관 : 한국신용정보원 | 신용정보회사 등 : NICE신용평가정보, 코리아크레딧뷰로 등 신용정보회사 | 보험회사 등 : 생명ᆞ손해보험회사,국내 재보험사,공제사업자,체신관서(우체국포함) | 보험협회 : 생명ᆞ손해보험협회
                </div>
              </div>

              <div class="mt20">
                <div class="fs14rem fwb">제공받는자의 이용목적</div>
                <div class="fs14rem crTy-blue4 underline mt6">
                  국가기관 등 : 보험업법,신용정보법 등 법령에 따른 업무수행(위탁업무,보험금 찾아주기,휴면보험금 출연 등 포함)| 금융거래기관 : 금융거래 업무| 종합신용정보집중기관 : 보험계약ᆞ보험계약대출 및 보험금 지급 관련 정보의 집중관리 및 활용 등 기관의 업무, 새로운 보험계약 체결 시 기존 보험계약과의 중요사항 비교설명 등 법령상 의무수행 지원 | 신용정보회사 등 : 당사요청 정보제공을 위한 대상자 식별| 보험회사 등 : 중복보험 확인 및 비례보상,재보험 가입 및 재보험금 청구,보험계약 공동인수, 비과세 상품의가입한도 확인 | 보험협회 : 보험계약관련 업무지원,공정경쟁 질서유지에 관한 협정업무
                </div>
              </div>

              <div class="mt20">
                <div class="fs14rem fwb">보유 및 이용기간</div>
                <div class="fs14rem crTy-blue4 underline mt6">
                  제공받는자의 이용목적을 달성할 때까지(관련 법령상 보존기간을 따름)
                </div>
                <div class="fs14rem crTy-blue4 underline mt10">
                  ※ 외국 재보험사의 국내지점이 재보험계약 가입 판단 지원, 보험계약 공동인수 지원업무를 위탁하기 위한 경우 별도의 동의 없이 외국소재본점에 귀하의 정보를 이전할 수 있습니다.
                </div>
              </div>

              <div class="mt30">
                <div class="fs14rem crTy-bk7">■ 제공 항목</div>
                <div class="fs14rem fwb mt10">고유식별정보</div>
                <div class="fs14rem crTy-bk7 mt6">
                  <span class="crTy-blue4 underline">주민등록번호, 외국인등록번호, 운전면허번호</span> 위 고유식별정보 수집ᆞ이용에 동의하십니까?                
                </div>
                <div class="ns-radio-list mt6 fexTy2">
                  <mo-radio v-model="cardOne.agreeRadioList[3]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[3]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                </div>
              </div>

              <div class="mt20">
                <div class="fs14rem fwb">민감정보</div>
                <div class="fs14rem crTy-bk7 mt6">
                  <span class="crTy-blue4 underline">피보험자의 질병ᆞ상해에 관한 정보(상병명,진료기록,기왕증 등)</span> 위 민감정보 제공에 동의하십니까?                
                </div>
                <div class="ns-radio-list mt6 fexTy2">
                  <mo-radio v-model="cardOne.agreeRadioList[4]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[4]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                </div>
              </div>

              <div class="mt20">
                <div class="fs14rem fwb">개인(신용)정보</div>
                <div class="fs14rem crTy-bk7 mt6">
                  위 『수집ᆞ이용 항목』 의 개인(신용)정보(단,각 제공받는자의 이용목적을 위해 필요한 정보에 한함)<br>
                  위 개인신용정보 제공에 동의하십니까?               
                </div>
                <div class="ns-radio-list mt6 fexTy2">
                  <mo-radio v-model="cardOne.agreeRadioList[5]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[5]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                </div>
              </div>

              <div class="mt20">
                <div class="fs14rem crTy-bk7">
                  ※ 향후 보험계약체결ᆞ유지ᆞ관리 및 서비스 제공 등을 위하여 불가피한 경우 제공받는 자, 제공받는자의 이용목적, 제공되는 저옵의 항목 등이 추가 또는 변동될 수 있습니다. 이 경우 추가 또는 변동되는 사항을 삼성생명 홈페이지를 통하여 알려드립니다.
                </div>
              </div>
            </div>

          </template>
        </msp-expand>
        <!-- row02 end -->

        <msp-expand ref="mspExpCardOne3" btn-area-first title-first expand-only-btn expanded class="mo-list-expand agree-expand" btn-area-class="fexTy3"  > 
          <template #title>
            <div class="ns-check fexTy2">
              <mo-checkbox v-model="cardOne.agreeCheckboxList" value="3" @input="fn_ClickedCheck(2)"><span class="fs16rem">{{requiredAgreementContent[2].title}}</span></mo-checkbox>
            </div>
          </template>
          <template #btn>
              <mo-button class="link-arrow down"></mo-button>
          </template>
          <template #content>
            <div class="con-area maxh200px">
              <div class="">
                <div class="fs14rem fwb">제공받는자</div>
                <div class="fs14rem crTy-blue4 underline mt6">
                  국외 재보험사
                </div>
              </div>

              <div class="mt20">
                <div class="fs14rem fwb">제공받는자의 이용목적</div>
                <div class="fs14rem crTy-blue4 underline mt6">
                  보험계약의 인수심사,보험계약 공동인수,재보험 가입 및 재보험금 청구
                </div>
              </div>

              <div class="mt20">
                <div class="fs14rem fwb">보유 및 이용기간</div>
                <div class="fs14rem crTy-blue4 underline mt6">
                  제공받는자의 이용목적을 달성할 때까지(관련 법령상 보존기간을 따름)
                </div>
              </div>

              <div class="mt30">
                <div class="fs14rem crTy-bk7">■ 제공 항목</div>
                <div class="fs14rem fwb mt10">고유식별정보</div>
                <div class="fs14rem crTy-bk7 mt6">
                  <span class="crTy-blue4 underline">주민등록번호, 외국인등록번호, 운전면허번호</span> 위 고유식별정보 수집ᆞ이용에 동의하십니까?                
                </div>
                <div class="ns-radio-list mt6 fexTy2">
                  <mo-radio v-model="cardOne.agreeRadioList[6]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[6]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                </div>
              </div>

              <div class="mt20">
                <div class="fs14rem fwb">민감정보</div>
                <div class="fs14rem crTy-bk7 mt6">
                  <span class="crTy-blue4 underline">피보험자의 질병ᆞ상해에 관한 정보(상병명,진료기록,기왕증 등)</span> 위 민감정보 제공에 동의하십니까?                
                </div>
                <div class="ns-radio-list mt6 fexTy2">
                  <mo-radio v-model="cardOne.agreeRadioList[7]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[7]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                </div>
              </div>

              <div class="mt20">
                <div class="fs14rem fwb">개인(신용)정보</div>
                <div class="fs14rem crTy-bk7 mt6">
                  위 『수집ᆞ이용 항목』 의 개인(신용)정보(단,각 제공받는자의 이용목적을 위해 필요한 정보에 한함)<br>
                  위 개인신용정보 제공에 동의하십니까?
                </div>
                <div class="ns-radio-list mt6 fexTy2">
                  <mo-radio v-model="cardOne.agreeRadioList[8]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[8]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                </div>
              </div>
            </div>

          </template>
        </msp-expand>
        <!-- row03 end -->

        <msp-expand ref="mspExpCardOne4" btn-area-first title-first expand-only-btn expanded class="mo-list-expand agree-expand" btn-area-class="fexTy3"  > 
          <template #title>
            <div class="ns-check fexTy2">
              <mo-checkbox v-model="cardOne.agreeCheckboxList" value="4" @input="fn_ClickedCheck(3)"><span class="fs16rem">{{requiredAgreementContent[3].title}}</span></mo-checkbox>
            </div>
          </template>
          <template #btn>
              <mo-button class="link-arrow down"></mo-button>
          </template>
          <template #content>
            <div class="con-area maxh200px">
              <div class="">
                <div class="fs14rem fwb">조회대상 기관</div>
                <div class="fs14rem crTy-bk7 mt6">
                  종합신용정보집중기관 : 한국신용정보원 | 신용정보회사 등 : NICE신용평가정보, 코리아크레딧뷰로 등 신용정보회사 | 보험요율산출기관 : 보험개발원 | 세금우대저축자료집중기관 : 은행연합회
                  <br>* 조회대상기관의 구체적인 정보 및 조회목적은 삼성생명 홈페이지에서 확인할 수 있습니다.
                </div>
              </div>

              <div class="mt20">
                <div class="fs14rem fwb">조회목적</div>
                <div class="fs14rem crTy-bk7 mt6">
                  보험계약 체결ᆞ유지ᆞ관리(부활 및 갱신 포함) ᆞ상담, 보험계약대출 체결ᆞ유지ᆞ관리, 보험금 등 지급ᆞ심사, 적부 및 사고조사(보험사기 조사 포함), 새로운 보험계약 체결 시 기존 보험계약과의 중요사항 비교설명
                </div>
              </div>

              <div class="mt20">
                <div class="fs14rem fwb">조회동의의 효력기간</div>
                <div class="fs14rem crTy-bk7 mt6">
                  위 조회결과는 귀하와의 보험거래가 개시되는 경우 해당 보험거래종료일까지 동의의 효력이 지속됩니다.
                  <br>다만,귀하가 신청한 보험 거래의 설정이 거절된 경우에는 그 시점부터 동의의 효력은 소멸합니다.
                </div>
              </div>

              <div class="mt30">
                <div class="fs14rem crTy-bk7">■ 조회 항목</div>
                <div class="fs14rem fwb mt10">고유식별정보</div>
                <div class="fs14rem crTy-bk7 mt6">
                  <span class="crTy-blue4 underline">주민등록번호, 외국인등록번호, 운전면허번호</span> 위 고유식별정보 수집ᆞ이용에 동의하십니까?                
                </div>
                <div class="ns-radio-list mt6 fexTy2">
                  <mo-radio v-model="cardOne.agreeRadioList[9]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[9]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                </div>
              </div>

              <div class="mt20">
                <div class="fs14rem fwb">민감정보</div>
                <div class="fs14rem crTy-bk7 mt6">
                  <span class="crTy-blue4 underline">피보험자의 질병ᆞ상해에 관한 정보(상병명,진료기록,기왕증 등)</span> 위 민감정보 제공에 동의하십니까?                
                </div>
                <div class="ns-radio-list mt6 fexTy2">
                  <mo-radio v-model="cardOne.agreeRadioList[10]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[10]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                </div>
              </div>

              <div class="mt20">
                <div class="fs14rem fwb">개인(신용)정보</div>
                <div class="fs14rem mt6">[일반개인정보]</div>
                <div class="fs14rem crTy-bk7 mt4">성명,주소,전화번호,이메일,직업,직장</div>
                <div class="fs14rem mt10">[신용거래정보]</div>
                <div class="fs14rem crTy-bk7 mt4">
                  보험계약정보(상품종류, 기간, 보험료, 보험가입금액 등) 및 보험금지급정보(사고정보 포함),연금저축/비과세/세금우대종합저축/비과세종합저축 가입총액 및 내역, 신용정보주체의 대출/보증 등 거래내용,신용도,재산 등 신용거래 능력을 판단할 수 있는 신용정보, 신용등급ᆞ신용평점 등 신용정보를 가공한 정보
                </div>
                <div class="fs14rem mt10">[신용능력정보]</div>
                <div class="fs14rem crTy-bk7 mt4">소득 및 재산정보
                  <br>위 개인신용정보 제공에 동의하십니까?
                </div>
                <div class="ns-radio-list mt6 fexTy2">
                  <mo-radio v-model="cardOne.agreeRadioList[11]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[11]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                </div>
              </div>
            </div>

          </template>
        </msp-expand>
        <!-- row04 end -->

        <msp-expand ref="mspExpCardOne5" btn-area-first title-first expand-only-btn expanded class="mo-list-expand agree-expand" btn-area-class="fexTy3"  > 
          <template #title>
            <div class="ns-check fexTy2">
              <mo-checkbox v-model="cardOne.agreeCheckboxList" value="5" @input="fn_ClickedCheck(4)"><span class="fs16rem">{{requiredAgreementContent[4].title}}</span></mo-checkbox>
            </div>
          </template>
          <template #btn>
              <mo-button class="link-arrow down"></mo-button>
          </template>
          <template #content>
            <div class="con-area maxh200px">
              <div class="">
                <div class="fs14rem crTy-bk7 mt6">
                  최근 1년내 실손/가입설계를 위한 개인 신용정보 처리 동의서에 직접 동의하였습니까?               
                </div>
                <div class="ns-radio-list mt6 fexTy2">
                  <mo-radio v-model="cardOne.agreeRadioList[12]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[12]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                </div>
                <div class="mt20 bd-b-Ty1 full48" />
              </div>

              <div class="mt20">
                <div class="fs14rem fwb">전자적 방법에 의한 계약체결 동의</div>
                <div class="ns-radio-list mt6 fexTy2">
                  <mo-radio v-model="cardOne.agreeRadioList[13]" value="1" class="fs16rem crTy-bk1">동의함</mo-radio> <mo-radio v-model="cardOne.agreeRadioList[13]" value="0" class="fs16rem crTy-bk1">동의안함</mo-radio>
                </div>
                <div class="fs14rem crTy-bk7 mt10">
                  본인은 다음과 같이 전자서명을 통해 보험계약을 체결하는데 동의합니다.               
                </div>

                <div class="fs14rem crTy-bk7 mt20 bd-b-Ty1 bd-T-Ty1 txt-center pt10 pb10">다음</div>
                <div class="fs14rem crTy-bk7 mt10">
                  보험계약자 또는 피보험자의 자필서명이 필요한 사항(보험청약서, 계약 전 알릴사항, 상품설명서 등)에 대해서는 전자서명법 제2조 제2호의 전자서명 방식에 의해 보험계약을 청약합니다.
보험계약체결의 모든 과정에서 삼성생명보험주식회사(이하＂회사＂라 합니다.)가 제공하는 전자서명시스템을 이용하여 보험계약을 체결합니다.
                </div>
                <div class="fs14rem crTy-bk7 mt10 pb10 bd-b-Ty1">
                  <span class="indent10">- 전자문서 외에 서면문서를 이용하여 청약서를 작성하실 수 있습니다.</span>
              <br><span class="indent10">- 전자서명을 통한 보험계약 체결 후 삼성생명 홈페이지(www.samsunglife.com)에서 전자문서, 계약처리단계 및 담당자 정보를 조회ᆞ출력하실 수 있습니다.</span>
              <br><span class="indent10">- 계약자가 청약서 부본,상품설명서 및 약관의 서면교부를 요청하는 경우, 회사가 전자적 방법으로 교부할 수 없는 경우 보험계약자에게 우편발송 또는 직접 제공해 드립니다.</span>
                </div>
              </div>
            </div>

          </template>
        </msp-expand>
        <!-- row05 end -->
        <div class="fs14rem crTy-bk7 mt10 pb10 bd-b-Ty1" style="padding-left: 20px; padding-right: 20px;">
              <span class="indent10">※ 당사는 완전히 자동화된 시스템을 통해 개인정보를 처리하여 보험계약의 인수 및 보험금 지급에 대한 결정을 수행할 수 있음을 안내드립니다.</span>
              <br><span class="indent10">※ 위 개인정보 제공 동의하시는 경우, 계약 관계자가 각각 기재하여 주시기 바랍니다.</span>
              <br><span class="indent10">※ 미성년인 계약관련자가 복수이고 각각의 법정대리인이 다를 경우에는 모두 동의하셔야 합니다.</span>
              <br><span class="indent10">※ 미성년자인 경우, 친권자 또는 후견인이 기재 바랍니다. 부모가 공동친권자인 경우 부모 모두가 각각 성명 기재하여야 합니다. 다만, 다른 일방의 의사에 반하지 않을 경우 부모 중 일방이 부모 공동명의로 동의할 수 있습니다.</span>
        </div>
        <div class="con-area pt16 pb16">
          <span class="fs16rem">[CISO 심의필 2024-042]</span>
        </div>

        <template v-if="cardOne.isAllAgree">
          <ur-box-container alignV="start" componentid="" direction="column" class="con-area mt30 pb30">
            <div class="fs18rem fwm">본인인증</div>
            <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt10">
              <mo-segment-wrapper solid primary v-model="authType1[0].key" class="chip-type-wrap chip-ty1" :disabled="!cardOne.isAllAgree">
                <mo-segment-button v-for="(item, idx) in authTypes" :value="item.key" :key="idx">{{item.label}}</mo-segment-button>
              </mo-segment-wrapper>
            </ur-box-container>

            <MSPPS401D ref="MSPPS401D" v-if="authType1[0].key === '1'" :disabled="!cardOne.isAllAgree || disabled" :bnfrInfoData="selectedValue" @authFinish="fn_AuthFinish" @authConfirmBtnClick="fn_AuthConfirmBtnClick" />
            <MSPPS402D ref="MSPPS402D" v-else-if="authType1[0].key === '2'" :disabled="!cardOne.isAllAgree  || disabled" :bnfrInfoData="selectedValue" @authFinish="fn_AuthFinish" @authConfirmBtnClick="fn_AuthConfirmBtnClick" />

          </ur-box-container>
          
        </template>
        
      </ur-box-container>
    </ur-box-container>
    <!-- Content 영역 end -->


    <ur-box-container direction="column" align-v="start" class="ns-agreeArea2">
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <!-- <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round gray" :disabled="allUserAuthFinish" @click="fn_Cancel">취소</mo-button> -->
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Confirm" :disabled="!allUserAuthFinish">확인</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
   

  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import PSCommUtil from '@/ui/ps/comm/PSCommUtil' 
import MSPPS401D from '@/ui/ps/MSPPS401D'
import MSPPS402D from '@/ui/ps/MSPPS402D'
import moment from 'moment'

export default {

/***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS613P', 
  screenId: 'MSPPS613P',
  isBackKeyHandle : true , // 팝업일때 디바이스 백키 직접 핸들 처리 여부 옵션

  components: {
    'MSPPS401D' : MSPPS401D, //전자서명 인증 - 휴대폰
    'MSPPS402D' : MSPPS402D //전자서명 인증 - 신용카드
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    itemData: {type: Object}
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {   
      disabled: false,
      validateRuleName: [
        v => !!v || '이름은 필수입력항목입니다.'
      ],
      validateRuleKnbFrno: [
        v => !!v || '필수입력항목입니다',
        v => v.length > 5 || '생년월일을 입력하세요.'
      ],
      validateRuleKnbBkno: [
        v => !!v || '필수입력항목입니다'
      ],
      currentStep: 1,
      opened: {a: true, b: true, c: true, d: true, e: true}, // 지정대리청구인 약관 오픈 여부
      inputName: '',  // 친권자 이름
      inputBirthday1: '', //친권자 생년월일
      inputBirthday2: '', //주민번호 7번째자리
      selectedValue: { key: '', label: '' },
      //selectedData: [],
      cardOne: {
        isAllAgree: false,
        agreeRadioList: ['', '', '', '', '', '', '', '', '', '', '', '', '', ''], // 14
        allItemsRadio: ['1', '1', '1', '1', '1', '1', '1', '1', '1', '1', '1', '1', '1', '1'],
        subItemsRadio: [['1', '1', '1'],['1', '1', '1'],['1', '1', '1'],['1', '1', '1'],['1', '1']],
        agreeCheckboxList: [],
      },
      authTypes: [
        {key: '1', label: '휴대폰'},
        {key: '2', label: '신용카드'}
      ],
      authType1: [{key: '1', label: '휴대폰'}],
      vuchId: '', // 영수증번호
      elstPlanId: '', // 설계 ID
      InfoData: {}, // 지정대리청구인 정보
      bnfrInfoData: {},
      standardAge: 19, // 미성년자 기준 나이
      allUserAuthFinish: false, // 모든 지정대리청구인 본인인증을 했는지 여부
      //parentalAuthorityInfo: false, // 지정대리청구인 미성년자일때 친권자 정보(성명, 생년월일) 입력여부(true:입력, false:미입력)
      authFinishFlag: false, // 지정대리청구인 인증 요청후 DB 저장 함수가 두번씩 call 되는 현상 방지 플래그
      requiredAgreementContent: [
        {
          opened: true,
          title: '1. 수집·이용에 관한 사항'
        },
        {
          opened: true,
          title: '2. 제공에 관한 사항'
        },
        {
          opened: true,
          title: '3. [국외] 제공에 관한 사항'
        },
        {
          opened: true,
          title: '4. 조회에 관한 사항'
        },
        {
          opened: true,
          title: '5. 개인(신용)정보 동의 관련 추가확인 사항'
        },
        {
          opened: true,
          title: '전자적 방법에 의한 계약체결 동의',
          content: ''
        }
      ]
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {    
    // backButton event 등록
    //상단 isBackKeyHandle : true ,  팝업일때 디바이스 백키 직접 핸들 처리 여부 옵션 있어야 핸들링 가능
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler613P)

    if (this.itemData && this.itemData.vuchId && this.itemData.elstPlanId && this.itemData.InfoData) {
      this.vuchId = this.itemData.vuchId // 영수증번호, 전자문서ID
      this.elstPlanId = this.itemData.elstPlanId // 설계 ID
      this.InfoData = this.itemData.InfoData // 지정대리청구인정보
    }

    this.fn_DataResultHandler(this.InfoData) // 지정대리청구인 정보 셋팅
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler613P)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { return this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    'cardOne.isAllAgree' (newValue) {
      if (newValue) {
        for ( let i = 1; i <= 5; i++ ) {
          this.$refs["mspExpCardOne"+i].isExpand = !newValue
        }
      }
      this.fn_TermsAllCheckControl()
    },
    'cardOne.agreeRadioList' (newValue) {
      this.fn_TermsAllCheckControl()
    },
  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {
    inputBirthday () {
      return this.inputBirthday1 + this.inputBirthday2
    },
  },//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {

  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/
   /************************************************************************************************
    * Function명  : fn_DevicBackBtnHandler613P
    * 설명        : 디바이스 Back Button 핸들러
    ************************************************************************************************/
    fn_DevicBackBtnHandler613P() {
      //if(PSCommUtil.fn_IsActiveModal()) return
      this.fn_HeaderBackBtnHandler()
    },


    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : 헤더 이전버튼 서비스 호출 함수
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
      this.fn_BottomAlert('지정대리인 상이 동의 서비스를 종료하시겠습니까?') 
    },// fn_HeaderBackBtnHandler
    



   /******************************************************************************
    * Function명  : fn_BottomAlert
    * 설명        : 확인 팝업
    ******************************************************************************/
    fn_BottomAlert (contents) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title: "알림",
          content: contents,
          title_pos_btn: '확인',
          title_neg_btn: '취소'
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(this.lv_AlertPop)
            this.fn_Cancel()
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      })
    },
    /******************************************************************************
    * Function명 : fn_AuthConfirmBtnClick
    * 설명       : 미성년자일때 입력값 유효성 체크
    ******************************************************************************/
    fn_AuthConfirmBtnClick () {
      if (this.selectedValue.isMinor) { // 미성년자일때
        this.fn_ValidateBeforeSubmit()
      } else {
        if (this.authType1[0].key === '1') {
          this.$refs.MSPPS401D.fn_BeforeSend()
        }
        if (this.authType1[0].key === '2') {
          this.$refs.MSPPS402D.fn_BtnAuthClick()
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_ValidateBeforeSubmit
    * 설명       : 유효성 체크 함수
    ******************************************************************************/
    fn_ValidateBeforeSubmit () {
      // 2021.07.20 ASR210600997_친권인 정보 글자수 확대
      if (this.inputName === '') {
        this.getStore('confirm').dispatch('ADD', '친권자 이름은 필수입력항목입니다.')
        this.$refs.inputName.focus()
      } else if (this.fn_StrByteLength(this.inputName) > 30) {
        this.getStore('confirm').dispatch('ADD', '친권자 성명은 한글 10자, 영문 30자 이하여야 합니다.')
        this.$refs.inputName.focus()
      } else if (this.inputBirthday1.length !== 6) {
        this.getStore('confirm').dispatch('ADD', '주민등록번호는 필수입력항목입니다.')
        this.$refs.inputBirthday1.focus()
      } else if (this.inputBirthday2.length !== 1) {
        this.getStore('confirm').dispatch('ADD', '주민등록번호는 필수입력항목입니다.')
        this.$refs.inputBirthday2.focus()
      } else {
        
        this.selectedValue.insrdNm = this.inputName
        this.selectedValue.custRrnId = this.inputBirthday + '000000'

        if (this.authType1[0].key === '1') {
          this.$refs.MSPPS401D.viewData = this.selectedValue //지정대리청구인정보 전달
          this.$refs.MSPPS401D.fn_BeforeSend()
        }
        if (this.authType1[0].key === '2') {
          this.$refs.MSPPS402D.viewData = this.selectedValue //지정대리청구인정보 전달
          this.$refs.MSPPS402D.fn_BtnAuthClick()
        }
      }
    },
    /************************************************************************************************
     * Function명  : fn_StrByteLength
     * 설명        : 친권자명 자리수 체크
     ************************************************************************************************/
    fn_StrByteLength (s) {
      return s.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, '$&$1$2').length
    },
    /******************************************************************************
    * Function명 : fn_BirthFocusBlur
    * 설명       : 생년월일 blue 이벤트 컨트롤 함수
    ******************************************************************************/
    fn_BirthFocusBlur (event) {
      if (this.inputBirthday.length === 6) {
        this.$refs.inputBirthday2.focus()
      }
    },
    /******************************************************************************
    * Function명 : fn_DataResultHandler
    * 설명       : 지정대리청구인상이동의 지정대리청구인 정보 데이터 셋팅 함수
    ******************************************************************************/
    fn_DataResultHandler: function (list) {
      let InfoData = list || []

      this.selectedValue = {
        key: 0,
        label: InfoData.apcCustNm,
        data: InfoData.data,
        detail: {celno: '', chnlCustId: InfoData.apcChnlCustId },
        knb: 'SE',
        isMinor: !(this.$bizUtil.compareCurrentAge(InfoData.data.knb.substr(0, 6), this.standardAge)), // 미성년자 여부
        vuchId: this.vuchId, // 영수증ID - TODO 4/30일 반영건
        apcPrsonScCd: InfoData.apcPrsonScCd, // 2021.06.22 ASR210501099_지대청 2인지정 현장접수 반영
        age : this.fn_GetInsuranceAge(InfoData.data.knb)
      }

      if (!this.selectedValue.isMinor) {
        this.selectedValue.insrdNm = InfoData.apcCustNm
        this.selectedValue.custRrnId = InfoData.data.knb
      }
      else {
        this.selectedValue.custRrnId = ''

        setTimeout(()=> {
          this.$refs.inputName.focus()
        }, 500)
      }
    },
    /******************************************************************************
    * Function명 : fn_OnSelectAgreeAll
    * 설명       : 약관 전체동의 선택 시 컨트롤 함수
    ******************************************************************************/
    fn_OnSelectAgreeAll () {
      this.fn_OnSelectAgreeAll_Radio(this.cardOne.isAllAgree)
    },
    /******************************************************************************
    * Function명 : fn_OnSelectAgreeAll_Radio
    * 설명       : 약관 전체동의 선택 시 컨트롤 함수
    ******************************************************************************/
    fn_OnSelectAgreeAll_Radio (chk) {
      let cnt = this.cardOne.allItemsRadio.length
      for ( let i = 0; i < cnt; i++ ) {
        if ( chk ) { //  동의함
          this.cardOne.agreeRadioList[i] = '1' // 동의
        } else {
          this.cardOne.agreeRadioList[i] = '0' // 동의안함
        }
      }
      if ( chk ) {
        this.cardOne.isAllAgree = true
        this.cardOneDisabledFlag = false
      } else {
        this.cardOne.isAllAgree = false
        this.cardOneDisabledFlag = true
      }
      
      this.currentStep = this.cardOne.isAllAgree ? 2 : 1
    },
    /******************************************************************************
    * Function명 : fn_TermsAllCheckControl
    * 설명       : 전체동의 약관 체크박스 컨트롤 함수
    ******************************************************************************/
    fn_TermsAllCheckControl () {
      // 2021.05.31_pjo
      let cnt = this.cardOne.allItemsRadio.length
      let flag = true
      
      /* 중간선택 추가 */
      for ( let i = 0; i < this.cardOne.subItemsRadio.length; i++ ) {
        flag = true
        for ( let j = 0; j < this.cardOne.subItemsRadio[i].length; j++ ) {
          if ( this.cardOne.agreeRadioList[i*3+j] !== '1' ) { // 하나라도 동의 안함이면 전체동의=false
            flag = false
            break
          }
        }
        let agreeCheckboxPos = (i + 1).toString()
        if ( flag ) {
          if (this.cardOne.agreeCheckboxList.indexOf(agreeCheckboxPos) < 0) {
            this.cardOne.agreeCheckboxList.push(agreeCheckboxPos)
          }
        }
        else {
          if (this.cardOne.agreeCheckboxList.indexOf(agreeCheckboxPos.toString()) > -1 ) {
            this.cardOne.agreeCheckboxList.splice(this.cardOne.agreeCheckboxList.indexOf(agreeCheckboxPos), 1)
          }
        }
      }
      /* 중간선택 추가 END */

      for ( let i = 0; i < cnt - 1; i++ ) { // 14별도체크
        if ( this.cardOne.agreeRadioList[i] !== '1' ) { // 하나라도 동의 안함이면 전체동의=false
          flag = false
          break
        }
      }

      if ( flag ) {
        this.cardOne.isAllAgree = true
      } else {
        this.cardOne.isAllAgree = false
      }
      
      this.currentStep = this.cardOne.isAllAgree ? 2 : 1
    },

    /******************************************************************************
    * Function명 : fn_AuthFinish
    * 설명       : 인증완료 컨트롤 함수
    ******************************************************************************/
    fn_AuthFinish (personType) {
      // personType 이 11,12,13 이라면 인증패스로 들어온 것으로 판단
      if (personType < 10) { // 정상인증
        this.fn_ServiceData('S') // 인증기록 저장 (DC I/F -> TTSD00003 Table)
      } else if (personType > 10) { // 인증패스
        personType = (personType - 10)
        this.fn_ServiceData('S1') // 지정대리청구인 인증 저장
      }
    },
    /******************************************************************************
    * Function명  : fn_ServiceData
    * 설명        : 서비스 호출 함수 정의
    ******************************************************************************/
    fn_ServiceData (serviceName) {
      let pParams = {}
      pParams.data = {}
      pParams.srnId = this.$options.screenId // 대표 화면명
      switch (serviceName) {
        case 'S':
          if (this.authFinishFlag) return
          this.authFinishFlag = true
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS11I1'))
          pParams.data.moblBusnScCd = 'SE' // 모바일업무구분코드(SE:청약) - TODO 가져온 데이타에서 값 있으면 그걸로 교체
          pParams.data.eltrnDoctScCd = '01' // 전자문서구분코드 01:청약서 고정
          pParams.data.eltrnDoctId = this.vuchId // 영수증 번호, 전자문서ID
          pParams.data.athntYmd = PSDateUtil.fn_CurrentDate() // 인증일자(오늘) - 서버에서 받아와야함
          pParams.data.athntUsScCd = '05' // 인증용도구분코드  01:전자서명동의/02:정보활용동의/03:계피상의동의/04:수익자상의동의/05:지정대리청구인상의동의
          pParams.data.rqstrEno = PSServiceManager.getUserInfo('userId') || ' ' // 로그인 사번
          pParams.data.predMoblBusnScCd = 'SE' // 모바일업무구분코드(SE:청약) - TODO 가져온 데이타에서 값 있으면 그걸로 교체
          pParams.data.predEltrnDoctScCd = '01' // 전자문서구분코드 01:청약서 고정
          pParams.data.predEltrnDoctId = this.vuchId // 영수증 번호, 전자문서ID
          pParams.data.predAthntYmd = PSDateUtil.fn_CurrentDate() // 인증일자(오늘) - 서버에서 받아와야함
          pParams.data.athntMthScCd = this.authType1[0].key === '1' ? '01' : '02' // 인증방법구분코드 01:휴대폰/02:신용카드

          pParams.data.athntSignRltnrScCd = '10' // 지정대리청구인이 들어왔을때
          PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler)
          break
        case 'S1': // 지정대리청구인 인증 저장
          pParams.appId = 'tss.ps'
          pParams.fnctScCd = 'U'
          pParams.trnstId = 'txTSSPS97U2'
          // 2021.06.22 ASR210501099_지대청 2인지정 현장접수 반영
          if (this.selectedValue.apcPrsonScCd === '02') {
            pParams.trnstId = 'txTSSPS97U3'
          }
          pParams.data.vuchId = this.vuchId // 영수증번호
          pParams.data.apcCnsntMethCd = this.authType1[0].key === '1' ? 'C' : 'M' // 본인인증방식코드 C:휴대폰/M:신용카드
          pParams.data.apcCnsntDtm = ''// 인증일자(오늘) - 서버에서 인증일자 저장
          pParams.data.apcPrtrPesonCnsntYn = this.selectedValue.isMinor ? 'Y' : 'N' // 친권자인증여부
          pParams.data.apcPrtrPesonNm = this.inputName === '' ? ' ' : this.inputName // 친권자명
          PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler)
          break
        default:
          break
      }
    },
    /************************************************************************************************
     * Function명  : fn_ServiceResultHandler
     * 설명        : 서비스 호출 결과 함수 정의
     ************************************************************************************************/
    fn_ServiceResultHandler (event, serviceName) {
      let d = event.data
      switch (serviceName) {
        case 'S': // 인증 기록 저장
          if (d.wkRslt && d.wkRslt > 0) {
            this.fn_ServiceData('S1') // 지정대리청구인 인증 저장
          } else {
            this.authFinishFlag = false
            //alert("인증기록이 실패하였습니다. 로그오프되었습니다.")
          }
          break
        case 'S1': // 지정대리청구인 인증 저장
          if (d.trtRslt && d.trtRslt > 0) {
            this.disabled = true
            this.allUserAuthFinish = true
          } else {
            this.getStore('confirm').dispatch('ADD', event.msgComm.msgCd + ' ' + event.msgComm.msgDesc)
          }
          setTimeout(()=> {
            this.authFinishFlag = false // 인증저장 두번씩 되는 현상 방지
          }, 1000)
          break
        default:
          break
      }
    },
    /************************************************************************************************
     * Function명  : fn_ServiceFaultHandler
     * 설명        : 에러 이벤트
     ************************************************************************************************/
    fn_ServiceFaultHandler (event, serviceName) {
      switch (serviceName) {
        case 'S': // 인증기록저장
          this.getStore('confirm').dispatch('ADD', event.msgDesc)
          this.authFinishFlag = false
          break
        case 'S1': // 지정대리청구인인증저장
          this.getStore('confirm').dispatch('ADD', event.msgDesc)
          this.authFinishFlag = false
          break
        default:
          break
      }
    },
    /******************************************************************************
    * Function명 : fn_Confirm
    * 설명       : 확인버튼 선택 시 호출
    ******************************************************************************/
    fn_Confirm () {
      this.$emit('confirmPopup')
    },
    /******************************************************************************
    * Function명 : fn_Cancel
    * 설명       : 취소버튼 선택 시 호출
    ******************************************************************************/
    fn_Cancel () {
      // 취소 버튼 선택 시 전체동의 여부와 지정대리청구인 동의 리스트를 넘겨준다
      this.$emit('cancelPopup', this.allUserAuthFinish)
    },

    /******************************************************************************
    * Function명 : fn_ClickedCheck
    * 설명       : 제목별 동의함, 동의안함 선택 시
    ******************************************************************************/
    fn_ClickedCheck (v) {
      if (this.cardOne.agreeCheckboxList.includes((v + 1).toString())) {
        if (v === 0) {
          this.cardOne.agreeRadioList[0] = '1' // 동의
          this.cardOne.agreeRadioList[1] = '1' // 동의
          this.cardOne.agreeRadioList[2] = '1' // 동의
        } else if (v === 1) {
          this.cardOne.agreeRadioList[3] = '1' // 동의
          this.cardOne.agreeRadioList[4] = '1' // 동의
          this.cardOne.agreeRadioList[5] = '1' // 동의
        } else if (v === 2) {
          this.cardOne.agreeRadioList[6] = '1' // 동의
          this.cardOne.agreeRadioList[7] = '1' // 동의
          this.cardOne.agreeRadioList[8] = '1' // 동의
        } else if (v === 3) {
          this.cardOne.agreeRadioList[9] = '1' // 동의
          this.cardOne.agreeRadioList[10] = '1' // 동의
          this.cardOne.agreeRadioList[11] = '1' // 동의
        } else if (v === 4) {
          this.cardOne.agreeRadioList[12] = '1' // 동의
          this.cardOne.agreeRadioList[13] = '1' // 동의
        }
      } else {
        if (v === 0) {
          this.cardOne.agreeRadioList[0] = '0' // 동의안함
          this.cardOne.agreeRadioList[1] = '0' // 동의안함
          this.cardOne.agreeRadioList[2] = '0' // 동의안함
        } else if (v === 1) {
          this.cardOne.agreeRadioList[3] = '0' // 동의안함
          this.cardOne.agreeRadioList[4] = '0' // 동의안함
          this.cardOne.agreeRadioList[5] = '0' // 동의안함
        } else if (v === 2) {
          this.cardOne.agreeRadioList[6] = '0' // 동의안함
          this.cardOne.agreeRadioList[7] = '0' // 동의안함
          this.cardOne.agreeRadioList[8] = '0' // 동의안함
        } else if (v === 3) {
          this.cardOne.agreeRadioList[9] = '0' // 동의안함
          this.cardOne.agreeRadioList[10] = '0' // 동의안함
          this.cardOne.agreeRadioList[11] = '0' // 동의안함
        } else if (v === 4) {
          this.cardOne.agreeRadioList[12] = '0' // 동의안함
          this.cardOne.agreeRadioList[13] = '0' // 동의안함
        }
      }

      this.fn_TermsAllCheckControl()
    },
    /******************************************************************************
    * Function명 : fn_GetInsuranceAge
    * 설명       : 보험연령 산정 (금일기준)
    ******************************************************************************/
    fn_GetInsuranceAge (knb) {
      return PSDateUtil.getInsuranceAge(knb, moment(new Date().toISOString()).format('YYYYMMDD'))
    },

    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>