/**
 * <PRE>
 * System Name : 사랑온 NEXT 시스템(TSS2-MSP)
 * Business Name : 청약
 * Class Name : PSElstInitUtil.js
 * Description :전자서명기본정보처리유틸
 *              AS-IS 파일 PS180MUtil.js 대응
 * </PRE>
 */

import PSServiceManager from '~/src/ui/ps/comm/PSServiceManager'
import PSContRltnrUtil from '~/src/ui/ps/comm/PSContRltnrUtil'
import PSAmlUtil from '@/ui/ps/comm/PSAmlUtil' 

const PSElstInitUtil = {}

/************************************************************************************************
 * Function명  : setItemData
 * 설명        : 전자서명 앱 호출 전 Data 조합 서비스 호출
 ************************************************************************************************/
PSElstInitUtil.setItemData = {
  planId: '', // 상품(가입)설계 번호
  vuchId: '', // 영수증(문서명) 번호
  userId: '', // FC 사번
  serverStatus: '', // 현재 서버 상태
  external: {
    externalnumId: '', // 약관조회용 Unique Key
    untySlmtBasInfoNo: '', // 통합영업자료기본정보번호
    hptsPblYn: '', // (가설발행여부)
    tmpYn: '', // 임시설계사여부
    vainsUnQulfYn: '' // 변액미자격여부
  }, // 상품코드 (약관조회용 Unique Key)
  baseData: {
    eltrnDoctId: '', // 문서명
    tbtPbFilePath: '', // 전체 경로
    moDCEncrKeyVO: {} // 문서 암호화 정보
  },
  signerData: {}, // 전자서명 사인 정보
  eltrnSaveData: {}, // 전자서명 세부 정보
  contInfoData: {}, // 채널고객 기본 정보(계약자)
  mnisdInfoData: {}, // 채널고객 기본 정보(피보험자)
  fatcaData: {}, // FATCA 개인고객정보
  
  amlContData : null, // AML 계약자 채널고객 기본정보
  amlPrtrData01 : null, // AML 친권자01 채널고객 기본정보
  amlDthBnfrData01 : null, //AML 사망수익자01 채널고객 기본정보

  bankData: {}, // 등록 계좌
  // 191210 출금동의일자가 없는 경우 계좌정보를 조회하지 않음
  // PSDocFormUtil.js / addContBankInfoDocForm에서 사용함
  bankDataTemp: {cmcustAccnInfoListVO: { }, rrn: '0000001111111'}, // 등록계좌 임시 정보
  gafData: {}, // 심사결과 정보
  /* 보험거래문서디지털화 */
  pvmnotice: {}, // 고지정보
  jsonInputData: {}, // 발행 Json 전문
  cardData: {} // 등록 카드 / (ASR240400333_24.05 장한나)
}
PSElstInitUtil.vueClone = {}
PSElstInitUtil.typeKey = ''
PSElstInitUtil.fnEltrnSignCallBack = function () {}

/************************************************************************************************
 * Function명  : fn_EltrnSignDocService
 * 설명        : 전자서명 앱 호출 전 Data 조합 서비스 호출
 * param : type 추가 정보 값
 * param : pdata 기초 Data
 * param : fnCallBack 리턴 Function
 * param : fullFilePath 파일 풀 경로
 ************************************************************************************************/

PSElstInitUtil.fn_EltrnSignDocService = function (type, pData, fnCallBack) {
  this.vueClone = Object.assign(this, window.vue.getInstance('vue'))
  this.typeKey = type
  this.setItemData.serverStatus = pData.serverStatus // 서버 상태
  this.setItemData.userId = pData.userId // FC 사번
  this.setItemData.planId = pData.planId // 설계 ID
  this.setItemData.vuchId = this.setItemData.baseData.eltrnDoctId = pData.vuchId // 문서명(영수증번호)
  this.setItemData.baseData.tbtPbFilePath = pData.fullFilePath // 파일 전체경로
  this.setItemData.baseData.ozdFilePath = pData.ozdFilePath // ozd 파일 전체경로
  this.setItemData.baseData.moDCEncrKeyVO = {} // 문서 암호화 정보
  // Data 초기화 처리
  this.setItemData.external.externalnumId = '' // 약관조회용 Unique Key
  this.setItemData.external.untySlmtBasInfoNo = '' // 통합영업자료기본정보번호
  this.setItemData.external.hptsPblYn = '' // (가설발행여부)
  this.setItemData.external.tmpYn = '' // 임시설계사여부
  this.setItemData.external.vainsUnQulfYn = '' // 변액미자격여부
  this.setItemData.signerData = {} // 전자서명 사인 정보
  this.setItemData.eltrnSaveData = {} // 전자서명 세부 정보
  this.setItemData.contInfoData = {} // 채널고객 기본 정보(계약자)
  this.setItemData.mnisdInfoData = {} // 채널고객 기본 정보(피보험자)
  this.setItemData.fatcaData = {} // FATCA 개인고객정보

  this.amlContData      = null, // AML 계약자 채널고객 기본정보
  this.amlPrtrData01    = null, // AML 친권자01 채널고객 기본정보
  this.amlDthBnfrData01 = null, //AML 사망수익자01 채널고객 기본정보

  this.setItemData.bankData = {} // 등록 계좌
  this.setItemData.gafData = {} // 심사결과 정보
  /* 보험거래디지털화 */
  this.setItemData.pvmnotice = pData.pvmnotice // 고지정보
  this.setItemData.jsonInputData = pData.jsonInputData // 발행 Json 전문

  this.fnEltrnSignCallBack = fnCallBack
  
  PSServiceManager.setDynaTraceLog('fn_EltrnSignDocService', type, 'PSElstInitUtil', this.setItemData.vuchId)
  
  // 전자서명 앱 실행이 필요한 상태일때만 암호화키를 조회  
  //if (this.typeKey === 'TSSPS110M' || this.typeKey === 'TSSPS110M_1' || this.typeKey.startsWith('TSSPS213D_3')) {
  if (this.typeKey === 'MSPPS420M' || this.typeKey === 'MSPPS420M_1' || this.typeKey.startsWith('MSPPS240M_3')) {  
    this.fn_EltrnSignServiceData('encrKey') // 암호화키 조회
  } else {
    this.fn_EltrnSignServiceData('S1') // 전자서명 사인 정보
  } // end else if

}
/************************************************************************************************
 * Function명  : fn_EltrnSignServiceData
 * 설명        : 서비스 호출 함수 정의
 ************************************************************************************************/
 PSElstInitUtil.fn_EltrnSignServiceData = function (serviceName) {
  let pParams = {}
  
  try {
    switch (serviceName) {
      case 'encrKey': // 암호화키 조회
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS19S1'))
        break
      case 'S1': // 전자서명 사인 정보
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS98S1'))
        pParams.data = {}
        pParams.data.vuchId = this.setItemData['vuchId'] // 전자문서ID
        break
      case 'S2': // 전자서명 세부 정보조회
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS99S14')) //AML대응용 txTSSPS99S1 => txTSSPS99S14
        pParams.data = {}
        pParams.data.vuchId = this.setItemData['vuchId'] // 전자문서ID
        break
      case 'S3': // 채널고객 기본 정보(계약자)
      case 'S4': // 채널고객 기본 정보(피보험자)
      case 'S6-PRE': // 채널고객 기본정보(예금주)
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS13S4'))
        pParams.data = {}
        pParams.data.cnsltNo = PSServiceManager.getUserInfo('userEno')
        pParams.useCompress = true
        if (serviceName === 'S3') {
          pParams.data.chnlCustId = this.setItemData.eltrnSaveData['mobslChnlCustId']
          pParams.data.custNm = this.setItemData.eltrnSaveData['mnContrNm']
        } else if (serviceName === 'S4') {
          pParams.data.chnlCustId = this.setItemData.eltrnSaveData['insrdChnlCustId']
          pParams.data.custNm = this.setItemData.eltrnSaveData['insrdNm']
        } else if (serviceName === 'S6-PRE') {
          pParams.data.chnlCustId = this.setItemData.eltrnSaveData['dpstrMobslChnlCustId']
          pParams.data.custNm = this.setItemData.eltrnSaveData['dpstrNm']
        }// end else if
        break
      case 'S6-PRE-CORP': // BPID 기본정보(예금주)
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS14S1')) // 변경 필요
        pParams.data = {}
        let custAthntNm = {}
        custAthntNm.custId = this.setItemData.eltrnSaveData.elstDpstrPartnNo
        custAthntNm.knbScCd = '21'
        pParams.data.custAthntNm = custAthntNm
        break
      case 'S5': // FATCA 개인고객정보 조회
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS13S7'))
        pParams.data = {}
        pParams.data.custNm = this.setItemData.eltrnSaveData['mnContrNm']
        pParams.data.custRrn = this.setItemData.contInfoData['knb']
        break
      case 'S6': // 등록계좌조회
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS13S3'))
        pParams.useCompress = true
        pParams.data = {}
        pParams.data.custNm = this.setItemData.dpstrInfo['custNm']
        pParams.data.rrn = this.setItemData.dpstrInfo['knb']
        pParams.data.custAccnRltnCd = this.setItemData.eltrnSaveData['bnkAccnId']
        break
      case 'S6-CARD': //  카드정보조회 'txTSSPS15S1': // F1MDI0438 고객카드목록 조회 (ASR240400333_24.05 장한나)
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS15S1'))
        pParams.useCompress = true
        pParams.data = {}
        pParams.data.custId = this.setItemData.eltrnSaveData['elstPartnNo'] // 계약자BPID / TTSD00002.ELST_PARTN_NO
        break
      case 'S7': // 심사결과 정보 호출(계약자/피보험자/수익자/보험정보)
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS13S2'))
        pParams.useCompress = true
        if (!this.setItemData.planId) { // 영수증 ID만 존재 할떄
          this.setItemData.planId = this.setItemData.eltrnSaveData['elstPlanId']
        } // end else if
        let sdrvDtVal = JSON.stringify({tmodel: {searchCriteria: {zaPlanId: this.setItemData.planId}}})
        pParams.data = {}
        pParams.data.cmndSrvcNm = 'selGafApplicationMain'
        pParams.data.sdrvDtVal = sdrvDtVal
        break
      case 'uniqKey': // 전자서명 약관키 조회 (Unique Key)
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS13S8'))
        pParams.data = {}
        pParams.data.cmndSrvcNm = 'getEntplPblItm'
        let inputData = {}
        inputData.bukrs = 'L001' // Company code  생명 L001, 화재 F100
        inputData.spras = 'K' // 언어코드
        // 발행목록 조회 서비스 디폴트
        inputData.zaPlanScCd = '01' // 설계구분코드
        inputData.zaPrspCustYn = 'Y' // 가망고객여부
        inputData.zaUntySlmtBasInfoNo = '' // 통합영업자료기본정보번호
        inputData.zaVainsUnQulfYn = '' // 변액미자격여부
        inputData.zaCnsltRrn = '' // 컨설턴드주민번호
        // 입력 가능 구분
        inputData.zaPlanId = this.setItemData.eltrnSaveData['elstPlanId'] // 설계 ID
        inputData.zaPrcd = this.setItemData.gafData.planInfoData[0]['prcdId'] // 상품코드
        inputData.zaPrdtVcd = this.setItemData.gafData.planInfoData[0]['productVersionNo'] // 상품버젼코드
        inputData.zaIlstIrtTypCd = this.setItemData.gafData.planInfoData['ilstIrtTypCd'] // 예시이율유형코드
        inputData.zaContrAgtFileId = this.setItemData.eltrnSaveData['mobslChnlCustId'] // 계약자채널고객ID
        inputData.productBaseId = this.setItemData.gafData.planInfoData[0]['productBaseId'] // productBaseId
        inputData.zzrmvlPrcdCd = this.setItemData.gafData.planInfoData[0]['rmvlPrcdCd']
        inputData.zzprmtrPrdTCd = this.setItemData.gafData.planInfoData[0]['prmtrPrdTCd'] // 파라미터상품유형코드
        pParams.data.sdrvDtVal = JSON.stringify(inputData)
        break
      default:
        break
    } // end switch
  } catch (error) {
    console.log('------------------------------------tyr~catch--------------------')
    this.fn_EltrnSignServiceFaultHandler(error, serviceName)  
    return
  }
  
  PSServiceManager.setDynaTraceLog('invoke.Call---->>', serviceName, 'PSElstInitUtil', this.setItemData.vuchId)
  pParams.srnId = 'MSPPS610M' //TSSPS180M
  pParams.context = this.vueClone
  PSServiceManager.invoke(pParams, this.fn_EltrnSignServiceResultHandler, serviceName, this.fn_EltrnSignServiceFaultHandler, true)
}
/************************************************************************************************
 * Function명  : fn_EltrnSignServiceResultHandler
 * 설명        : 서비스 호출 결과 함수 정의
 ************************************************************************************************/
 PSElstInitUtil.fn_EltrnSignServiceResultHandler = async function (event, serviceName) {
  
  PSServiceManager.setDynaTraceLog('result---->>', serviceName, 'PSElstInitUtil', this.setItemData.vuchId)
  // ------------------------------- 다이나트레이스 세팅_end
  let lv_data = event.data
  console.log('SElstInitUtil.fn_EltrnSignServiceResultHandler event.data serviceName=' + serviceName)
  console.log(lv_data)
  switch (serviceName) {
    case 'encrKey': // 암호화키 조회
      this.setItemData.baseData.moDCEncrKeyVO = {}
      this.setItemData.baseData.moDCEncrKeyVO.encrKey = lv_data.encrKey // 암호화 키
      this.setItemData.baseData.moDCEncrKeyVO.timestampId = lv_data.timestampId // 타임스템프 ID
      this.fn_EltrnSignServiceData('S1')
      break
    case 'S1': // 전자서명 사인 정보
      if (lv_data.vuchId) {
        this.setItemData.signerData = lv_data
        this.fn_EltrnSignServiceData('S2') // 전자서명 앱 기초 Data 조회
      } else {
        // this.fnEltrnSignCallBack({itemData: this.setItemData, error: serviceName + ':: 전자서명 정보가 없습니다.'})
        this.fnEltrnSignCallBack({itemData: this.setItemData, error: serviceName + ':: 전자서명 정보가 없습니다.\n vuchId:' + this.setItemData.vuchId}) // vuchId 표기, ASR200100469 (20200221 운영반영)
      } // end if
      break
    case 'S2': // 전자서명 앱 기초 Data
      if (lv_data.vuchId) {
        this.setItemData.eltrnSaveData = lv_data
        
        //AML 대응 추가 컬럼 방어 체크
        //계약자 AML BPID
        this.setItemData.eltrnSaveData.amlContrPartnNo = (this.setItemData.eltrnSaveData.amlContrPartnNo)? String(this.setItemData.eltrnSaveData.amlContrPartnNo).trim() : ''
        //친권자 AML BPID
        this.setItemData.eltrnSaveData.amlPrtrPesonPartnNo = (this.setItemData.eltrnSaveData.amlPrtrPesonPartnNo)? String(this.setItemData.eltrnSaveData.amlPrtrPesonPartnNo).trim() : ''
        //사망수익자 AML BPID
        this.setItemData.eltrnSaveData.amlDthBnfrPartnNo = (this.setItemData.eltrnSaveData.amlDthBnfrPartnNo)? String(this.setItemData.eltrnSaveData.amlDthBnfrPartnNo).trim() : ''        
        // 사망수익자01 채널고객ID
        this.setItemData.eltrnSaveData.amlDthBnfrChnlCustId = (this.setItemData.eltrnSaveData.amlDthBnfrChnlCustId)? String(this.setItemData.eltrnSaveData.amlDthBnfrChnlCustId).trim() : '' 
        // 사망수익자01 채널 고객명 
        this.setItemData.eltrnSaveData.amlDthBnfrCustNm = (this.setItemData.eltrnSaveData.amlDthBnfrCustNm)? String(this.setItemData.eltrnSaveData.amlDthBnfrCustNm).trim() : ''         

        if (this.setItemData.eltrnSaveData.corpNomnPinsYn === 'N') {
          // 법인이 아닐떄
          this.fn_EltrnSignServiceData('S3') // 채널고객 기본 정보(계약자)
        } else {
          // 법인일때
          this.setItemData.eltrnSaveData.dpstrNm = this.setItemData.eltrnSaveData.mnContrNm
          this.setItemData.eltrnSaveData.elstDpstrPartnNo = this.setItemData.eltrnSaveData.elstPartnNo
          this.fn_EltrnSignServiceData('S6-PRE-CORP')
        }
      } else {        
        this.fnEltrnSignCallBack({itemData: this.setItemData, error: serviceName + ':: 전자서명 정보가 없습니다.\n vuchId:' + this.setItemData.vuchId}) 
      } // end else if
      break
    case 'S3': // 채널고객 기본 정보(계약자)
      this.setItemData.contInfoData = lv_data

      /*------------ AML 대상자 기본 정보 처리  AS-IS 처리 흐름 에서 끼워넣기 Promise 패턴사용 -----*/
      let amlChnlCustId = '' , amlChnlCustNm = ''
      let amlRsltCustInfo = null
      
      
      if(this.setItemData.eltrnSaveData.amlContrPartnNo){ // 계약자 AML대상 BPID 존재여부
        this.setItemData.amlContData = this.setItemData.contInfoData // 계약자 기본 정보로 셋팅
      }

      //친권자01 채널고객 정보 조회 셋팅 Promise 패턴 사용함      
      if(this.setItemData.eltrnSaveData.amlPrtrPesonPartnNo){ // 친권자01 AML대상 BPID 존재여부
        amlChnlCustId = (this.setItemData.eltrnSaveData['prtrChnlCustId01'])? String(this.setItemData.eltrnSaveData['prtrChnlCustId01']).trim() : ''//친권자01 채널고객ID
        amlChnlCustNm = (this.setItemData.eltrnSaveData['prtrNm01'])? String(this.setItemData.eltrnSaveData['prtrNm01']).trim() : '' //친권자01 고객명
        amlRsltCustInfo = await PSAmlUtil.fn_GetAmlChnlCustInfo(this.vueClone,amlChnlCustId,amlChnlCustNm) // 친권자 정보 조회
        if(amlRsltCustInfo.isError == false){
          this.setItemData.amlPrtrData01 = amlRsltCustInfo.data
        }else{
          //에러시 에러 로그 기록
          PSElstInitUtil.fn_EltrnSignServiceFaultHandler(amlRsltCustInfo.msgComm, 'AML_PRTR01')
        }
      }

      //사망수익자01 채널고객 정보 조회 셋팅 Promise 패턴 사용함      
      if(this.setItemData.eltrnSaveData.amlDthBnfrPartnNo){ // 사망수익자01 AML대상 BPID 존재여부
        amlChnlCustId = this.setItemData.eltrnSaveData.amlDthBnfrChnlCustId // 사망수익자01 채널고객ID
        amlChnlCustNm = this.setItemData.eltrnSaveData.amlDthBnfrCustNm  // 사망수익자01 채널 고객명        
        amlRsltCustInfo = await PSAmlUtil.fn_GetAmlChnlCustInfo(this.vueClone,amlChnlCustId,amlChnlCustNm) // 사망수익자 정보 조회
        if(amlRsltCustInfo.isError == false){
          this.setItemData.amlDthBnfrData01 = amlRsltCustInfo.data
        }else{
          //에러시 에러 로그 기록
          PSElstInitUtil.fn_EltrnSignServiceFaultHandler(amlRsltCustInfo.msgComm, 'AML_DTH_BNFR01')
        }
      }

      /*------------ AML 대상자 기본 정보 처리 완료  AS-IS 처리 루틴 수행-----*/


      if (this.setItemData.signerData['contrInsrdSameYn'] === 'N') { // 계피상이 일때
        this.fn_EltrnSignServiceData('S4') // 채널고객 기본 정보(피보험자)
      } else {
        if (this.setItemData.eltrnSaveData['fatcaCnfmObjYn'] === 'Y') { // FATC 개인고객정보
          this.fn_EltrnSignServiceData('S5') // FATCA 개인고객정보 조회
        } else {
          // 후입금 여부에 따라 계좌 정보 조회
          if (this.setItemData.eltrnSaveData['aftRctmHopYn'] === 'Y') {
            this.fn_EltrnSignServiceData('S7') // 심사결과 정보 호출(계약자/피보험자/수익자/보험정보)
          // 191210 출금동의일자가 없는 경우 계좌정보를 조회하지 않음
          } else if (this.setItemData.signerData['dfraCnsntYmd'].trim() === '') {
            this.setItemData.bankData = this.setItemData.bankDataTemp
            this.fn_EltrnSignServiceData('S7') // 심사결과 정보 호출(계약자/피보험자/수익자/보험정보)
          } else {
            if(this.setItemData.eltrnSaveData['frtmPrmPadMthCd'] === '9' ) { // 9 신용카드 (ASR240400333_24.05 장한나)
              this.fn_EltrnSignServiceData('S6-CARD') // 카드정보조회
            } else {
              // this.fn_EltrnSignServiceData('S6') // 계좌정보 조회
              this.fn_EltrnSignServiceData('S6-PRE') // 채널고객 기본정보(예금주)
            }
          } // end else if
        } // end else if
      } // end else if
      
      break
    case 'S4': // 채널고객 기본 정보(피보험자)
      this.setItemData.mnisdInfoData = lv_data
      if (this.setItemData.eltrnSaveData.fatcaCnfmObjYn === 'Y') { // FATC 개인고객정보
        this.fn_EltrnSignServiceData('S5') // FATCA 개인고객정보 조회
      } else {
        // 후입금 여부에 따라 계좌 정보 조회
        if (this.setItemData.eltrnSaveData['aftRctmHopYn'] === 'Y') {
          this.fn_EltrnSignServiceData('S7') // 심사결과 정보 호출(계약자/피보험자/수익자/보험정보)
        // 191210 출금동의일자가 없는 경우 계좌정보를 조회하지 않음
        } else if (this.setItemData.signerData['dfraCnsntYmd'].trim() === '') {
          this.setItemData.bankData = this.setItemData.bankDataTemp
          this.fn_EltrnSignServiceData('S7') // 심사결과 정보 호출(계약자/피보험자/수익자/보험정보)
        } else {
          // this.fn_EltrnSignServiceData('S6') // 계좌정보 조회
          this.fn_EltrnSignServiceData('S6-PRE') // 채널고객 기본정보(예금주)
        } // end else if
      } // end else if
      break
    case 'S5': // FATCA 개인고객정보 조회
      this.setItemData.fatcaData = lv_data
      // 후입금 여부에 따라 계좌 정보 조회
      if (this.setItemData.eltrnSaveData['aftRctmHopYn'] === 'Y') {
        this.fn_EltrnSignServiceData('S7') // 심사결과 정보 호출(계약자/피보험자/수익자/보험정보)
        // 191210 출금동의일자가 없는 경우 계좌정보를 조회하지 않음
      } else if (this.setItemData.signerData['dfraCnsntYmd'].trim() === '') {
        this.setItemData.bankData = this.setItemData.bankDataTemp
        this.fn_EltrnSignServiceData('S7') // 심사결과 정보 호출(계약자/피보험자/수익자/보험정보)
      } else {
        // this.fn_EltrnSignServiceData('S6') // 계좌정보 조회
        this.fn_EltrnSignServiceData('S6-PRE') // 채널고객 기본정보(예금주)
      } // end else if
      break
    case 'S6-PRE': // 채널고객 기본정보(예금주)
      this.setItemData.dpstrInfo = lv_data
      this.fn_EltrnSignServiceData('S6') // 계좌정보 조회
      break
    case 'S6-PRE-CORP':
      let dpstrInfo = {
        custNm: this.setItemData.eltrnSaveData.dpstrNm,
        knb: lv_data.tcrmGBobjNm.custAthntNm[0].rrn
      }
      this.setItemData.dpstrInfo = dpstrInfo
      this.fn_EltrnSignServiceData('S6')
      break
    case 'S6': // 계좌정보 조회
      this.setItemData.bankData = lv_data
      this.fn_EltrnSignServiceData('S7') // 심사결과 정보 호출(계약자/피보험자/수익자/보험정보)
      break
    case 'S6-CARD': //  카드정보 (ASR240400333_24.05 장한나)
          /*
    "custId": "0412742771",
    "listCardInfoVO": [
        {
            "cardId": "000002", // 지급카드 ID
            "cardType": "0001", // 지급카드 유형
            "cardNum": "379183689420724", // 카드 번호
            "carddefault": " ",
            "creditCardNm": " ",
            "validTo": "2024.04.25", // 지급카드: 효력 시작일 
            "validFrom": "9999.12.31", // 지급카드: 효력 종료일 
            "maskNum": " ",
            "stampNm": "이은별", // 지급카드: 카드 소유자 이름
            "identificationNum": " ",
            "issuer": " ",
            "issuingDate": "00000000",
            "cardMnChkYn": "Y",
            "bpCardStatCd": "01", // BP카드상태코드
            "cardAvlYm": "202506", // 카드유효년월
            "cardTypeNm": "삼성카드", // 카드회사명
            "bpCardStatCdNm": "정상"
        }
    ]
          */
      let vCardId = this.setItemData.eltrnSaveData.crmtBuagCcardOut
      if ( lv_data !== undefined && lv_data.listCardInfoVO.length > 0 ) {
        for ( let i = 0; i < lv_data.listCardInfoVO.length; i++ ) {
          if ( vCardId === lv_data.listCardInfoVO[i].cardId ) {
            this.setItemData.cardData = lv_data.listCardInfoVO[i]
            console.log( this.setItemData.cardData )
            break
          }
        }
      }
      console.log('>>>>>>>>>>>PSElstInitUtil.S6-CARD-vCardId:'+ vCardId)
      console.log('>>>>>>>>>>>PSElstInitUtil.S6-CARD-setItemData.cardData:'+ JSON.stringify(this.setItemData.cardData))
      this.fn_EltrnSignServiceData('S7') // 심사결과 정보 호출(계약자/피보험자/수익자/보험정보)
      break
    case 'S7': // 심사결과 정보 호출(계약자/피보험자/수익자/보험정보)
      this.setItemData.gafData = PSContRltnrUtil.fn_PICustInfoAll(lv_data)
      
      //전산심사 AML 친권자 KYC결과코드 셋팅 친권자01 AML대상 BPID 있으면 무조건 EDD
      //2023.07 까지는 전자서명시 친권자01 서식 발행 CASE 없음(계약자 == 친권자01) 
      if(this.setItemData.eltrnSaveData.amlPrtrPesonPartnNo && 
        this.setItemData.gafData && this.setItemData.gafData.amlKycRsltCd){ // 친권자01 AML대상 BPID 존재여부
          this.setItemData.gafData.amlKycRsltCd.prtr01 = 'EDD'
      }else{
          this.setItemData.gafData.amlKycRsltCd.prtr01 = ''
      }

      // 전자서명 완료 및 LMS 약관 발송을 필요한 단계일때 약관 키 조회      
      //if (this.typeKey === 'TSSPS110M' || this.typeKey === 'TSSPS110M_1' || this.typeKey.startsWith('TSSPS213D_3')) {
      if (this.typeKey === 'MSPPS420M' || this.typeKey === 'MSPPS420M_1' || this.typeKey.startsWith('MSPPS240M_3')) {  
        this.fn_EltrnSignServiceData('uniqKey')
      } else {
        this.fnEltrnSignCallBack({itemData: this.setItemData, error: ''})
      } // end else if
      break
    case 'uniqKey': // 전자서명 약관키 조회 (Unique Key)
      this.setItemData.external.externalnumId = lv_data.externalnumId // 약관조회용 Unique Key
      this.setItemData.external.untySlmtBasInfoNo = lv_data.zaUntySlmtBasInfoNo // 통합영업자료기본정보번호
      this.setItemData.external.hptsPblYn = lv_data.zaHptsPblYn // (가설발행여부)
      this.setItemData.external.tmpYn = lv_data.zaTmpYn // 임시설계사여부
      this.setItemData.external.vainsUnQulfYn = lv_data.zaVainsUnQulfYn // 변액미자격여부
      this.fnEltrnSignCallBack({itemData: this.setItemData, error: ''})
      break
    default:
      break
  } // end switch
}
/************************************************************************************************
 * Function명  : fn_EltrnSignServiceFaultHandler
 * 설명        : 에러 이벤트
 ************************************************************************************************/
 PSElstInitUtil.fn_EltrnSignServiceFaultHandler = function (event, serviceName) {
  let ERROR_NAME = ''
  switch (serviceName) {
    case 'encrKey': // 암호화키 조회
      ERROR_NAME = '문서 키 조회 실패'
      break
    case 'S1': // 전자서명 사인 정보
      ERROR_NAME = '전자 서명 기본 정보 조회'
      break
    case 'S2': // 전자서명 앱 기초 Data
      ERROR_NAME = '전자 서명 사전 정보 조회'
      break
    case 'S3': // 채널고객 기본 정보(계약자)
      ERROR_NAME = '계약자 고객 기본 정보 조회'
      break
    case 'AML_PRTR01': // 채널고객 기본 정보 AML 친권자01
      ERROR_NAME = 'AML 친권자01 고객 기본 정보 조회'
      break  
    case 'AML_DTH_BNFR01': // 채널고객 기본 정보 AML 사망수익자01
      ERROR_NAME = 'AML 사망수익자01 고객 기본 정보 조회'
      break    
    case 'S4': // 채널고객 기본 정보(피보험자)
      ERROR_NAME = '피보험자 고객 기본 정보 조회'
      break
    case 'S5': // FATCA 개인고객정보 조회
      ERROR_NAME = 'FATCA 입력 정보 조회'
      break
    case 'S6': // 계좌정보 조회
      ERROR_NAME = '계약자 계좌정보 조회'
      break
    case 'S7': // 심사결과 정보 호출(계약자/피보험자/수익자/보험정보)
      ERROR_NAME = '상품설계 심사결과 정보 조회'
      break
    case 'uniqKey': // 전자서명 약관키 조회 (Unique Key)
      ERROR_NAME = '전자서명 약관키 조회'
      break
    case 'S6-PRE': // 전자서명 약관키 조회 (Unique Key)  //추가필요.20200205 박재옥
      ERROR_NAME = '채널고객 기본정보(예금주)'
      break
    case 'S6-PRE-CORP': // 단체정보 조회
      ERROR_NAME = '단체고객 기본정보(예금주)'
      break
    default:
      break
  } // end switch
  
  
  let vLog = serviceName + ':: ' + ERROR_NAME
  if ( event.msgDesc ) vLog += '\n' + event.msgDesc
  if ( event.stack ) vLog += '\n' + event.stack
  console.log('▶▶▶vue.log.test' + '\n' + vLog )
  
  PSServiceManager.setDynaTraceLog('ERROR--', vLog, 'PSElstInitUtil', this.setItemData.vuchId)
  
  this.fnEltrnSignCallBack({itemData: this.setItemData, error: vLog}) // test
}

export default PSElstInitUtil
// eslint-disable-next-line
