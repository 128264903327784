/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS610M
 * 화면 설명: 전자서명 청약접수-청약 완료 처리 화면
              AS-IS TSSP180M 전환 화면

 */
<template>
  <!-- 퍼블 : 뒤로가기 nav 버튼 제거 : class 추가 "btn-back-clear"  -->
  <!-- <ur-page-container class="msp" :class="currentStep === '7' ? 'btn-back-clear' : ''" :title="currentTitle" :show-title="true" type="subpage" ref="page" @on-header-left-click="fn_HeaderBackBtnHandler"> -->
  <ur-page-container class="msp" :title="currentTitle" :show-title="true" type="subpage" ref="page" @on-header-left-click="fn_HeaderBackBtnHandler">
    <keep-alive>
      <component ref="component"         
        v-if="currentStep !== '0'" 
        :is="currentComponent" 
        :currentStep="currentStep" 
        :itemData="itemData"
        :planNm="planNm" 
        :prcd="prcd" 
        :scnAcpnChackData="scnAcpnChackData"
        :key="compKey" 
        :isFlashEnv="isFlashEnv" 
        @childs-activated-evnet="fn_ChildsActivatedEvnet" 
        @comp-change-event ="fn_ComponentChange"
        @titl-change-event ="fn_TitlChange"
        @childs-movepage-event="fn_MovePage"          
        @childs-186D-event="fn_Child186DEvent"
        @childs-trace-event="fn_trace">
      </component>
    </keep-alive>

    <!--
    <div :class="[{'-pub-dim':!getProgress.isShow}]" v-if="isShowLoading">
      <span :class="[{'-pub-loading':!getProgress.isShow}]"></span>
      <span class="-pub-loading-text" v-if="isShowLoading && isShowLoading1">고객님의 전자서명 계약을 생성중입니다.<br/>잠시 기다려 주시기 바랍니다.</span>
      <span class="-pub-loading-text" v-if="isShowLoading && isShowLoading2">고객님의 전자서명 계약을 처리중입니다.<br/>완료될 때까지 잠시 기다려 주시기 바랍니다.</span>
    </div>
    -->
    
	  <div class="msp loading--dim msp-in-page" v-if="isShowLoading">
      <!-- <div class="preloader-bubbling">
        <span id="bubbling_1"></span>
        <span id="bubbling_2"></span>
        <span id="bubbling_3"></span>
      </div> -->
      <div class="loading-text-box mt35" v-if="isShowLoading && isShowLoading1">
        <span class="title">고객님의 전자서명 계약을 생성중입니다.</span>
        <span class="txt">잠시 기다려 주시기 바랍니다.</span>
      </div>
      <div class="loading-text-box mt35" v-if="isShowLoading && isShowLoading2">
        <span class="title">고객님의 전자서명 계약을 처리중입니다.</span>
        <span class="txt">완료될 때까지 잠시 기다려 주시기 바랍니다.</span>
      </div>
    </div>


  </ur-page-container>
   <!-- / root ur-page-container -->
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 

import PSDocFormUtil from '@/ui/ps/comm/PSDocFormUtil'
import PSElstInitUtil from '~/src/ui/ps/comm/PSElstInitUtil'  //AS-IS PS180MUtil
import PSScnAcpnUtil from '@/ui/ps/comm/PSScnAcpnUtil'  //AS-IS PSScnAcpnUtil
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import ElstDtlInfoVO from '@/ui/ps/vo/psElstDtlInfoVO'
import SHA256 from '@/ui/ps/comm/PSSha256'

import MSPPS241P from '@/ui/ps/MSPPS241P' // 전자서명 진행단계팝업 AS-IS TSSPS211P 
import MSPPS611D from '@/ui/ps/MSPPS611D' // 전자서명 신계약체결 완료 AS-IS TSSPS181D
import MSPPS612D from '@/ui/ps/MSPPS612D' // 전자서명 전자서명 완료  AS-IS TSSPS182D
import MSPPS613D from '@/ui/ps/MSPPS613D' // 전자서명 청약접수 완료 AS-IS TSSPS183D
import PdfConstants from '@/config/constants/pdfConstants'
import MSPPS732D from '@/ui/ps/MSPPS732D' // 피보험자 지문촬영 AS-IS TSSPS186D  

import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import PSAmlUtil from '@/ui/ps/comm/PSAmlUtil'

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS610M', 
  screenId: 'MSPPS610M', 
  components: {
    'MSPPS611D': MSPPS611D, // 전자서명 신계약체결 완료 AS-IS TSSPS181D
    'MSPPS612D': MSPPS612D, // 전자서명 전자서명 완료  AS-IS TSSPS182D
    'MSPPS613D': MSPPS613D, // 전자서명 청약접수 완료 AS-IS TSSPS183D
    'MSPPS732D': MSPPS732D, // 피보험자 지문촬영 AS-IS TSSPS186D  
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  /*
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  */

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {      
      isFlashEnv: false,
      //srnTitle : '청약완료',   //해더 타이틀 제목
      isDebugMode: false,     // Debug Mode 활성화
      //debugLogData: '',       // Debug Mode 전자서명 결과 Data
      //debugLog: '',           // Debug Mode 전자서명 진행 사항 및 에러 로그
      //isShowAlert: false,
      isShowLoading: true,
      isShowLoading1: false,
      isShowLoading2: false,
      msgDesc: '',
      completeType: '',
      negativeButtonLabel: '',
      positiveButtonLabel: '',
      classOption: [],
      reqNameID: '',
      mobpsYn: 'N',           // 모바일청약여부(MSPPS230M,AS-IS:TSSPS230D)에서 호출시 Y로 설정
      planNm: '',
      prcd: '',
      currentComponent: '',
      currentTitle: '전자서명',
      compKey : '0',
      currentItems: [
        {'title': '',                'component': '' , 'compKey' : '1'},
        {'title': ['','전자서명 완료','청약접수 완료','이체완료','신계약체결 완료'],   'component': 'MSPPS611D' , 'compKey' : '2'}, //AS-IS TSSPS181D
        {'title': '전자서명 완료',    'component': 'MSPPS612D' , 'compKey' : '3'}, //AS-IS TSSPS182D
        {'title': '청약접수완료',     'component': 'MSPPS613D', 'compKey' : '4'}, //AS-IS TSSPS183D
        {'title': '피보험자지문촬영', 'component': 'MSPPS732D', 'compKey' : '5'}  //AS-IS TSSPS186D 
      ],
      currentStep: '0',
      rowPopupData: {},
      STORAGE_ROOT_PATH: '/storage/emulated/0',           // 로칼 업로드 파일 경로
      PATH_PDF_DOWNLOAD: 'file.path.ofr.bs',              // 서버 다운로드 Property 정보
      LOCAL_PATH_PDF_DOWNLOAD: '/sli/temp/newpdf/',       // 로컬 다운로드 파일 경로
      PATH_PDF_UPLOAD: 'file.path.ofr.as',                // 서버 업로드 Property 정보
      LOCAL_PATH_PDF_UPLOAD: '/sli/temp/pdf_finish',      // 로칼 업로드 파일 경로
      PATH_PDF_FOLDER_DATE: '',                           // 페이지 로딩 시점 날자(yyyyMMdd) 저장
      itemData: {
        planId: '',         // 상품(가입)설계 번호
        vuchId: '',         // 영수증(문서명) 번호
        userId: '',         // FC 사번
        fullFilePath: '',   // 전체 경로
        ozdFilePath: '',    // ozd 전체 경로
        serverStatus: '',   // 현재 서버 상태
        external: {
          externalnumId: '',      // 약관조회용 Unique Key
          untySlmtBasInfoNo: '',  // 통합영업자료기본정보번호
          hptsPblYn: '',          // (가설발행여부)
          tmpYn: '',              // 임시설계사여부
          vainsUnQulfYn: ''       // 변액미자격여부
        }, // 상품코드 (약관조회용 Unique Key)
        baseData: {
          eltrnDoctId: '',    // 문서명
          tbtPbFilePath: '',  // 전체 경로
          moDCEncrKeyVO: {}   // 문서 암호화 정보
        },
        signerData: {},       // 전자서명 사인 정보
        eltrnSaveData: {},    // 전자서명 세부 정보
        contInfoData: {},     // 채널고객 기본 정보(계약자)
        mnisdInfoData: {},    // 채널고객 기본 정보(피보험자)
        fatcaData: {},        // FATCA 개인고객정보
        bankData: {},         // 등록 계좌
        gafData: {},          // 심사결과 정보
        /* 보험거래문서디지털화 */
        pvmnotice: {},        // 고지정보
        jsonInputData: {}     // 발행 Json 전문
      },
      reSignerData: {},       // 전자서명 결과
      lmsObject: {
        eltrnDoctId: '',      // 전자문서 ID
        vuchNo: '',           // 영수증 번호
        prdtNm: '',           // 상품명
        prcd: '',             // 상품코드
        custNm: '',           // 고객명
        chnlCustId: '',       // 채널고객ID
        celNo: '',            // 고객 핸드폰
        celNoHash: ''         // 휴대폰 번호 Hash
      }, // LMS 정보 저장
      scnAcpnChackData: [],   // 접수가능 여부 체크 항목
      completeData: {},
      isSignerComplete: [],   // 전자서명 중단건 확인
      isMobile: false,        // 모바일 여부
      serverChoice: '',       // 서버 상태(개발 development, 검증 stage, 운영 production)
      //lv_FofAcpnShow: false,  // [생체인증추가] 지접점수처리 메시지확인창
      lv_MsgFofAcpn: '전자서명[부인방지]처리에 실패하였습니다.<br />서면동의서 작성 후<br />지점접수를 진행하시겠습니까?', // [생체인증추가] 지접점수처리 메시지
      lv_FofAcpnYn: false,          // [생체인증추가]지점접수처리 요청여부
      doubleUploadFlag_NAS: false,  // uploadPlugin 중복 호출 방지 처리 NAS flag
      doubleUploadFlag_DC: false,   // uploadPlugin 중복 호출 방지 처리 DC flag,
      // 전자서명 전문 저장용 temp
      sendElstTlgm: {},
      receiveElstTlgm: {},
      test_srcdt: '', // 영업일테스트용(개발,검증용)(20221220_PJO)
      currDt: '', // 현재일자변수화(20221220_PJO)
      nundwYn: 'N', // 선심사여부 (선심사PJT(2024-01-29))
      nundwNrmYn: '' // 선심사 정보 이상 유무 (선심사PJT(2024-01-29))
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  beforeRouteEnter (to, from, next) { 
    window.vue.getInstance('vue').$commonUtil.selListEACommCd('ZA_CMUCO_SC_CD')
    .then(function () { 
      console.log('◆◆◆ beforeRouteEnter !!! then result~')
      next()
     })

   },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    console.log('◆◆◆ created !!! 호출')
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
    this.fn_init()
    // 개발.검증일 경우 업무config의 영업일로 세팅  후  +10일 영업일리스트  재조회 (20211221_PJO)
    this.currDt = PSDateUtil.fn_CurrentDate() // 현재날짜 (20211221_PJO)
    if ( process.env.NODE_ENV !== 'production' ) {
      //this.serviceCallTest()
    }
    
    this.fn_IsBioAthntEnv() // 지문촬영 가능 여부 셋팅

  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
    return
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
     // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {
    getProgress () {
      return this.getStore('progress').getters.getState
    }
  },//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {

  /*-----------------------------------------------------------------------------------------------
  * 테스트 처리 
  ------------------------------------------------------------------------------------------------*/
  fn_testCase () {

  },

  /*-----------------------------------------------------------------------------------------------
  * 초기 처리 영역
  ------------------------------------------------------------------------------------------------*/

  /************************************************************************************************
  * Function명  : fn_init
  * 설명        : 초기화
  ************************************************************************************************/

  fn_init () {
    this.isMobile = window.vue.getInstance('vue').$commonUtil.isMobile() // 모바일 여부

    this.serverChoice = process.env.NODE_ENV // 현재 접속 앱 설정(개발 development,검증 stage,운영)
    this.itemData.userId = PSServiceManager.getUserInfo('userId')
    this.STORAGE_ROOT_PATH = this.$bizUtil.cloneDeep(window.vue.getStore('psStore').getters.getStorageRootPath)
    /* 21.11 young21.kwon ASR211100071 사랑On 전자서명 중단건 재처리 수정
      사랑온 로그인후, 한번도 전자서명 하지 않은 상태일때, psStore 루트패스에는 값이 없슴.
      이로인해, 재전송중 PSFileCopyPlugin에 루트패스값이 전달되지 않아, 경로에 PDF파일이 없다는 오류 발생.
      ==> psStore에 루트패스값이 없으면 강제 set
    */
    if (this.$bizUtil.isEmpty(this.STORAGE_ROOT_PATH)) {
      this.STORAGE_ROOT_PATH = '/storage/emulated/0'
    }

    this.PATH_PDF_FOLDER_DATE = PSDateUtil.fn_CurrentDate() // 페이지 로딩 시점 날자(yyyyMMdd) 저장

    // 운영계가 아니라면 전자서명 로그 값이 없기때문에 작동 불필요 함
    if (this.serverChoice !== 'production') {
      // 운영계가 아니라면 로그 삭제 처리
      window.vue.getStore('psStore').dispatch('DEBUG_LOG_DELETE')
    } // end if

    this.fn_trace('info', this.PATH_PDF_FOLDER_DATE + ' :: ' + this.serverChoice + ' :: ' + this.STORAGE_ROOT_PATH)
    // 화면 전달 이후 분기 처리
    if (this.$route.params && this.$route.params.step) {
      this.reqNameID = this.$route.params.reqNameID
      
      if (this.$route.params.mobpsYn !== undefined) { // 모바일청약여부:MSSPS230M(AS-IS: TSSPS230D)에서 호출시 Y로 설정
        this.mobpsYn = this.$route.params.mobpsYn
      }
      this.fn_BaseDataSetting(this.$route.params.itemData, this.$route.params.step)
    } // end else if
  },

 /******************************************************************************
  * Function명 : fn_BaseDataSetting
  * 설명       : 화면에 전달 받은 Data 세팅
  * 전자서명 앱에 전달 할 필요 기본 Data 조회 순서(TSSPS110M)
  ******************************************************************************/
  fn_BaseDataSetting (baseData, step) {
    this.itemData.serverStatus = (this.serverChoice === 'production') ? '1' : '0' // 운영:1, 개발/검증:0
    
    //다이나트레이스 세팅
    let vLog = ''
    if (this.reqNameID !== 'undefined' && this.reqNameID !== undefined) {    
      vLog = this.reqNameID
    } else {
      vLog = 'N/A'
    }

    // 전자서명 인증 및 정보 입력 화면에서 this.reqNameID === 'TSSPS110M'
    if (this.reqNameID === 'MSPPS420M') { 
      this.fn_ShowLoading(true, '1') // 화면 로딩 처리
      this.itemData.planId = baseData['entplId'] // 설계 ID
      this.itemData.vuchId = this.itemData.baseData.eltrnDoctId = baseData.moDCDocuMngVO['eltrnDoctId'] // 문서명(영수증번호)
      
      /* 보험거래문서디지털화 : 전자서명 완료후 경로로 변경 */
      let tbtPbFilePath = this.STORAGE_ROOT_PATH + this.LOCAL_PATH_PDF_UPLOAD + '/'
      this.itemData.fullFilePath = tbtPbFilePath + this.itemData.vuchId + '.pdf'
      /* 보험거래문서디지털화 */
      this.itemData.jsonInputData = baseData['jsonInputData'] // 발행전문JSON
      this.itemData.pvmnotice = baseData['pvmnotice'] // 고지사항

      /* 선심사여부 로직 추가 - 선심사PJT(2024-01-29) */
      this.nundwYn = baseData['nundwYn']
      this.nundwNrmYn = baseData['nundwNrmYn']
      // 1. NUNDW_YN(선심사여부) 필드값 추가
      let lv_Vm = this      
      if (this.itemData.pvmnotice['signerDetail'] && this.itemData.pvmnotice['signerDetail'].length > 0) {
        this.itemData.pvmnotice['signerDetail'].forEach((el, i) => {
          if (lv_Vm.nundwYn === 'Y') { // 선심사여부가 Y인 경우, 기본고지, 상세고지, 스마트알릴고지 모두 N으로 처리
            el.pvmnotice.NUNDW_YN = 'Y'
            el.pvmnotice.BAS_DSCLS_EXNEX = 'N'
            el.pvmnotice.CLM_HSTR = 'N'
            el.pvmnotice.DTL_DSCLS_EXNEX = 'N'
          } else {
            el.pvmnotice.NUNDW_YN = 'N'
          }
        })
      }

      // 2. NUNDW_YN === Y인 경우 pvmnotice정보를 선심사에서 세팅한 정보로 변경처리
      if (lv_Vm.nundwYn === 'Y') {
        console.log('pvmnotice정보 세팅하기')
        let tmpNotice = baseData['nundwPvmnotice']
        
        let formData = lv_Vm.itemData.pvmnotice['pvmnotice'].formData
        // 이전고지 불러오기 데이터 초기화 하기
        for (let j = 0; j < formData.length; j++) {
          let tmpInput = formData[j].Input
          Object.keys(tmpInput).forEach((key) => {
            tmpInput[key] = ''
          })
        }

        Object.keys(tmpNotice).forEach((key) => {
          for (let i = 0; i < formData.length; i++) {
            formData[i].Input[key] = tmpNotice[key]
          }
        })
      }
    } else if (this.reqNameID === 'MSPPS420M_1') { // 설계청약-전자서명 this.reqNameID === 'TSSPS110M_1'
      this.fn_ShowLoading(true, '1') // 화면 로딩 처리
      this.itemData.planId = baseData['entplId'] // 설계 ID
      this.itemData.vuchId = this.itemData.baseData.eltrnDoctId = baseData.moDCDocuMngVO['eltrnDoctId'] // 문서명(영수증번호)
      
      let tbtPbFilePath = this.STORAGE_ROOT_PATH + this.LOCAL_PATH_PDF_UPLOAD + '/'
      this.itemData.fullFilePath = tbtPbFilePath + this.itemData.vuchId + '.pdf'
      this.itemData.ozdFilePath = baseData['ozdFilePath']
      
      /* 선심사여부 로직 추가 - 선심사PJT(2024-01-29) */
      this.nundwYn = baseData['nundwYn']
      this.nundwNrmYn = baseData['nundwNrmYn']
      
    } else if (this.reqNameID.indexOf('MSPPS240M') > -1) { // 설계청약-전자서명 TSSPS213D
      this.fn_ShowLoading(true, '1') // 화면 로딩 처리
      this.itemData.planId = baseData['elstPlanId'] // 설계 ID
      this.itemData.vuchId = this.itemData.baseData.eltrnDoctId = baseData['vuchId'] // 문서명(영수증번호)

      this.fn_NundwData() // // 전자서명 재전송인 경우 선심사 정보 조회 호출하도록 기능 추가

    } else if (this.reqNameID === 'MSPPS000M') { // 테스트  TSSPS000M
      this.fn_ShowLoading(true, '1') // 화면 로딩 처리
      this.itemData.planId = baseData['entplId']
      this.itemData.vuchId = this.itemData.baseData.eltrnDoctId = baseData.moDCDocuMngVO['eltrnDoctId'] // 문서명(영수증번호)
      
      if ( process.env.NODE_ENV === 'local' ) {
        // this.fn_ComponentChange('7')
        console.log('process.env.NODE_ENV--------------------------------')
        this.serviceCallTest() // 영업일테스트
        return
      }
    } // end else if

    // 다이나트레이스 세팅_start    
    let tmpVuchId = ''
    if (this.itemData.vuchId !== 'undefined' && this.itemData.vuchId !== undefined) {
      tmpVuchId = this.itemData.vuchId
    }    
    PSServiceManager.setDynaTraceLog('fn_BaseDataSetting', vLog, 'MSPPS610M', tmpVuchId) // event명, vLog
    
    PSElstInitUtil.fn_EltrnSignDocService(vLog, this.itemData, this.fn_EltrnSignDocServiceResultHandler)
  },
  /************************************************************************************************
   * Function명  : fn_EltrnSignDocServiceResultHandler
   * 설명        : 기초  Data 호출 결과 함수 정의
   ************************************************************************************************/
  fn_EltrnSignDocServiceResultHandler (event) {
    if (!event.error) {
      this.fn_trace('info', '전자서명 기본 Data 호출 완료', event, 'itemData')
      this.itemData = event.itemData
      this.planNm = event.itemData.eltrnSaveData.mnPrdtNm // 상품 명
      this.prcd = event.itemData.eltrnSaveData.prcd // 상품 코드

      //if (this.reqNameID === 'TSSPS110M' || this.reqNameID === 'TSSPS110M_1') { // 전자서명앱 호출
      if (this.reqNameID === 'MSPPS420M' || this.reqNameID === 'MSPPS420M_1') { // 전자서명앱 호출
        if (!this.itemData.signerData.insrOfrYmd.trim()) {
          this.fn_trace('info', '전자서명 앱 처음 실행 일자 입력')
          this.fn_ServiceData('U1') // 전자서명 최초 실행 일자 입력
        } else {
          this.fn_ServiceData('bef_ES') // 전자서명 호출전  전문 저장
        }// end if
      } else if (this.reqNameID === 'MSPPS240M_1') { // 현장접수 TSSPS213D_1
        this.fn_ComponentChange('1') // 접수 화면
      } else if (this.reqNameID === 'MSPPS240M_2') { // 현장 출금 TSSPS213D_2
        this.fn_ComponentChange('2') // 이체 화면
      } else if (this.reqNameID === 'MSPPS240M_3') { // 재전송 TSSPS213D_3
        this.fn_ShowLoading(true, '2') // 화면 로딩 처리
        this.fn_trace('info', '계피상이?', this.itemData.signerData.contrInsrdSameYn )
        
        // [생체인증추가] 전자서명(부인방지) 미처리시 분기처리)
        if ( this.itemData.signerData.contrInsrdSameYn === 'N' ) {   
          //최종전문조회하여 피보험자서명동의서스킵여부 값에 따라 분기
          this.fn_ServiceData('S8') // 전자서명 서면동의생략여부 조회
        } else {
          if (this.itemData.signerData['elstCnclnYmd'] === '99999999') { // 전자서명 1단계 오류 일떄
          	//AML 우려사항 확인 CASE
            PSScnAcpnUtil.fn_ScnAcpnService(this.itemData, this.fn_ScnAcpnChackServiceResultHandler) // 청약접수 가능 여부
          } else {            
            this.fn_GetPdfFileList()
          } 
        }
      } else if (this.reqNameID === 'MSPPS240M_3_ERR') { // 재전송-비정상 종료건  TSSPS213D_3_ERR
        this.fn_ServiceData('S9') // 전자서명 완료 전문 조회
      } 

    } else {
      this.fn_trace('error', '전자서명 기본 Data 호출 실패', event, 'itemData')      
      this.fn_AlertMsg('baseData', '전자서명 구성 중 오류가 발생하였습니다.<br /><br />' + event.error, '확인|재시도') 
    } // end if
  },
  /************************************************************************************************
   * Function명  : fn_NundwData
   * 설명        : 전자서명 재전송인 경우 선심사 정보 조회 호출하도록 기능 추가 (선심사PJT-ASR240501074)
   ************************************************************************************************/
  fn_NundwData () {
    let pParams = {}
    pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS99S15'))
    pParams.srnId = this.$options.screenId // 호출 화면 명
    pParams.data.vuchId = this.itemData.vuchId
    pParams.data.elstPlanId = this.itemData.planId
    PSServiceManager.invoke(pParams, this.fn_NundwServiceResultHandler, 'NUNDW', this.fn_ServiceFaultHandler)
  },
  /************************************************************************************************
   * Function명  : fn_NundwServiceResultHandler
   * 설명        : 선심사정보 결과 조회 및 nundwYn, nundwNrmYn변수에 저장 (선심사PJT-ASR240501074)
   ************************************************************************************************/
  fn_NundwServiceResultHandler: function (event) {
    let lv_data = event.data
    this.nundwYn = lv_data.nundwYn // 선심사사용여부
    if (this.nundwYn == 'Y') {
      this.nundwNrmYn = lv_data.nundwNrmYn // 선심사고지정상 여부 (EP에 고지정보 전달시 함께 전달되는 변수)
    }
  },
  /************************************************************************************************
   * Function명  : fn_ScnAcpnChackServiceResultHandler
   * 설명        : 청약접수 가능 여부 호출 결과 처리
   ************************************************************************************************/
  fn_ScnAcpnChackServiceResultHandler (event) {
    if (event.returnData.scnAcpnPsbYn === 'N') {
      this.fn_trace('info', '현장 접수 불가', event)
      // 현장 접수 불가건
      this.itemData.signerData['scnAcpnPsbYn'] = 'N'
      this.scnAcpnChackData = event
    } else {
      this.fn_trace('info', '현장 접수 가능')      
      this.itemData.signerData['scnAcpnPsbYn'] = 'Y'
      this.scnAcpnChackData = event
    } // end else if

    // 조회 Data 저장
    this.fn_ServiceData('U9') // 청약일자 입력 및 수정
  },
  /************************************************************************************************
   * Function명  : fn_AcpnDalwChkSetting
   * 설명        : 접수불가건체크 결과 업데이트
   ************************************************************************************************/
  fn_AcpnDalwChkSetting: function () {
    let inputData = Object.assign({}, ElstDtlInfoVO())
    try {
      inputData.vuchId = this.itemData.baseData.eltrnDoctId // 영수증ID
      inputData.eusUndwCmpltYmd = this.scnAcpnChackData.returnData.eusUndwCmpltYmd // AEUS 여부
      inputData.crinfCnsntYn = this.scnAcpnChackData.returnData.crinfCnsntYn // 신용정보제공동의여부
      inputData.ukepInvwObjYn = this.scnAcpnChackData.returnData.ukepInvwObjYn // 유지면담대상여부
      inputData.amlObjYn = this.scnAcpnChackData.returnData.amlObjYn // AML대상여부
      inputData.selfContYn = this.scnAcpnChackData.returnData.selfContYn // 본인계약여부
      inputData.bnfrChgYn = this.scnAcpnChackData.returnData.bnfrChgYn // 수익자변경여부
      // 현장접수 불가건 추가
      inputData.nobMultpYn = this.scnAcpnChackData.returnData.nobMultpYn // 알릴의무중복여부
      inputData.gaOnpstYn = this.scnAcpnChackData.returnData.gaOnpstYn // GA소속여부
      inputData.scnAcpnRstcYn = this.scnAcpnChackData.returnData.scnAcpnRstcYn // 현장접수제한여부
      inputData.frgnIdcTofCnfObjYn = this.scnAcpnChackData.returnData.frgnIdcTofCnfObjYn // 외국인신분증인증대상여부
      inputData.fofOfwrHldofYn = this.scnAcpnChackData.returnData.fofOfwrHldofYn // 지점사무직재직여부
      inputData.diabtWrtUnSlctYn = this.scnAcpnChackData.returnData.diabtWrtUnSlctYn // 당뇨작성미선택여부
    } catch (error) {
      this.fn_trace('error', '전자서명 입력 Data :fn_AcpnDalwChkSetting 오류', inputData)
      return null
    } // end try..catch
    return inputData
  },

  /*-----------------------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ------------------------------------------------------------------------------------------------*/

  /************************************************************************************************
  * Function명  : fn_DevicBackBtnHandler
  * 설명        : 디바이스 Back Button 핸들러
  ************************************************************************************************/
  fn_DevicBackBtnHandler() {
    if(PSCommUtil.fn_IsActiveModal()) return
    this.fn_HeaderBackBtnHandler()
  },

  /******************************************************************************
  * Function명  : fn_HeaderBackBtnHandler
  * 설명        : 헤더 이전버튼 서비스 호출 함수
  ******************************************************************************/
  fn_HeaderBackBtnHandler() {
    if (this.currentStep === '7') { // 7:[생체인증추가]피보험자 지문촬영
      this.fn_AlertMsg('HeaderbackBtnBio', '피보험자 지문촬영 및 전자서명을 중단하시겠습니까?', '취소|확인') 
    }
    else {
      this.fn_AlertMsg('HeaderbackBtn', '접수과정 없이 청약을 완료하시겠습니까?<br /> 이후 지점을 통해서도 진행이 가능합니다.', '취소|확인') 
    }
  },// fn_HeaderBackBtnHandler

  /******************************************************************************
  * Function명 : Function명 : fn_ChangeTabIdx
  * 설명       : 컴포넌트 변경 이벤트 처리
  ******************************************************************************/
  fn_ChildsActivatedEvnet () {    
    if (this.currentComponent != 'MSPPS611D') return
    this.$refs.component.fn_SearchEvent()
  },

  /******************************************************************************
   * Function명 : fn_ComponentChange
   * 설명       : 컴포넌트 화면 제어
   *   step 1 : 전자서명 완료 화면
   *   step 2 : 청약접수 완료 화면
   *   step 3 : 1회 보험료 이체 신청 완료 화면
   *   step 4 : 신계약체결 완료 화면
   *   step 7 : 피보험자 지문촬영
   *   step 5 : 전자서명 완료 화면 (계피상이 일때 한명만 서명 했을때)
   *   step 6 : 청약접수 완료 화면 (접수 불가건, 2단계 완료건)   
   ******************************************************************************/
  fn_ComponentChange (step) {
    this.fn_ShowLoading(false, ' ') // 화면 로딩 처리
    if (step === '0') {
      this.compKey = this.currentItems[0]['compKey']
      this.currentComponent = this.currentItems[0]['component']
      this.currentTitle = this.currentItems[0]['title']
      this.currentStep = step
      
    } else if (step === '5') { // 전자서명완료
      this.compKey = this.currentItems[2]['compKey']
      this.currentComponent = this.currentItems[2]['component']
      this.currentTitle = this.currentItems[2]['title']
      this.currentStep = step      
    } else if (step === '6') { // 청약접수완료
      this.compKey = this.currentItems[3]['compKey']
      this.currentComponent = this.currentItems[3]['component']
      this.currentTitle = this.currentItems[3]['title']
      this.currentStep = step      
    } else if (step === '7') { // [생체인증추가]피보험자 지문촬영      
      this.compKey = this.currentItems[4]['compKey']  
      this.currentComponent = this.currentItems[4]['component']
      this.currentTitle = this.currentItems[4]['title']
      this.currentStep = step      
    } else { // step 1,2,3,4
      this.compKey = this.currentItems[1]['compKey']
      this.currentComponent = this.currentItems[1]['component']
      this.currentTitle = this.currentItems[1]['title'][step]
      this.currentStep = step      
    } // end else if
  },

  /******************************************************************************
   * Function명 : fn_TitlChange
   * 설명       : 타이틀 제어
  ******************************************************************************/
  fn_TitlChange (step) {
    if (step === '0') {
      this.currentTitle = this.currentItems[0]['title']
    } else if (step === '5') { // 전자서명완료
      this.currentTitle = this.currentItems[2]['title']
    } else if (step === '6') { // 청약접수완료
      this.currentTitle = this.currentItems[3]['title']
    } else if (step === '7') { // [생체인증추가]피보험자 지문촬영      
      this.currentTitle = this.currentItems[4]['title']
    } else { // step 1,2,3,4
      this.currentTitle = this.currentItems[1]['title'][step]
    } // end else if
  },
  /************************************************************************************************
   * Function명  : fn_IsBioAthntEnv
   * 설명        : 생체인증(지문) 가능여부
   ************************************************************************************************/
  async fn_IsBioAthntEnv() {
    this.isFlashEnv = await PSCommUtil.fn_IsBioAthntEnv() 
    console.log('fn_IsBioAthntEnv:' + this.isFlashEnv)
  },
  /************************************************************************************************
   * Function명  : fn_PrgPhseOpenPopup
   * 설명        : 전자서명 진행단계(MSPPS241P AS-IS:TSSPS211P) 팝업
   ************************************************************************************************/
  fn_PrgPhseOpenPopup: function () {
    const retryPopup = this.$bottomModal.open(MSPPS241P, {
      properties: {
        rowPopupData : this.rowPopupData
      },
      listeners: {
        // 대상팝업 onPopupConfirm $emit 이벤트명 
        onPopupConfirm: (rtnData) => {
          this.fn_PrgPhseClosePopup(rtnData)
          this.$bottomModal.close(retryPopup)     // 모달 닫기
        },
        // 재처리하지 않고 닫았을때
        onPopupClose: () => {
          this.$bottomModal.close(retryPopup)     // 모달 닫기
          this.fn_MovePage('6')
        }
      }
    })
  },
  /************************************************************************************************
   * Function명  : fn_PrgPhseClosePopup
   * 설명        : 전자서명 진행단계(MSPPS241P AS-IS:TSSPS211P) POP-UP 창 팝업 닫기 핸들러
   ************************************************************************************************/
  fn_PrgPhseClosePopup: function (pData = null) {
    console.log('fn_PrgPhseClosePopup::::' + JSON.stringify(pData))
    if (pData[0]['completion'] && pData[1]['completion']) {
      if (this.itemData.signerData['scnAcpnPsbYn'] === 'N') {
        this.fn_ComponentChange('5') // 전자서명완료 화면으로 변경
      } else {
        this.fn_ComponentChange('1') // 현장 접수 화면으로 변경
      } // end else if
    } else {
      this.fn_trace('error', '전자서명 2단계 이상 처리 오류')
    } // end else if
  },

  /******************************************************************************
  * Function명 : fn_MovePage
  * 설명       : 설계청약 홈으로 이동
  ******************************************************************************/

  fn_MovePage: function (currentStep) {
    let routerParam

    if (currentStep === '1') { // 설계청약-전자서명 탭으로 이동
      // 접수과정 없이 청약을 완료하시겠습니까?<br>이후 지점을 통해서도 진행이 가능합니다. TSSPS213D
      this.fn_AlertMsg('MSPPS240M', this.$t('ps')['EPSC019'], '취소|확인') 
    } else if (currentStep === '2') { // 설계청약-전자청약 탭으로 이동
      // 초회보험료 이체 없이 청약을 완료하시겠습니까?<br>이후 지점을 통해서도 진행이 가능합니다. TSSPS213D
      this.fn_AlertMsg('MSPPS240M', this.$t('ps')['EPSC022'], '취소|확인')
    } else if (currentStep === '4') { // 설계청약-신계약진행 탭으로 이동
      routerParam = {name: 'MSPPS250M', params: {tabIdx: '1'}}
      PSCommUtil.fn_RoutepushSetKeepAlive(routerParam, false)
    } else if (currentStep === '5') { // 설계청약-전자서명(중단건) 탭으로 이동
      if (this.mobpsYn === 'Y') {        
        //this.$MenuManager.fn_RouterPushSrnId('MSPPS230M', {radioTable: '2'}) // #2020모바일청약, 모바일청약의 접수출금시 모바일청약목록화면으로 이동 TSSPS230D
        routerParam = {name: 'MSPPS230M', params: {tabIdx: '1'}}
        PSCommUtil.fn_RoutepushSetKeepAlive(routerParam, false)
      } else {
        //this.$MenuManager.fn_RouterPushSrnId('MSPPS240M', {radioTable: '2'})
        routerParam = {name: 'MSPPS240M', params: {tabIdx: '1'}} //중단건
        PSCommUtil.fn_RoutepushSetKeepAlive(routerParam, false)
      }
    } else if (currentStep === '6') { // 설계청약-전자서명(완료건) 탭으로 이동
      if (this.mobpsYn === 'Y') {
        //this.$MenuManager.fn_RouterPushSrnId('MSPPS230M', {radioTable: '1'}) // #2020모바일청약, 모바일청약의 접수출금시 모바일청약목록화면으로 이동 TSSPS230D
        routerParam = {name: 'MSPPS230M', params: {tabIdx: '2'}}
        PSCommUtil.fn_RoutepushSetKeepAlive(routerParam, false)
      } else {
        //this.$MenuManager.fn_RouterPushSrnId('MSPPS240M', {tabIdx: '2'}) //전자서명 진행 현황 TSSPS213D
        routerParam = {name: 'MSPPS240M', params: {tabIdx: '2'}} //완료건
        PSCommUtil.fn_RoutepushSetKeepAlive(routerParam, false)
      }
    } else {
      if (this.mobpsYn === 'Y') {
        //this.$MenuManager.fn_RouterPushSrnId('MSPPS230M', {tabIdx: '1'}) // #2020모바일청약, 모바일청약의 접수출금시 모바일청약목록화면으로 이동 TSSPS230D
        routerParam = {name: 'MSPPS230M', params: {tabIdx: '2'}}
        PSCommUtil.fn_RoutepushSetKeepAlive(routerParam, false)
      } else {
        if (currentStep === 'MSPPS240M') {
          routerParam = {name: currentStep, params: {tabIdx: '2'}} //완료건
          PSCommUtil.fn_RoutepushSetKeepAlive(routerParam, false)
        }
        else {
          this.$MenuManager.fn_RouterPushSrnId(currentStep)
        }
      }
    } // end else if
  },

  /************************************************************************************************
   * Function명  : fn_OnPositive
   * 설명        : fdpConfirm 예 버튼 클릭 시, 콜백
   ************************************************************************************************/
  fn_OnPositive: function (type) {
    if (type === 'download') { // PDF 다운로드
      this.fn_ServiceData('S0')
    } else if (type === 'upload') { // PDF 업로드
      // this.fn_UploadPlugin()
      this.fn_UploadTimeOut() // 업로드 진행시 일자 체크 로직 사용
    } else if (type === 'timeOut') { // 전자서명 시간 오버로 폐기 처리
      this.fn_PdfFileDelCachFile()
      this.fn_MovePage('MSPPS210M') // 전자서명 최근 설계 화면으로 이동 TSSPS211D
    } else if (type === 'complete') { // 전자서명 완료      
      if ( this.itemData.signerData.contrInsrdSameYn === 'N' ) { // 계피상이
        this.fn_PrcsLiveAthntRslt() // 생체인증 결과 전송
      } else {
        this.fn_ServiceData('H')
      }
    } else if (type === 'contYmdCheck') { // 191112. 계약일,접수일자 alert 표시 후 전자서명완료
      this.fn_PDFSignComplete()
    } else if (type === 'signerComplete') { // 전자서명 사인 완료
      this.fn_MovePage('5')
    } else if (type === 'lmsComplete') { // LMS 전송
      PSScnAcpnUtil.fn_ScnAcpnService(this.itemData, this.fn_ScnAcpnChackServiceResultHandler) // 청약접수 가능 여부
    } else if (type === 'eltrnSignPhse') { // 전자서명 단계 처리 오류
      this.fn_MovePage('5') // 전자서명 목록으로 이동
    //} else if (type === 'TSSPS213D' || type === 'TSSPS214D' || type === 'TSSPS215D') {
    } else if (type === 'MSPPS240M' || type === 'MSPPS250M') { // 전자서명 앱 결과 JSON (TSSPS214D 고대전자청약 사용안함)
      this.fn_MovePage(type)
    } else if (type === 'baseData') { // 전자서명 필수 Data 호출 실패
      PSElstInitUtil.fn_EltrnSignDocService(this.reqNameID, this.itemData, this.fn_EltrnSignDocServiceResultHandler)
    } else if (type === 'bef_ES') { // 전자서명앱 호출 전 전문 저장 실패
      this.fn_ServiceData('bef_ES')
    } else if (type === 'aft_ES') { // 전자서명앱 호출 후 전문 저장 실패
      this.fn_ServiceData('aft_ES')
    } else if (type === 'PdfFileCopy') {
      this.fn_PdfFileCopy()
    } else if (type === 'S8') { // 전자서명 서면동의생략여부 조회 실패
      this.fn_MovePage('5')
    } else if (type === 'S9') { // 전자서명 완료 전문 조회 실패
      this.fn_MovePage('5')
    } else if (type === 'I8') { // 전자서명 설계 및 청약/접수 상세 정보 입력
      this.fn_ServiceData('I8')
    } else if (type === 'HeaderbackBtn') { // 헤더 이전버튼 클릭시
      this.fn_MovePage('6') // 전자서명(완료) 목록으로 이동
    } else if (type === 'HeaderbackBtnBio') { // 헤더 이전버튼 클릭시 (지문인증)
      this.fn_MovePage('5') // 전자서명(완료) 목록으로 이동
    } else if (type === 'fofAcpn') { // 전자서명 부인방지처리 실패시 지점접수
      this.fn_FofAcpnResult(true)
    }// end else if
  },

  /************************************************************************************************
   * Function명  : fn_OnNegative
   * 설명        : dpConfirm 아니오 버튼 클릭 시, 콜백
   ************************************************************************************************/
  fn_OnNegative: function (type) {
    if (type === 'download') {
      this.fn_MovePage('MSPPS210M') 
      //TSSPS211D
    } else if (type === 'recomp') {
      this.fn_ServiceData('U6_end') // 전자서명체결 실패
    } else if (type === 'upload') {
      this.fn_MovePage('5')
    } else if (type === 'baseData') { // 전자서명 필수 Data 호출 실패
      if (this.serverChoice === 'production') {
        this.fn_MovePage('MSPPS220M') //TSSPS212D 청약발행현황
      } // end if
    } else if (type === 'bef_ES') { // 전자서명앱 호출 전 전문 저장 실패
      this.fn_MovePage('MSPPS220M') // 설계청약홈-청약서발행 화면으로 이동 TSSPS212D
    } else if (type === 'aft_ES') { // 전자서명앱 호출 후 전문 저장 실패
      this.fn_MovePage('MSPPS220M') // 설계청약홈-청약서발행 화면으로 이동 TSSPS212D
    } else if (type === 'PdfFileCopy') { // 파일업로드 전처리 스킵
      this.fn_MovePage('5') // 중단건 조회 화면으로 이동
    } else if (type === 'I8') { // 전자서명 설계 및 청약/접수 상세 정보 입력 전처리 스킵
      this.fn_MovePage('5') // 중단건 조회 화면으로 이동
    } else if (type === 'fofAcpn') { // 전자서명 부인방지처리 실패시 - 재요청
      this.fn_FofAcpnResult(false)
    } // end else if
  },

  /*-----------------------------------------------------------------------------------------------
  * Data Service 핸들러 영역
  ------------------------------------------------------------------------------------------------*/
  /******************************************************************************
  * Function명  : fn_ServiceData
  * 설명        : 서비스 호출 함수 정의
  ******************************************************************************/
  fn_ServiceData: function (serviceName) {
    let pParams = {}
    
    if (serviceName !== 'H') {
      this.fn_ShowLoading(true, ' ') // 화면 로딩 처리
      this.isLoadingStatus = true
    }

    switch (serviceName) {
      case 'S0': // NAS 영역에 PDF 파일이 생성 체크
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS19S2'))
        pParams.data = {}
        pParams.data.moblBusnScCd = 'SE' // 모바일업무구분코드(SE:청약)
        pParams.data.eltrnDoctScCd = '01' // 전자문서구분코드 01:청약서 고정
        pParams.data.eltrnDoctId = this.itemData['vuchId'] // 전자문서ID
        break
      case 'U1': // 전자서명앱최초입력날자수정 (발행일자)
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS98U5'))
        pParams.data = {}
        pParams.data.vuchId = this.itemData.baseData['eltrnDoctId'] // 전자문서ID
        pParams.data.elstPrgStatCd = '10' // 10:서명중/20:서명완료/30:접수완료/40:이체완료
        break
      case 'bef_ES': // 전자서명 호출 전 전문 저장
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS99I3'))
        pParams.data.vuchId = this.itemData.baseData['eltrnDoctId'] // 전자문서ID

        //PSElstInitUtil.fn_EltrnSignDocService 호출시 값 셋팅됨 
        this.sendElstTlgm = pParams.data.jsonInputData = PSDocFormUtil.setJsonData(
          this.itemData.userId, // FC 사번
          this.itemData.serverStatus, // 서버 상태
          this.itemData.baseData, // 발행 Data
          this.itemData.eltrnSaveData, // 전자서명 저장 Data
          this.itemData.contInfoData, // 계약자 기본정보 = AML 계약자 채널고객 기본정보
          this.itemData.mnisdInfoData, // 피보험자 기본정보
          this.itemData.fatcaData, // FATCA 정보
          this.itemData.bankData, // 은행 정보
          this.itemData.signerData, // 서명 정보
          this.itemData.gafData, // 심사정보
          this.itemData.dpstrInfo, // 예금주정보
          /* 보험거래디지털화PJT 추가 */
          this.itemData.pvmnotice, // 고지정보
          this.itemData.jsonInputData, // 발행 Json 전문
          /* AML 대상자 기본정보 */          
          this.itemData.amlPrtrData01 , // AML 친권자01 채널고객 기본정보
          this.itemData.amlDthBnfrData01, //AML 사망수익자01 채널고객 기본정보
          this.nundwYn,// 선심사여부
          this.itemData.cardData // 신용카드 (ASR240400333_24.05 장한나)
        )
        this.fn_trace('info', '전자서명앱 호출전 전문 저장 시작', pParams.data)
        break
      case 'aft_ES': // 전자서명 호출 후 전문 저장
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS99I3'))
        pParams.data.vuchId = this.itemData.baseData['eltrnDoctId'] // 전자문서ID
        pParams.data.jsonInputData = this.receiveElstTlgm
        this.fn_trace('info', '전자서명앱 호출후 전문 저장 시작', pParams.data)
        break
      case 'U4': // 계/피/친/예 전자서명 수정
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS98U2'))
        pParams.data = {}
        pParams.data.vuchId = this.itemData.baseData['eltrnDoctId'] // 전자문서ID
        pParams.data.elstPrgStatCd = this.itemData.signerData['elstPrgStatCd'] // 10:서명중/20:서명완료/30:접수완료/40:이체완료
        pParams.data.contrElstYn = this.itemData.signerData['contrElstYn'] // 계약자전자서명여부
        pParams.data.insrdElstYn = this.itemData.signerData['insrdElstYn'] // 피보험자전자서명여부
        pParams.data.aisdElstYn = this.itemData.signerData['aisdElstYn'] // 종피보험자전자서명여부
        pParams.data.prtrPesonElstYn = this.itemData.signerData['prtrPesonElstYn'] // 친권자전자서명여부
        pParams.data.dpstrElstYn = this.itemData.signerData['dpstrElstYn'] // 예금주전자서명여부
        pParams.data.fcElstYn = this.itemData.signerData['fcElstYn'] // FC전자서명여부
        break
      case 'I8': // 전자서명 설계 및 청약/접수 상세 정보 입력
        //전자서명완료 고지사항 입력항목 Insert 시점
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS99I2'))
        pParams.data = this.fn_PSElstDtlInfoVOSetting()
        this.fn_trace('info', '전자서명 설계 및 청약/접수 VO 정보', pParams.data)
        break      
      // 보험거래문서디지털화 : 전자서명완료 처리 tx 변경(고지정보 추가)
      case 'P2': // 전자서명완료 - 보험거래문서디지털화 : 고지정보 추가한 신규 tx 적용
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS13P3'))
        pParams.data = this.fn_DCDocuMngVOSetting()
        this.doubleUploadFlag_DC = true
        console.log('fn_DCDocuMngVOSetting::after:::' + JSON.stringify(pParams.data))
        this.fn_trace('info', '전자서명완료 입력 VO 정보', pParams.data)
        break
      case 'U9': // 청약일자 입력 및 수정
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS99U13'))
        pParams.data = {}
        pParams.data.vuchId = this.itemData.baseData['eltrnDoctId'] // 전자문서ID
        break
      case 'U7': // 현장접수가능여부
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS98U3'))
        pParams.data = {}
        pParams.data.vuchId = this.itemData.baseData['eltrnDoctId'] // 전자문서ID
        pParams.data.scnAcpnPsbYn = this.itemData.signerData['scnAcpnPsbYn'] // 접수가능여부
        break
      case 'U8': // 전자서명앱 입력 데이터 수정
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS99U5'))
        pParams.data = this.fn_AcpnDalwChkSetting()
        break
      case 'S5': // 전자서명 단계별 처리 조회
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS13S5'))
        pParams.data = {}
        pParams.data.predMoblBusnScCd = 'SE' // 모바일업무구분코드
        pParams.data.predEltrnDoctScCd = '01' // 전자문서구분코드
        pParams.data.predEltrnDoctId = this.itemData.baseData['eltrnDoctId'] // 전자문서ID
        break
      case 'U6': // 전자서명체결 수정
      case 'U6_end': // 전자서명체결 실패
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS98U6'))
        pParams.data = {}
        pParams.data.vuchId = this.itemData.baseData['eltrnDoctId'] // 전자문서ID
        if (serviceName === 'U6_end') { // 전자서명 완전 실패 일때
          pParams.data.elst2PhseCmpltYn = 'N' // 전자서명완료2단계완료여부
          pParams.data.elst5PhseCmpltYn = 'N' // 전자서명완료5단계완료여부
          pParams.data.elstCnclnYmd = '99999999' // 전자서명완료5단계완료여부
        } else {
          pParams.data.elst2PhseCmpltYn = this.itemData.signerData['elst2PhseCmpltYn'] // 전자서명완료2단계완료여부
          pParams.data.elst5PhseCmpltYn = this.itemData.signerData['elst5PhseCmpltYn'] // 전자서명완료5단계완료여부
        } // end else if
        break
      case 'insLms': // 모바일완전판매 LMS 처리
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS29P2'))
        pParams.data = this.fn_LmsSendComplete(true)
        break
      case 'H': // 191112. 계약일,접수일자 alert 표시
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS13S9'))
        // 계약일자조회
        // 191104 : 계약일자 신규 추가
        //        : DCEltrnSignCmpltTltCtlImpl.eltrnSignCmplt / DCDocuMngVO.contYmd : String 8자리 ( YYYYMMDD )
        //        : 실시간이체   : 출금동의일자 -> 계약일
        //                      : 출금동의일자 : TSSPS141D.vue : 출금계좌제공선택후 [다음]
        //        : 가상계좌입금 : 전자서명완료일시(당일) -> 계약일
        let contYmd = this.currDt // 현재일자변수화(20221220_PJO) PSDateUtil.fn_CurrentDate()
        if (this.itemData.eltrnSaveData.frtmPrmPadMthCd === '1' || this.itemData.eltrnSaveData.frtmPrmPadMthCd === '9') { // 9 신용카드 (ASR240400333_24.05 장한나)
          contYmd = this.itemData.eltrnSaveData.dfraCnsntYmd
        }
        if (this.serverChoice !== 'production') { // 개.검인경우 테스트영업일사용
          contYmd = this.currDt
        }
        pParams.data = {slrcYmYmd: PSDateUtil.addDate(contYmd, +1)}
        break
      case 'S8': // 전자서명 서면동의생략여부 조회
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS99S8'))
        pParams.data.vuchId = this.itemData.baseData['eltrnDoctId'] // 전자문서ID
        this.fn_trace('info', '전자서명 서면동의생략여부 조회 시작', pParams.data)
        break
      /** 22.07 young21.kwon ASR220700176 / [전자서명] 재전송 오류 관련 조치내용 */
      case 'S9': // 전자서명 완료 전문 조회
        pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS99S9'))
        pParams.data.vuchId = this.itemData.baseData['eltrnDoctId'] // 전자문서ID
        this.fn_trace('info', '// 전자서명 완료 전문 조회 시작', pParams.data)
        break
      default:
        break
    } // end switch
    if (pParams.data !== null) {
      pParams.srnId = this.$options.screenId // 대표 화면 명
      PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler, true)
    } else {
      this.fn_trace('error', serviceName + '::조회 Data오류')
    } // end else if
  },



  /************************************************************************************************
   * Function명  : fn_ServiceDataResultHandler
   * 설명        : 서비스 호출 결과 함수 정의
   ************************************************************************************************/

  fn_ServiceResultHandler: async function (event, serviceName) {
    this.fn_ShowLoading(false, ' ') // 화면 로딩 처리
    let lv_data = event.data
    console.log('serviceName::::' + serviceName + ':::' + JSON.stringify(lv_data))
    switch (serviceName) {
      case 'S0': // NAS 영역에 PDF 파일이 생성 체크
        if (lv_data) {
          if (lv_data.trfoCmpltYn === 'Y') {
            if (lv_data.trfoErrcdCntnt === '0000') {
              this.fn_DownloadPlugin()
            } else { // 0000(정상)이 아닌 경우 에러내용 팝업!!
              this.getStore('confirm').dispatch('ADD', lv_data.trfoErrcdCntnt)
            } // end else if
          } else { // PDF 다운로드 확인 로직
            this.fn_AlertMsg('download', '전자서명 양식 생성을 위한 서류변환작업이 완료되지 않았습니다.\n다시 변환요청을 하시겠습니까?\n\n다시 변환요청을 해도 완료되지 않는 경우, 태블릿을 재부팅하고 다시 전자서명을 진행하시기 바랍니다.', '아니오|예') // 문구변경.ASR210401173 (2021.05.06 PJO)
          } // end else if
        } // end if
        break
      case 'U1': // 전자서명앱최초입력날자수정 (발행일자)
        if (lv_data.trtRslt === 0) { // 정상처리 1, 오류 0
          this.fn_trace('error', '전자서명 최초진행일자 입력 실패')
        } else {
          this.fn_ServiceData('bef_ES') // 전자서명앱 호출전 전문 저장
        } // end else if
        break
      case 'bef_ES': // 전자서명앱 호출전 전문 저장
        if (lv_data.trtRslt === 0) { // 정상처리 1, 오류 0
          this.fn_trace('error', '전자서명앱 호출전 전문 저장 실패')
          this.fn_AlertMsg('bef_ES', '전자서명앱 호출 처리중 오류가 발생 했습니다.\n다시 시도하시겠습니까?', '아니오|예')
        } else {
          this.fn_trace('info', '전자서명앱 호출전 전문 저장 성공')
          this.fn_PdfViewerPlugin2() // 전자서명 앱 호출
        } // end else if
        break
      case 'aft_ES': // 전자서명앱 호출후 전문 저장
        if (lv_data.trtRslt === 0) { // 정상처리 1, 오류 0
          this.fn_trace('error', '전자서명앱 호출후 전문 저장 실패')
          this.fn_AlertMsg('aft_ES', '전자서명앱 종료 처리중 오류가 발생 했습니다.\n다시 시도하시겠습니까?', '아니오|예')
        } else {
          this.fn_trace('info', '전자서명앱 호출후 전문 저장 성공')
          await this.fn_AftPdfViewerPlugin() // 전자서명 앱 호출후 처리
        } // end else if
        break
      case 'U4': // 계/피/친권자 전자서명 수정
        if (lv_data.trtRslt === 0) { // 정상처리 1, 오류 0
          this.fn_trace('error', '전자서명 서명 완료 여부 입력 실패')
        } // end if
        let signature = this.reSignerData.signature // 전자서명 완료 여부
        if (signature) {
          // 전자서명 모두 완료시 전자서명 설계 및 청약/접수 상세 정보 입력
          // [생체인증추가]
          // 지문촬영(isDcmWcnstOmit === 'F')
          // 계약서해시값 존재 (this.reSignerData.fileHash)
          // 지점접수요청하지 않은경우 (!this.lv_FofAcpnYn)
          let sElstMessage = 'isDcmWcnstOmit=' + this.reSignerData.isDcmWcnstOmit +
                      ', fileHash =' + this.reSignerData.fileHash + ', lv_FofAcpnYn=' + this.lv_FofAcpnYn
         
         this.fn_trace('info', '부인방지여부확인', sElstMessage)
          if ( (this.reSignerData.isDcmWcnstOmit === 'F') && (this.reSignerData.fileHash) && (!this.lv_FofAcpnYn )) {
            this.fn_trace('info', '전자서명완료 --->> 부인방치 처리 시작')
            this.fn_ProcBioSign() // 전자서명 분할방지처리
          } else {
            this.fn_ServiceData('I8') // 전자서명 설계 및 청약/접수 상세 정보 입력
          }
        } else {
          let signerComplete = this.isSignerComplete.filter(item => item.complete)
          let msgComplete = ''
          
          signerComplete.forEach(item => {
            if (item.complete === true) {
              if (msgComplete === '') {
                msgComplete = item.name
              } else {
                msgComplete += '/' + item.name
              }
            }
          }) //forEach

          if (msgComplete !== '') {
            msgComplete += ' 서명이 완료되었습니다.'
          } else {
            msgComplete = '전자서명이 중단되었습니다.'
          } // end else if
          
          this.fn_AlertMsg('signerComplete', msgComplete, '확인')

        } // end else if
        break
      case 'I8': // 전자서명 설계 및 청약/접수 상세 정보 입력
        this.fn_trace('info', 'I8 결과 입니다.', lv_data)
        if (lv_data.trtRslt === 0) { // 정상처리 1, 오류 0 lv_data.trtRslt = undefined
          this.fn_trace('info', '전자서명 앱 입력정보 신규는 실패!')
        } else {
          // 파일 업로드 진행 시작
          this.fn_PdfFileCopy()
        } // end if
        break
      case 'P2': // 전자서명완료
        console.log('completeData:::::lv_data' + JSON.stringify(lv_data))
        this.doubleUploadFlag_DC = false
        this.completeData = Object.assign({}, lv_data)
        if (this.completeData !== null && this.completeData.wkRslt === 0) {
          console.log('전자서명완료단계기본 저장 완료')
          let tempMsgDesc
          if (this.completeData.wkRslt2 === 97) {
            tempMsgDesc = '약관이 첨부되지 않았습니다.'
          } else if (this.completeData.wkRslt2 === 98) {
            tempMsgDesc = '변액 운용 설명서가 첨부되지 않았습니다.'
          } else if (this.completeData.wkRslt2 === 99) {
            tempMsgDesc = '약관과 변액 운용 설명서가  첨부되지 않았습니다.'
          } else {
            tempMsgDesc = "정상적으로 처리되었습니다. \n'확인'버튼을 눌러 주시기 바랍니다. "
          } // end else if
          // 메세지 창 오픈
          this.fn_trace('info', '전자서명완료 ::' + this.completeData.wkRslt + '||' + this.completeData.wkRslt2)
          // 확인 눌르면 fn_PDFSignComplete() 로 이동
          this.fn_AlertMsg('complete', tempMsgDesc, '확인')

          //전자서명 완료시 사용자 이벤트 로그 기록 처리 20:서명완료 업로드까지 완료
          let logVuchId = (this.itemData && this.itemData.baseData && this.itemData.baseData.eltrnDoctId)? this.itemData.baseData.eltrnDoctId : ' '
          PSCommUtil.prcsEvtLog(this,'MSPPS610M','서명완료',logVuchId)

        } else {
          this.fn_trace('error', '전자완료 실패 ::' + this.completeData.wkRslt + '||' + this.completeData.wkRslt2)
          this.fn_AlertMsg('upload', '파일 처리 중 에러가 발생했습니다.\n다시 시도하시겠습니까?', '아니오|예')
        } // end else if
        break
      case 'U9': // 청약일자 입력 및 수정
        if (lv_data.trtRslt === 0) { // 정상처리 1, 오류 0
          this.fn_trace('error', '청약일자 입력 및 수정 실패')
        } else {
          this.fn_ServiceData('U7') // 현장접수가능여부
        } // end if
        break
      case 'U7': // 현장접수가능여부
        if (lv_data.trtRslt === 0) { // 정상처리 1, 오류 0
          this.fn_trace('error', '현장접수가능여부 입력 실패')
        } else {
          this.fn_ServiceData('U8') // 전자서명 설계 및 청약/접수 상세 정보 입력
        } // end if
        break
      case 'U8': // 전자서명앱 입력 데이터 수정
        if (lv_data.trtRslt === 0) { // 정상처리 1, 오류 0
          this.fn_trace('error', '전자서명 앱 입력 상세정보 수정 실패')
        } else {
          this.fn_trace('info', '!!!!!!!!!!전자서명 수정 완료!!(U) => 가자 접수 !!!!!!!!!!')
          this.fn_ServiceData('S5') // 전자서명 단계별 처리 조회
        } // end if
        break
      case 'S5': // 전자서명 단계별 처리 조회
        this.fn_trace('info', '전자서명 단계별 처리 조회')
        let eltrnSignCmpltPhseTrtVO = Object.assign({}, lv_data)
        let eltrnSignPhseCd01 = eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd01.trim() // 전자문서보관
        let eltrnSignPhseCd02 = eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd02.trim() // 신계약정보전송
        let eltrnSignPhseCd03 = eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd03.trim() // 주임메일
        let eltrnSignPhseCd04 = eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd04.trim() // 서류이메일
        let eltrnSignPhseCd05 = eltrnSignCmpltPhseTrtVO.eltrnSignPhseCd05.trim() // 메세지전송
        /** 하나라도 N 이라면 MOSE_PIPE70900P로 연결하는 버튼 활성화 시키기 : VO를 연결하기 */
        this.itemData.signerData['elst2PhseCmpltYn'] = 'N' // 전자서명완료2단계완료여부
        this.itemData.signerData['elst5PhseCmpltYn'] = 'N' // 전자서명완료5단계완료여부
        if (!eltrnSignPhseCd01 || eltrnSignPhseCd01 === 'N') {
          // 1단계의 경우는 worm에 전자서명을 저장하지 못 하였기 때문에 재처리가 불가능하다.
          // 전자서명 중단건
          this.fn_trace('error', '전자서명 단계별 처리 조회 오류')
          if (this.itemData.signerData['elstCnclnYmd'] === '99999999') {            
            this.fn_UploadTimeOut() // 업로드 진행시 일자 체크 로직 사용
          } else {
            this.fn_ServiceData('U6_end') // 전자서명체결 실패
          } // end else if
        } else {
          if (!eltrnSignPhseCd02 || eltrnSignPhseCd02 === 'N') {
            // 현장 접수 불가
            this.itemData.signerData['elst2PhseCmpltYn'] = 'N' // 전자서명완료2단계완료여부
            this.itemData.signerData['elst5PhseCmpltYn'] = 'N' // 전자서명완료5단계완료여부
          } else if (!eltrnSignPhseCd03 || eltrnSignPhseCd03 === 'N') {
            // 현장 접수 가능 2단계 업데이트
            this.itemData.signerData['elst2PhseCmpltYn'] = 'Y' // 전자서명완료2단계완료여부
            this.itemData.signerData['elst5PhseCmpltYn'] = 'N' // 전자서명완료5단계완료여부
          } else if (!eltrnSignPhseCd04 || eltrnSignPhseCd04 === 'N') {
            // 현장 접수 가능 2단계 업데이트
            this.itemData.signerData['elst2PhseCmpltYn'] = 'Y' // 전자서명완료2단계완료여부
            this.itemData.signerData['elst5PhseCmpltYn'] = 'N' // 전자서명완료5단계완료여부
          } else if (!eltrnSignPhseCd05 || eltrnSignPhseCd05 === 'N') {
            // 현장 접수 가능 2단계 업데이트
            this.itemData.signerData['elst2PhseCmpltYn'] = 'Y' // 전자서명완료2단계완료여부
            this.itemData.signerData['elst5PhseCmpltYn'] = 'N' // 전자서명완료5단계완료여부
          } else {
            // 현장 접수 가능 2단계 업데이트
            // 현장 접수 가능 5단계 업데이트
            this.itemData.signerData['elst2PhseCmpltYn'] = 'Y' // 전자서명완료2단계완료여부
            this.itemData.signerData['elst5PhseCmpltYn'] = 'Y' // 전자서명완료5단계완료여부
          } // end else if
          this.fn_PdfFileDelCachFile() // 전자서명 업로드 완료이기 때문에 클라이언트 파일 삭제 처리
          if (this.itemData.signerData['elst2PhseCmpltYn'] === 'N') {
            this.rowPopupData = {
              mnPrdtNm: this.planNm, // 상품명
              mnContrNm: this.itemData.eltrnSaveData['mnContrNm'], // 계약자명
              elstCnclnYmd: this.itemData.eltrnSaveData['elstPblYmd'], // 청약일
              vuchId: this.itemData['vuchId'], // 영수증번호(문서ID)
              elst2PhseCmpltYn: 'N', // 전자서명완료2단계완료여부
              elst5PhseCmpltYn: 'N' // 전자서명완료5단계완료여부
            }
            
            this.fn_PrgPhseOpenPopup() //전자서명완료 재처리 팝업

          } else {
            this.fn_trace('info', '접수 가능 시작')
            this.fn_ServiceData('U6') // 전자서명 2,5단계 수정
          } // end else if
        } // end else if
        break
      case 'U6': // 전자서명 2,5단계 수정
        if (lv_data.trtRslt === 0) { // 정상처리 1, 오류 0
          this.fn_trace('error', '전자서명 2,5단계 입력 실패')
        } else {
          if (this.itemData.signerData['scnAcpnPsbYn'] === 'N') {
            this.fn_ComponentChange('5') // 전자서명완료 화면으로 변경
          } else {
            this.fn_ComponentChange('1') // 현장 접수 화면으로 변경
          } // end else if
        } // end if
        break
      case 'U6_end': // 전자서명 완료 실패
        this.fn_AlertMsg('eltrnSignPhse', '전자서명 전송이 실패하였습니다.', '확인')
        if (lv_data.trtRslt === 0) { // 정상처리 1, 오류 0
          this.fn_trace('error', '전자서명 완료 실패 업데이트')
        } else {
          this.fn_MovePage('5') // 전자서명 목록으로 이동 this.fn_MovePageTSSPS210M('5')
        } // end else if
        break
      case 'insLms': // 모바일완전판매 LMS 처리
        this.fn_trace('info', '전자약관 LMS 전송 완료')
        this.fn_AlertLmsComplete(Object.assign({}, lv_data))
        break
      case 'H': // 191112. 계약일,접수일자 alert 표시

        // 1.계약일자조회
        // 191104 : 계약일자 신규 추가
        //        : DCEltrnSignCmpltTltCtlImpl.eltrnSignCmplt / DCDocuMngVO.contYmd : String 8자리 ( YYYYMMDD )
        //        : 실시간이체   : 출금동의일자 -> 계약일
        //                      : 출금동의일자 : TSSPS141D.vue : 출금계좌제공선택후 [다음]
        //        : 가상계좌입금 : 전자서명완료일시(당일) -> 계약일
        let contYmd = this.currDt // PSDateUtil.fn_CurrentDate() // 현재일자변수화(20221220_PJO)
        if (this.itemData.eltrnSaveData.frtmPrmPadMthCd === '1' || this.itemData.eltrnSaveData.frtmPrmPadMthCd === '9') { // 9 신용카드 (ASR240400333_24.05 장한나)
          contYmd = this.itemData.eltrnSaveData.dfraCnsntYmd
        }
        if (this.serverChoice !== 'production') { // 개.검인경우 테스트영업일사용
          contYmd = this.currDt
          console.log('contYmd_reset:' + contYmd)
        }
        // 2. 영업일 조회
        let holyDayChkDateArry
        if (lv_data !== null && lv_data.icymdMngVO.length > 0) {
          holyDayChkDateArry = lv_data.icymdMngVO
        }
        // 2.1 +1영업일 조회
        let i = 0
        let holyDayLen = holyDayChkDateArry.length
        let nxtSalesYmd = this.currDt // PSDateUtil.fn_CurrentDate() // 현재일자변수화(20221220_PJO)
        for ( i; i < holyDayLen; i++ ) {
          if ( holyDayChkDateArry[i].holyCd === '00') {
            nxtSalesYmd = holyDayChkDateArry[i].slrcYmYmd
            break
          }
        }
        // 191209 : 장유휘 프로
        //        : 회계년도마감으로 청약접수일자 표시 팝업 고정
        // 계약일자가 12월, 청약접수 가능일자가 이듬해 1월일 경우, 청약접수가능일을 해당년의 마지막날(12월 31일)로 고정
        // EX ) 본 전자서명의 계약일자는 2019년 12월 31일입니다. 2019년 12월 31일 23:00까지만 청약접수가 가능합니다
        //
        // ASR211200170_회계년도 마감 관련 시스템 변경사항_가입설계(도유림 / 20211221_PJO)
        // 1) 계약일과 다음영업일의 연도가 다르면
        // 본 전자서명의 계약일자는 2021년 12월 31일입니다.
        // 회계연도 마감 등 대내외 정책 변경에 따라 2021년 12월 31일 23:00까지 청약 접수/출금 가능합니다.
        // (단, 가상계좌의 경우 2021년 12월 31일 20:00까지 청약 접수/출금 가능합니다.)
        // 2) 그외
        // 본 전자서명의 계약일자는 2021년 12월 31일 입니다.
        // 2021년 12월 31일 23:00까지만 청약접수가 가능합니다.
        // // 임시_테스트용삭제할것
        // contYmd = '20211231'
        // nxtSalesYmd = '20220103'
        let msgComplete = ''
        /* ASR221200180_회계년도변경시 별도 로직제거 ( 이현수 / 221220_PJO)
        if ( contYmd.substr(0, 4) !== nxtSalesYmd.substr(0, 4)) {
          nxtSalesYmd = contYmd.substr(0, 4) + '1231'
          msgComplete = '본 전자서명의 계약일자는 ' + contYmd.substr(0, 4) + '년 ' + contYmd.substr(4, 2) + '월 ' + contYmd.substr(6, 2) + '일 입니다.\n\n'
          msgComplete += '회계연도 마감 등 대내외 정책 변경에 따라\n' + nxtSalesYmd.substr(0, 4) + '년 ' + nxtSalesYmd.substr(4, 2) + '월 ' + nxtSalesYmd.substr(6, 2) + '일 23:00까지 청약 접수/출금 가능합니다.\n\n'
          msgComplete += '(단, 가상계좌의 경우\n' + nxtSalesYmd.substr(0, 4) + '년 ' + nxtSalesYmd.substr(4, 2) + '월 ' + nxtSalesYmd.substr(6, 2) + '일 20:00까지 청약 접수/출금 가능합니다.)'
        } else {
          msgComplete = '본 전자서명의 계약일자는 ' + contYmd.substr(0, 4) + '년 ' + contYmd.substr(4, 2) + '월 ' + contYmd.substr(6, 2) + '일 입니다.\n\n' + nxtSalesYmd.substr(0, 4) + '년 ' + nxtSalesYmd.substr(4, 2) + '월 ' + nxtSalesYmd.substr(6, 2) + '일 23:00까지만 청약 접수가 가능합니다.' // 20:00 ->23:00 ASR201200613_회계년도 마감_사랑온변경(20201222 PJO)
        } */
        msgComplete = '본 전자서명의 계약일자는 ' + contYmd.substr(0, 4) + '년 ' + contYmd.substr(4, 2) + '월 ' + contYmd.substr(6, 2) + '일 입니다.\n\n' + nxtSalesYmd.substr(0, 4) + '년 ' + nxtSalesYmd.substr(4, 2) + '월 ' + nxtSalesYmd.substr(6, 2) + '일 23:00까지만 청약 접수가 가능합니다.' // 20:00 ->23:00 ASR201200613_회계년도 마감_사랑온변경(20201222 PJO)
        // 3. alertMsg 표시
        this.fn_AlertMsg('contYmdCheck', msgComplete, '확인')
        break
      case 'S8': // 전자서명 서면동의생략여부 조회-재전송 대상건이 계피상이인 경우 호출

        if (lv_data.trtRslt === 0) { // 정상처리 1, 오류 0
          this.fn_trace('error', '전자서명 서면동의생략여부 조회 실패')
          this.fn_AlertMsg('S8', '전자서명 서면동의생략여부 확인 실패\n재전송이 불가합니다.', '확인')
        } else {
          try {
            this.fn_trace('info', '전자서명 서면동의생략여부 조회 성공')
            this.itemData.isDcmWcnstOmit = lv_data.jsonInputData
            this.fn_trace('info', 'isDcmWcnstOmit 서면동의생략여부 : ' + this.itemData.isDcmWcnstOmit)
            /*
            * isDcmWcnstOmit 서면동의생략여부
            * Y : 서면동의서 선택 후 촬영 스킵
            * N : 서면동의서 선택 후 촬영 성공
            * F : 지문 촬영 선택 시
            */
            if (this.itemData.isDcmWcnstOmit === 'F') {
              // 기존 계피상이 재전송 생체인증 프로세스 처리
              this.fn_CheckBioSignComplete() // 계피상이 일때문 분기처리 : 전자서명(부인방지) 미완료여부 확인
            } else {
              if (this.itemData.signerData['elstCnclnYmd'] === '99999999') { // 전자서명 1단계 오류 일떄
                PSScnAcpnUtil.fn_ScnAcpnService(this.itemData, this.fn_ScnAcpnChackServiceResultHandler) // 청약접수 가능 여부
              } else {
                // pdf 파일 유무 체크 후 업로드
                this.fn_GetPdfFileList()
              } // end else if
            }
          } catch (e) {
            this.fn_trace('error', '전자서명 서면동의생략여부 조회 중 오류 발생')
            this.fn_AlertMsg('S8', '전자서명 서면동의생략여부 조회 중 오류가 발생되었습니다.', '확인')
          }
        } // end else if
        break      
      case 'S9': // 전자서명 완료 전문 조회-재전송 대상건이 비정상종료건(TTSD00004에 DATA 없는건)인 경우 호출

        if (lv_data.trtRslt === 0) { // 정상처리 1, 오류 0
          this.fn_trace('error', '전자서명 완료 전문 조회 실패')
          this.fn_AlertMsg('S9', '전자서명 완료 전문 조회 실패\n재전송이 불가합니다.', '확인')
        } else {
          try {
            this.fn_trace('info', '전자서명 완료 전문 조회 성공')
            // 전자서명 전문 파싱
            this.reSignerData = PSDocFormUtil.getOzJsonData(lv_data.jsonInputData)
            this.fn_trace('info', 'isDcmWcnstOmit 서면동의생략여부 : ' + this.reSignerData.isDcmWcnstOmit)
            /*
            * isDcmWcnstOmit 서면동의생략여부
            * Y : 서면동의서 선택 후 촬영 스킵
            * N : 서면동의서 선택 후 촬영 성공
            * F : 지문 촬영 선택 시
            */
            if (this.reSignerData.isDcmWcnstOmit === 'F') {
              // 기존 계피상이 재전송 생체인증 프로세스 처리
              this.fn_CheckBioSignComplete() // 계피상이 일때문 분기처리 : 전자서명(부인방지) 미완료여부 확인
            } else {
              this.fn_ServiceData('I8') // 전자서명 설계 및 청약/접수 상세 정보 입력
            }
          } catch (e) {
            this.fn_trace('error', '전자서명 완료 전문 조회 중 오류 발생')
            this.fn_AlertMsg('S9', '전자서명 완료 전문 조회 중 오류가 발생되었습니다.\n재전송이 불가합니다.', '확인')
          }
        } // end else if
        break
      default:
        break
    }
    this.isLoadingStatus = false
    this.isLoading = false
  },

  /************************************************************************************************
   * Function명  : fn_ServiceFaultHandler
   * 설명        : 에러 이벤트
   ************************************************************************************************/
  fn_ServiceFaultHandler (event, serviceName) {
    let ERROR_NAME = ''
    switch (serviceName) {
      case 'S0': // NAS 영역에 PDF 파일이 생성 체크
        ERROR_NAME = 'PDF 파일생성'
        break
      case 'U1': // 전자서명앱최초입력날자수정 (발행일자)
        ERROR_NAME = '전자서명 앱 최초 실행 일자'
        break
      case 'bef_ES': // 전자서명 호출 전 전문 저장
        ERROR_NAME = '전자서명 호출 전 전문 저장'
        break
      case 'aft_ES': // 전자서명 호출 후 전문 저장
        ERROR_NAME = '전자서명 호출 후 전문 저장'
        break
      case 'U4': // 계/피/친전자서명 수정
        ERROR_NAME = '전자서명 결과 상태 등록'
        break
      case 'I8': // 전자서명 설계 및 청약/접수 상세 정보 입력
        ERROR_NAME = '전자서명 작성 Data등록'
        break
      case 'P2': // 전자서명완료
        this.doubleUploadFlag_DC = false
        ERROR_NAME = '전자서명 완료'
        break
      case 'U9': // 청약일자 입력 및 수정
        ERROR_NAME = '청약일자 입력 및 수정'
        break
      case 'U7': // 현장접수가능여부
        ERROR_NAME = '현장접수 가능 여부'
        break
      case 'U8': // 전자서명앱 입력 데이터 수정
        ERROR_NAME = '현자접수 가능 여부 상세'
        break
      case 'S5': // 전자서명 단계별 처리 조회
        ERROR_NAME = '전자서명 처리 단계 조회'
        break
      case 'U6': // 전자서명 2,5단계 수정
        ERROR_NAME = '전자서명 처리 단계 수정'
        break
      case 'U6_end': // 전자서명 완료 실패
        ERROR_NAME = '전자서명 완료 처리'
        break
      case 'insLms': // 모바일완전판매 LMS 처리
        ERROR_NAME = '모바일완전판매 LMS 처리'
        break
      case 'S8': // 전자서명 서면동의생략여부 조회
        ERROR_NAME = '전자서명 서면동의생략여부 조회'
        break
      case 'S9': // 전자서명 완료 전문 조회
        ERROR_NAME = '전자서명 완료 전문 조회'
        break
      case 'NUNDW': // 선심사정보조회
        ERROR_NAME = '선심사정보조회'
        break
      default:
        break
    } //switch

    this.fn_ShowLoading(false, ' ') // 화면 로딩 처리
    this.fn_trace('error', ERROR_NAME + '::' + event.msgDesc)
    this.getStore('confirm').dispatch('ADD', event.msgDesc)
  },

  /*-----------------------------------------------------------------------------------------------
  * 전자서명 / PDF 처리 관련 영역
  ------------------------------------------------------------------------------------------------*/
  /************************************************************************************************
  * Function명  : fn_GetPdfFileList
  * 설명        : 태블릿내 PDF파일리스트 조회
  ************************************************************************************************/
  fn_GetPdfFileList () {
    this.fn_trace('info', '태블릿내 PDF파일리스트 조회 시작')
    let lv_vm = this
    let t_Param = {path: this.LOCAL_PATH_PDF_UPLOAD}
    let fileName = this.itemData.vuchId + '.pdf'
    this.fn_ShowLoading(true, '2') // 화면 로딩 처리 // 화면 로딩

    window.fdpbridge.exec('getFileListPlugin', t_Param, (result) => {
    // 조회 결과
      lv_vm.fn_trace('info', '태블릿내 PDF파일리스트 조회 완료')
      let splitList = result.data.split('|')
      if (splitList && splitList.length > 0) {
        lv_vm.fn_trace('info', '태블릿내 PDF파일리스트 totCnt => ' + splitList.length)
        splitList.forEach((el, i) => {
          lv_vm.fn_trace('info', '태블릿내 PDF파일리스트 ' + i + ' : ' + el)
        })
      }

      if (result.data && result.data.indexOf(fileName) > -1 ) {
        lv_vm.fn_trace('info', '태블릿내 로컬영역에 PDF파일 존재-> 캐쉬로 복사 시작')
        lv_vm.fn_PdfFileCopy()
      } else {
        lv_vm.fn_trace('info', '태블릿내 로컬영역에 PDF파일 미존재->캐쉬내 PDF 파일 내역 조회 시작')
        lv_vm.fn_GetCachePdfFileList()
      }
    }, () => {
      lv_vm.fn_trace('error', '태블릿내 PDF파일리스트 조회 오류 발생')
    })
  },

  /******************************************************************************
   * Function명 : fn_GetCachePdfFileList
   * 설명 : 앱 내 캐쉬 영역 pdf 폴더 파일리스트 조회
   ******************************************************************************/
  fn_GetCachePdfFileList () {
    this.fn_trace('info', '캐쉬 영역 PDF파일리스트 조회 시작')
    let lv_vm = this
    let fileName = this.itemData.vuchId + '.pdf'
    this.fn_ShowLoading(true, '2') // 화면 로딩 처리 // 화면 로딩

    window.fdpbridge.exec('getPSFileListPlugin', {}, (result) => {
      // 조회 결과
      lv_vm.fn_trace('info', '캐쉬 PDF파일리스트 조회 완료')
      let splitList = result.data.split('|')
      if (splitList && splitList.length > 0) {
        lv_vm.fn_trace('info', '캐쉬 PDF파일리스트 totCnt => ' + splitList.length)
        splitList.forEach((el, i) => {
          lv_vm.fn_trace('info', '캐쉬 PDF파일리스트 ' + i + ' : ' + el)
        })
      }

      if (result.data && result.data.indexOf(fileName) > -1 ) {
        lv_vm.fn_trace('info', '캐쉬 PDF파일 존재-> 업로드 시작')
        lv_vm.fn_UploadTimeOut()
      } else {
        lv_vm.fn_trace('error', '캐쉬 PDF파일 미존재')
        lv_vm.getStore('confirm').dispatch('ADD', '전자서명 PDF가 없습니다.(cache)')
      }
    }, (error) => {
      let errorMsg = (error)? error : ''
      lv_vm.fn_trace('error', '캐쉬 PDF파일리스트 조회 오류 발생 errorMsg='+errorMsg)      
    })
  },

  /**
  * 영업일 컨피그값으로 세팅
  * (개발,검증 테스트용)
  * (20221220_PJO)
  */
  serviceCallTest () {
    var lv_Vm = this
    let btnId = 'S'
    let pParams = {}
    this.currDt = PSDateUtil.fn_CurrentDate()
    console.log('serviceCallTest_currDt :' + this.currDt )
    pParams = {envId: 'ELST_TEST_SRCDT'} // 전자서명_테스트_영업일
    this.post(lv_Vm, pParams, 'txTSSAD02S3', btnId)
      .then(function (response) {
        if (response.body !== null) {
          let vList = response.body.returnDtlList
          if ( vList.length > 0 ) {
            for (const idx in vList) {
              const item = vList[idx]
              let o = {}
              o = Object.assign({}, item)
              o.label = item.smoUnitl // ID
              if (o.label === 'SRCDT' ) {
                lv_Vm.test_srcdt = item.envCntnt // 값
                console.log('■■■ lv_Vm.test_srcdt:' + lv_Vm.test_srcdt)

                lv_Vm.currDt = lv_Vm.test_srcdt
                // lv_Vm.fn_ServiceData('H')
              }
            } // for~
          } else {
            console.log('list 값 없읍!')
          }
          console.log('serviceCallTest.currDt_reset :' + this.currDt )
          // lv_Vm.fn_ServiceData('H')
        }
      })
      .catch(function (error) {
        console.log('■■■ serviceCallTest_error.......')
        window.vue.error(error)
      })
  },
  /************************************************************************************************
   * Function명  : fn_PSElstDtlInfoVOSetting
   * 설명        : 전자서명 입력 Data 저장
   ************************************************************************************************/
  fn_PSElstDtlInfoVOSetting: function () {
    let inputData = Object.assign({}, ElstDtlInfoVO())
    try {
      inputData.vuchId = this.itemData.baseData.eltrnDoctId // 영수증ID

      // 접수 세부입력 화면 여부(체크팝업 기본 Data)
      let idcTofCnfObjYn = this.itemData.gafData.planInfoData[0].idcTofCnfObjYn // 신분증진위확인대상여부
      inputData.idcTofCnfObjYn = this.itemData.eltrnSaveData.idcTofCnfObjYn = idcTofCnfObjYn
      // 친권자안내대상여부
      if (this.itemData.signerData.mnisdMiorYn === 'Y' || this.itemData.signerData.mnisdMiorYn === 'X') { // 미성년자 여부 (조회는 'Y' 입력/수정 'X')
        inputData.prtrPesonGudnObjYn = this.itemData.eltrnSaveData.prtrPesonGudnObjYn = 'Y'
      } else {
        inputData.prtrPesonGudnObjYn = this.itemData.eltrnSaveData.prtrPesonGudnObjYn = 'N'
      } // end else if
      // 수익자상이여부
      if (this.itemData.eltrnSaveData.bnfrChgCntnt === ' ') {
        inputData.bnfrDfrncYn = this.itemData.eltrnSaveData.bnfrDfrncYn = 'N' // 수익자상이여부
      } else {
        inputData.bnfrDfrncYn = this.itemData.eltrnSaveData.bnfrDfrncYn = 'Y'// 수익자상이여부
      } // end else if

      // 전자 서명 입력 Data화 시작
      // inputData.cmmuRscCnsntYn = this.itemData.eltrnSaveData.cmmuRscCnsntYn = this.reSignerData.xmlData.cmmuRscCnsntYn // 통신해지가능여부
      inputData.cmmuRscCnsntYn = this.itemData.eltrnSaveData.cmmuRscCnsntYn = '1' // 통신해지 동의 데이터는 전건 동의로 전송(2022.02 요청)
      inputData.contDsclsYn = this.itemData.eltrnSaveData.contDsclsYn = this.reSignerData.xmlData.contDsclsYn // 계약고지여부 (피)
      inputData.bnfrAsgnCnsntYn = this.itemData.eltrnSaveData.bnfrAsgnCnsntYn = this.reSignerData.xmlData.bnfrAsgnCnsntYn // 수익자지정동의여부
      inputData.asgnPrsonIapAplYn = this.itemData.eltrnSaveData.asgnPrsonIapAplYn = this.reSignerData.xmlData.asgnPrsonIapAplYn // 지정인알림신청여부(2022.03 ASR220300097 김문준프로요청)
      inputData.ralOwnerYn = this.itemData.eltrnSaveData.ralOwnerYn = this.reSignerData.xmlData.ralOwnerYn // 실제소유자여부
      inputData.insrdHght = this.itemData.eltrnSaveData.insrdHght = parseInt(this.reSignerData.xmlData.insrdHght) // 피보험자신장
      inputData.insrdBwght = this.itemData.eltrnSaveData.insrdBwght = parseInt(this.reSignerData.xmlData.insrdBwght) // 피보험자체중
      inputData.insrdSmkYn = this.itemData.eltrnSaveData.insrdSmkYn = this.reSignerData.xmlData.insrdSmkYn // 피보험자흡연여부
      inputData.aisdHght = this.itemData.eltrnSaveData.aisdHght = parseInt(this.reSignerData.xmlData.aisdHght) // 종피보험자신장
      inputData.aisdBwght = this.itemData.eltrnSaveData.aisdBwght = parseInt(this.reSignerData.xmlData.aisdBwght) // 종피버험자체중
      inputData.aisdSmkYn = this.itemData.eltrnSaveData.aisdSmkYn = this.reSignerData.xmlData.aisdSmkYn // 종피보험자흡연여부
      inputData.prgwkFgr = this.itemData.eltrnSaveData.prgwkFgr = parseInt(this.reSignerData.xmlData.prgwkFgr) // 임신주수
      inputData.asgnProxyClamEntYn = this.itemData.eltrnSaveData.asgnProxyClamEntYn = this.itemData.eltrnSaveData.apcAsgnYn // 지정대리청구가입여부
      inputData.rprtRcpplScCd = this.itemData.eltrnSaveData.rprtRcpplScCd = this.reSignerData.xmlData.rprtRcpplScCd // 변액보고서수령방법코드
      inputData.snbkGudnSmsReciYn = this.itemData.eltrnSaveData.snbkGudnSmsReciYn = this.reSignerData.xmlData.snbkGudnSmsReciYn// 190605 출금동의서 반송안내 SMS 수신여부- 장유휘프로 요청
      inputData.txbkScCd = this.itemData.eltrnSaveData.txbkScCd = this.reSignerData.xmlData.txbkScCd// 세금우대구분코드
      // 현장접수 가능 여부 에서 활용
      inputData.crinfCnsntYn = this.itemData.eltrnSaveData.crinfCnsntYn = this.reSignerData.xmlData.pCrdinPrcusCnsnt // 개인신용정보활용동의여부
      inputData.nobMultpYn = this.itemData.eltrnSaveData.nobMultpYn = this.reSignerData.xmlData.duCnt > 1 ? 'Y' : 'N' // 알릴의무사항 2개 이상 시 접수 불가 처리
      // 2019.02.28 부터 추가되는 알림의무사항
      inputData.cylClbyDignYn = this.itemData.eltrnSaveData.cylClbyDignYn = this.reSignerData.xmlData.cylClbyDignYn // 주기별진단여부 (3/2/5체크)
      inputData.diabtNobWrtYn = this.itemData.eltrnSaveData.diabtNobWrtYn = this.reSignerData.xmlData.diabtNobWrtYn // 당뇨특약알릴의무작성여부
      inputData.diabtDignYn = this.itemData.eltrnSaveData.diabtDignYn = this.reSignerData.xmlData.diabtDignYn // 당뇨진단여부

      // 191010 / isDcmWcnstOmit 서면동의생략
      this.itemData.isDcmWcnstOmit = this.reSignerData.isDcmWcnstOmit // 서면동의생략
      // 보험거래문서디지털화: 고지입력정보내용 추가
      console.log('fn_PSElstDtlInfoVOSetting>>>pvmnotice::::' + JSON.stringify(this.reSignerData))
      inputData.dsclsMatrTlgmCntnt = this.itemData.eltrnSaveData.dsclsMatrTlgmCntnt = JSON.stringify(this.reSignerData.xmlData['pvmnotice'])
      inputData.elstPlanId = this.itemData.planId
      // 전자 서명 입력 Data화 종료
    } catch (error) {
      this.fn_trace('error', '전자서명 입력 Data :fn_PSElstDtlInfoVOSetting 오류', inputData)
      return null
    } // end try..catch
    return inputData
  },
  /************************************************************************************************
   * Function명  : fn_PdfFileCopy
   * 설명        : 전자서명 캐쉬 영역으로 복제
   ************************************************************************************************/
  fn_PdfFileCopy () {
    let lv_vm = this
    this.fn_ShowLoading(true, '2') // 화면 로딩 처리
    let t_Param = {path: this.STORAGE_ROOT_PATH + this.LOCAL_PATH_PDF_UPLOAD, filename: this.itemData.baseData.eltrnDoctId + '.pdf'}
    lv_vm.fn_trace('info', '전자서명 PDF 업로드 사전 준비 시작합니다.' + JSON.stringify(t_Param))
    window.fdpbridge.exec('PSFileCopyPlugin', t_Param, (result) => {
      try {
        lv_vm.fn_trace('info', 'PSFileCopyPlugin.result=>' + JSON.stringify(result))
        if (result.data === 'RESULT_OK') {
          lv_vm.fn_trace('info', '전자서명 PDF 업로드 사전 준비 완료했습니다.')
          // 복제 완료시 업로드 실행
          lv_vm.fn_AlertMsg('upload', '변환된 전자문서 전송이 필요합니다.\n반드시 [확인]버튼을 누른 후\n전송이 완료될 때까지 기다려 주십시오.', '확인')
        } else {
          lv_vm.getStore('confirm').dispatch('ADD', '전자서명 PDF가 없습니다.')
          lv_vm.fn_trace('error', '전자서명 PDF가 없습니다. result.data =>' + result.data)
        } // end else if
      } catch (error) {
        lv_vm.fn_trace('error', 'catch 전자서명 PDF 복사 중 오류 발생 ')
      } // end try..catch
    }, (error) => {
      let errorMsg = (error)? error : ''
      lv_vm.fn_trace('error', '전자서명 PDF 복사 중 오류 발생 errorMsg='+errorMsg)
    })
  },

  /************************************************************************************************
   * Function명  : fn_PdfViewerPlugin2
   * 설명        : 전자서명 앱 로드   
   ************************************************************************************************/
  fn_PdfViewerPlugin2 () {
    this.fn_trace('info', '전자서명 앱 호출 시작')
    let jsonData = this.sendElstTlgm
    let lv_vm = this
    let ozParam = PdfConstants.OZ_CONTENTS_PARAM
    let ozParamSep = PdfConstants.OZ_PARAM_SEP
    let t_Param = {json: jsonData, ozParamData: ozParam, ozParamSepData: ozParamSep}
    console.log('★★★★★★★★OZ startEltrnSignPlugin')
    console.log(t_Param)

    this.fn_ShowLoading(false, ' ') // 화면 로딩 처리
    
    console.log("startEltrnSignPlugin 호출 - 전자서명 app 호출")
    window.fdpbridge.exec('startEltrnSignPlugin', t_Param, (result) => {

      // 전자서명 리턴 전문 저장
      lv_vm.receiveElstTlgm = result.data

      console.log('★★★★★★★★OZ callBack')
      console.log(lv_vm.receiveElstTlgm)

      lv_vm.fn_ServiceData('aft_ES')

    }, (e) => {

      /* 전자서명 앱 Exception 로그 기록*/
      try {
        let logVuchId = lv_vm.itemData.baseData['eltrnDoctId'] 
        let logTxt = (e)? JSON.stringify(e) : ' '

        lv_vm.fn_trace('error', '전자서명 앱 호출 오류 발생')
        if(logTxt.trim() != '') lv_vm.fn_trace('error', logTxt)

        let cutLogTxt = PSCommUtil.fn_CutByteLength(logTxt,145) 
        PSCommUtil.prcsEvtLog(lv_vm,'startEltrnSignPlugin',cutLogTxt,logVuchId)
      } catch (error) {
        console.log('startEltrnSignPlugin evtLog error!!',error) 
      }
      
      lv_vm.fn_AlertMsg('signerComplete', '전자서명 처리 중 비정상 종료되었습니다.', '확인')

    })
  },

  /************************************************************************************************
   * Function명  : fn_AftPdfViewerPlugin
   * 설명        : 전자서명 앱 종료 후 처리
   ************************************************************************************************/
  async fn_AftPdfViewerPlugin () {
    this.fn_trace('info', '전자서명 앱 리턴 데이타 처리 시작')
    let resultData = PSDocFormUtil.getOzJsonData(this.receiveElstTlgm)
    this.fn_ShowLoading(true, '2') // 화면 로딩 처리
    if (resultData.trtRslt === 0) {
      this.fn_trace('error', '전자서명 XML 파싱중 오류 발생', resultData)
      this.fn_AlertMsg('signerComplete', '전자서명 처리 중 오류가 발생되었습니다.', '확인')
    } else {
      await this.fn_PdfSignerDataSave(this, resultData)
    } // end else if
  },

  /************************************************************************************************
   * Function명  : fn_PdfSignerDataSave
   * 설명        : 전자서명 앱 사인 정보 정보 추출
   *               중단건 이어서 하기 고려 AML 서식 입력값 저장 시점 
   ************************************************************************************************/
  async fn_PdfSignerDataSave (lv_vm, resultData) {
    lv_vm.reSignerData = resultData // 전자서명 앱 결과 값 저장
    lv_vm.fn_trace('info', '전자서명 앱 완료되었습니다', lv_vm.reSignerData)
    let signature = lv_vm.reSignerData.signature
    let signerArray = lv_vm.reSignerData.signerInfo.signerDetail // 전자서명 사인 정보 확인
    lv_vm.isSignerComplete = []
    let isInsrdComplete = 'N' // 피보험자 전자서명여부
    if (signerArray && signerArray.length > 0 ) {
      
      signerArray.forEach(item => {
        let authorization = false
        let signerName = ''
        if (item.signerCode === '1') { // 계약자
          signerName = '계약자'
          authorization = lv_vm.itemData.signerData.contrAthntMethCd.trim() !== ''
          lv_vm.itemData.signerData['contrElstYn'] = item.complete ? 'Y' : 'N' // 계약자전자서명여부
        } else if (item.signerCode === '2') { // 피보험자
          signerName = '피보험자'
          authorization = lv_vm.itemData.signerData.insrdAthntMethCd.trim() !== ''
          isInsrdComplete = lv_vm.itemData.signerData['insrdElstYn'] = item.complete ? 'Y' : 'N' // 피보험자전자서명여부
        } else if (item.signerCode === '3') { // 종피보험자
          signerName = '종피보험자'
          authorization = lv_vm.itemData.signerData.contrAthntMethCd.trim() !== ''
          lv_vm.itemData.signerData['aisdElstYn'] = item.complete ? 'Y' : 'N' // 종피보험자전자서명여부
        } else if (item.signerCode === '4') { // 친권자
          signerName = '친권자'
          authorization = lv_vm.itemData.signerData.insrdAthntMethCd.trim() !== ''
          lv_vm.itemData.signerData['prtrPesonElstYn'] = item.complete ? 'Y' : 'N' // 친권자전자서명여부
        } else if (item.signerCode === '6') { // 예금주
          signerName = '예금주'
          authorization = lv_vm.itemData.signerData.dpstrAthntMethCd.trim() !== ''
          lv_vm.itemData.signerData['dpstrElstYn'] = item.complete ? 'Y' : 'N' // 예금주전자서명여부
        } else if (item.signerCode === '9') { // FC
          signerName = 'FC'
          if (lv_vm.itemData.signerData.contrAthntMethCd.trim() !== '' && lv_vm.itemData.signerData.insrdAthntMethCd.trim() !== '') {
            authorization = true
          } else {
            authorization = false
          } // end else if
          lv_vm.itemData.signerData['fcElstYn'] = item.complete ? 'Y' : 'N' // FC전자서명여부
        } // end else if
        // 사인완료 여부 임시 저장
        // 서명 중단건이지 완료인지 구분
        if (authorization && !item.complete) { // 서명 중단 건
          lv_vm.isSignerComplete.push({code: item.signerCode, name: signerName, complete: false})
        } else if (authorization && item.complete) { // 서명 완료 건
          lv_vm.isSignerComplete.push({code: item.signerCode, name: signerName, complete: true})
        } // end if
      }) // for each 종료

    } // end if

    if (signature) { // 전자서명 모두 완료시 업로드 처리
      lv_vm.fn_trace('info', '전자서명 작성이 완료되었습니다.')
      // 업로드 전 완성된 PDF를 캐쉬 영역으로 복제 처리
      lv_vm.itemData.signerData['elstPrgStatCd'] = '20' // 10:서명중/20:서명완료/30:접수완료/40:이체완료
    } else { // 계피상이이고 서명부분 완료시 처리
      lv_vm.fn_trace('info', '전자서명 작성이 미완료되었습니다.')
      lv_vm.itemData.signerData['elstPrgStatCd'] = '10' // 10:서명중/20:서명완료/30:접수완료/40:이체완료
    } // end else if


    // 전자서명 완료시 AML 서식 입력데이터 저장 처리
    let eltrnSaveData       = (lv_vm.itemData && lv_vm.itemData.eltrnSaveData)?      lv_vm.itemData.eltrnSaveData      : null
    let amlContrPartnNo     = (eltrnSaveData  && eltrnSaveData.amlContrPartnNo)?     eltrnSaveData.amlContrPartnNo     : ''
    let amlDthBnfrPartnNo   = (eltrnSaveData  && eltrnSaveData.amlDthBnfrPartnNo)?   eltrnSaveData.amlDthBnfrPartnNo   : ''
    let amlPrtrPesonPartnNo = (eltrnSaveData  && eltrnSaveData.amlPrtrPesonPartnNo)? eltrnSaveData.amlPrtrPesonPartnNo : ''
    // 서명완료 이고 AML 대상자 BPID 한개라도 있으면 서식 입력한 내용 테이블 적재처리
    if (signature && (amlContrPartnNo || amlDthBnfrPartnNo || amlPrtrPesonPartnNo)) {
      lv_vm.fn_trace('info', 'AML 서식 입력데이터 저장 처리.')
      let amlWrtDataList = (lv_vm.reSignerData.xmlData && lv_vm.reSignerData.xmlData.amlWrtDataList)? lv_vm.reSignerData.xmlData.amlWrtDataList : []
      let gafDataAmlKycRsltCd   = (lv_vm.itemData.gafData.amlKycRsltCd)? lv_vm.itemData.gafData.amlKycRsltCd : null
      let eltrnSaveData  = lv_vm.itemData.eltrnSaveData
      await PSAmlUtil.fn_SetSaveAmlDocWrt(lv_vm , eltrnSaveData ,gafDataAmlKycRsltCd, amlWrtDataList)
    }  

    /*
      피보험자 지문촬영.
     1. 계피상이건 : contrInsrdSameYn === N , isSameContractor === false
     2. 피보험자 지문촬영 동의 :  isDcmWcnstOmit == F
     3. 피보험자 서명이 완료 : isInsrdComplete === 'Y'
     if ( 1 && 2 && 3 ) {
      fn_CheckInsrdBioComplete() // 피보험자의 지문 등록건이 있는지 여부 확인
     } else {
      fn_ServiceData('U4') // 서명 정보 업데이트 이후 로직
     }
    */
    let sElstMessage = 'isSameContractor=' + lv_vm.reSignerData.signerInfo.isSameContractor +
                      ', isInsrdComplete =' + isInsrdComplete + ', isDcmWcnstOmit=' + lv_vm.reSignerData.isDcmWcnstOmit
    lv_vm.fn_trace('info', sElstMessage )
    if ( (lv_vm.reSignerData.signerInfo.isSameContractor === false) && (isInsrdComplete === 'Y') && (lv_vm.reSignerData.isDcmWcnstOmit === 'F') ) {
      lv_vm.fn_trace('info', '계피상이 ,지문촬영 선택(isDcmWcnstOmit=' + lv_vm.reSignerData.isDcmWcnstOmit + '), 피보험자서명완료 했습니다.')
      lv_vm.fn_CheckInsrdBioComplete() // 피보험자의 지문 등록건이 있는지 여부 확인
    } else {
      lv_vm.fn_ServiceData('U4') // 서명 정보 업데이트 이후 로직
    }
  },

  /************************************************************************************************
   * Function명  : fn_UploadTimeOut
   * 설명        : 전자서명 처리 중지 및 패기 처리
   *             전자서명 완료시간 확대 로 AS-IS 로직 제거 반영 
   ************************************************************************************************/
  fn_UploadTimeOut () {
    this.fn_UploadPlugin()    
  },
  
  /************************************************************************************************
   * Function명  : fn_UploadPlugin
   * 설명        : PDF 업로드
   ************************************************************************************************/
  fn_UploadPlugin () {
    // PDF 업로드 중복처리 방지
    if ( this.doubleUploadFlag_NAS || this.doubleUploadFlag_DC ) {      
      PSServiceManager.setDynaTraceLog('fn_UploadPlugin', 'PDF 업로드 중복:' + this.itemData.baseData.eltrnDoctId + ':NAS:' + this.doubleUploadFlag_NAS + ':DC:' + this.doubleUploadFlag_DC, 'MSPPS610M', this.itemData.baseData.eltrnDoctId) //180M
      return
    }
    let lv_vm = this
    let dateFolder = PSDateUtil.fn_CurrentDate()
    let t_Param = {
      path: this.PATH_PDF_UPLOAD, // 기본 path
      datedir: dateFolder, // 날자 폴더
      filename: this.itemData.baseData.eltrnDoctId + '.pdf', // 저장파일명
      localpath: '', // this.LOCAL_PATH_PDF_UPLOAD 로칼 파일 경로[필요는 없음]
      localfilename: this.itemData.baseData.eltrnDoctId + '.pdf', // 로칼 파일명
      timeout: '600000' // 기본 타임머 10분
    }
    this.fn_trace('info', '전자서명 PDF 업로드 시작합니다.', t_Param)
    this.fn_ShowLoading(true, '2') // 화면 로딩 처리 // 화면 로딩
    this.doubleUploadFlag_NAS = true
    let tmp = this.itemData.baseData.eltrnDoctId + '.pdf' // filename 다이나로그남기기 20201222 PJO
    if (this.isMobile) {

      window.fdpbridge.exec('uploadPlugin', t_Param, function (result) {
        console.log('uploadPlugin::result::::', JSON.stringify(result))
        lv_vm.doubleUploadFlag_NAS = false
        if (result.data === '200' || result.data === 'ok') {
          lv_vm.fn_trace('info', '전자서명 PDF 업로드 완료했습니다.')
          PSServiceManager.setDynaTraceLog('uploadPlugin', 'PDF 업로드 완료,' + tmp, 'MSPPS610M', lv_vm.itemData.baseData.eltrnDoctId) // 20201222 PJO
          lv_vm.fn_ServiceData('P2') // 전자서명 완료
        } else {
          lv_vm.fn_trace('error', '전자서명 PDF 업로드 실패 코드2', result.data)
          PSServiceManager.setDynaTraceLog('uploadPlugin', 'PDF 업로드 실패 코드2,' + tmp, 'MSPPS610M', lv_vm.itemData.baseData.eltrnDoctId) // 20201222 PJO
          lv_vm.fn_AlertMsg('upload', '파일 처리 중 에러가 발생했습니다.\n다시 시도하시겠습니까?', '아니오|예')
        } // end else if
      }, function (result) {
        lv_vm.doubleUploadFlag_NAS = false
        lv_vm.fn_trace('error', '전자서명 PDF 업로드 실패 코드3', result)
        PSServiceManager.setDynaTraceLog('uploadPlugin', 'PDF 업로드 실패 코드3,' + tmp, 'MSPPS610M', lv_vm.itemData.baseData.eltrnDoctId) // 20201222 PJO
        lv_vm.fn_AlertMsg('upload', '파일 처리 중 에러가 발생했습니다!\n다시 시도하시겠습니까?', '아니오|예')
      })

    } else { // 데스크탑 테스트 용도
    
      let t_Url = ''
      if (this.serverChoice === 'development' || this.serverChoice === 'local') {
        t_Url = 'http://wb1.t.tss.samsunglife.kr:8810/upload.do?' // 개발, 내부망
      } else if (this.serverChoice === 'stage' || this.serverChoice === 'stage2') {
        t_Url = 'https://itss.samsunglife.com:8410/upload.do?' // 검증, 외부망
      } else {
        t_Url = 'https://tss.samsunglife.com:8410/upload.do?' // 운영, 외부망
      } // end else if
      var rsl = 'javascript:' +
        'var to = "' + t_Url + '";' +
        "var myForm = document.createElement('form');" +
        "myFormid='fileUpload';" +
        "myForm.method='post';" +
        'myForm.action = to;' +
        "myForm.enctype='multipart/form-data';" +
        "var myInputPath = document.createElement('input');" +
        "myInputPath.setAttribute('type', 'hidden');" +
        "myInputPath.setAttribute('name', 'path');" +
        "myInputPath.setAttribute('value','" + t_Param.path + "');" +
        'myForm.appendChild(myInputPath);' +
        "var myInputDateDir = document.createElement('input');" +
        "myInputDateDir.setAttribute('type', 'hidden');" +
        "myInputDateDir.setAttribute('name', 'dir');" +
        "myInputDateDir.setAttribute('value','" + t_Param.datedir + "');" +
        'myForm.appendChild(myInputDateDir);' +
        "var myInputFile = document.createElement('input');" +
        "myInputFile.setAttribute('type', 'file');" +
        "myInputFile.setAttribute('name', 'filename');" +
        'myForm.appendChild(myInputFile);' +
        'document.body.appendChild(myForm);' +
        'myForm.submit();' +
        'document.body.removeChild(myForm);'
      window.open(rsl, '_blank')
    } // end if
  },

  /************************************************************************************************
   * Function명  : fn_PdfFileDelCachFile
   * 설명        : PDF 파일 삭제
   ************************************************************************************************/
  fn_PdfFileDelCachFile () {
    let lv_vm = this
    let t_Param = {filename: this.itemData.baseData.eltrnDoctId + '.pdf'}
    window.fdpbridge.exec('PSFileDeletePlugin', t_Param, (result) => {
      try {
        lv_vm.getStore('toast').dispatch('ADD', '전자서명 PDF  완료되었습니다')
      } catch (error) {
        lv_vm.fn_trace('error', '전자서명 PDF 삭제중 오류 발생')
      }
    }, () => {
      lv_vm.fn_trace('error', '전자서명 PDF 삭제중 오류 발생')
    })
  },

  /************************************************************************************************
   * Function명  : fn_PDFSignComplete
   * 설명        : 전자서명 완료
   ************************************************************************************************/
  fn_PDFSignComplete () {
    console.log('fn_PDFSignComplete start 00::' + JSON.stringify(this.itemData.signerData))
    // 전자약관 LMS 전송 셋팅 03 과 04 일경우만 셋팅 한다.
    if (this.itemData.eltrnSaveData.isuMthScCd === '03' || this.itemData.eltrnSaveData.isuMthScCd === '04') {
      console.log('fn_PDFSignComplete start 01::completeData::' + JSON.stringify(this.completeData))
      console.log('fn_PDFSignComplete start 01::dclmsSndTransVO::' + JSON.stringify(this.completeData.dCLmsSndTransVO))
      this.fn_AlertLmsComplete(this.completeData.dCLmsSndTransVO)
    } else if (this.itemData.signerData.mobpCswfYn === 'N' && this.itemData.eltrnSaveData.isuMthScCd === '01') { // 완전판매가 아니고 01 일때(휴대전화번호 있을수도 있고 없을수도 있음)
      console.log('fn_PDFSignComplete start 02::')
      // LMS를 보낼수 있는지 휴대전화번호 체크
      if (this.completeData.celno.trim() !== '') {
        console.log('fn_PDFSignComplete start 03::')
        this.fn_trace('info', '전자약관 LMS 전송 시작')

        this.fn_ServiceData('insLms')
        console.log('fn_PDFSignComplete start 04::')
      } else {
        console.log('fn_PDFSignComplete start 05::')
        PSScnAcpnUtil.fn_ScnAcpnService(this.itemData, this.fn_ScnAcpnChackServiceResultHandler)
      } // end else if
    } else { // 서면일때 모바일 전송 스킵하고 후속 처리
      console.log('fn_PDFSignComplete start 06::')
      PSScnAcpnUtil.fn_ScnAcpnService(this.itemData, this.fn_ScnAcpnChackServiceResultHandler)
    } // end else if
  },
  /************************************************************************************************
   * Function명  : fn_DCDocuMngVOSetting
   * 설명        : 전자서명 완료 필요 값 세팅
   ************************************************************************************************/
  fn_DCDocuMngVOSetting: function () {
    let mODCDocuMngVO = {}
    try {
      mODCDocuMngVO.moblBusnScCd = 'SE'// 모바일업무구분코드
      mODCDocuMngVO.eltrnDoctScCd = '01' // 전자문서구분코드
      mODCDocuMngVO.eltrnDoctId = this.itemData.baseData.eltrnDoctId // 전자문서ID
      mODCDocuMngVO.custNm = this.itemData.eltrnSaveData.mnContrNm // 고객명
      mODCDocuMngVO.rrno = this.itemData.contInfoData.knb // 주민등록번호
      mODCDocuMngVO.prdtNm = this.planNm // 상품명
      mODCDocuMngVO.prcd = this.itemData.external.externalnumId // 상품코드 (약관조회용 Unique Key)
      mODCDocuMngVO.regntEno = PSServiceManager.getUserInfo('userEno') // 등록자사번
      mODCDocuMngVO.orgNo = PSServiceManager.getUserInfo('onpstFofOrgNo') // 소속코드
      mODCDocuMngVO.eltrnDoctClsfCd = '01' // 전자문서분류코드 - 01:진본으로 세팅
      mODCDocuMngVO.eltrnDoctFileNm = this.itemData.baseData.eltrnDoctId + '.pdf'// 전자문서파일명
      mODCDocuMngVO.eltrnDoctFilePathNm = 'file.path.ofr.as' // 전자문서파일경로명
      mODCDocuMngVO.emailAddr = this.itemData.contInfoData.emailAddr // 이메일주소
      mODCDocuMngVO.reqSrvcNm = 'MSPPS610M'//'TSSPS180M' // 요청서비스명
      mODCDocuMngVO.reqShpScCd = '03' // 요청형태구분코드 03- 이메일

      if (this.itemData.eltrnSaveData.ofrAcpnGudnLmsObjYn === 'Y') {
        mODCDocuMngVO.lmsSlctCd = '1' // LMS선택코드 Y:동의
      } else {
        mODCDocuMngVO.lmsSlctCd = '2' // LMS선택코드 N:미동의
      } // end else if

      // 완료처리시 뷰어에서 SMS로 결과 받길 원하는 경우는 전화번호 세팅.. N 인경우는 전화번호 공백 세팅
      if (this.itemData.eltrnSaveData.contrSmsReciCnsntYn === 'Y') {
        mODCDocuMngVO.celNo = String(this.itemData.contInfoData.celno).replace(/\-/g, '') // 휴대폰번호
      } else {
        mODCDocuMngVO.celNo = '' // 휴대폰번호
      } // end else if

      mODCDocuMngVO.suitaObjScCd = this.itemData.gafData.planInfoData[0].vainsUnQulfYn // 적합성대상 구분코드 - 변액 설명서 가 있는지 여부 판단용
      mODCDocuMngVO.clctCnsltNo = PSServiceManager.getUserInfo('userEno') // 모집컨설턴트
      mODCDocuMngVO.trtrEno = PSServiceManager.getUserInfo('userEno') // 처리자사번

      // 전자 서명 입력 Data화 시작
      mODCDocuMngVO.fstIsuMthScCd = this.itemData.eltrnSaveData.isuMthScCd // 최초교부방법구분코드
      mODCDocuMngVO.secusCvancMthCd = this.itemData.eltrnSaveData.secusCvancMthCd // 증권전달방법코드
      mODCDocuMngVO.vainsRprtRcvMthCd = this.itemData.eltrnSaveData.rprtRcpplScCd // 변액보고서수령방법코드
      if (this.itemData.eltrnSaveData.cmmuRscCnsntYn === '1') {
        mODCDocuMngVO.cmmuSaleRscCnsntYn = 'y' // 통신판매해지동의여부
      } else {
        mODCDocuMngVO.cmmuSaleRscCnsntYn = 'n' // 통신판매해지동의여부
      } // end else if
      mODCDocuMngVO.cpdClasPblYn = 'N' // CD약관발행여부 (고정 처리)
      mODCDocuMngVO.hght = this.itemData.eltrnSaveData.insrdHght // 신장
      mODCDocuMngVO.bwght = this.itemData.eltrnSaveData.insrdBwght // 체중
      // 전자 서명 입력 Data화 종료
      // LMS 약관 세팅
      this.fn_LmsSendObjValue()
      // 전자약관 LMS 전송 셋팅 03 과 04 일경우만 셋팅 한다.
      // if ((mODCDocuMngVO.fstIsuMthScCd === '03' || mODCDocuMngVO.fstIsuMthScCd === '04') && this.lmsObject !== null) {
      // 휴대폰번호 존재시 알림톡발송 // ASR240200373 종이약관 선택시 알림톡 발송 개발_우아미(240329_PJO)
      if ( this.lmsObject !== null && this.lmsObject.celNo !== null && this.lmsObject.celNo.trim().length > 0) {
        mODCDocuMngVO.dCLmsSndTransVO = this.fn_LmsSendComplete(false)
        mODCDocuMngVO.dCLmsSndTransVO.vainsPrdtYn = mODCDocuMngVO.suitaObjScCd // 변액셋팅
      } // end if

      // 191104 : 계약일자 신규 추가
      //        : DCEltrnSignCmpltTltCtlImpl.eltrnSignCmplt / DCDocuMngVO.contYmd : String 8자리 ( YYYYMMDD )
      //        : 실시간이체   : 출금동의일자 -> 계약일
      //                      : 출금동의일자 : TSSPS141D.vue : 출금계좌제공선택후 [다음]
      //        : 가상계좌입금 : 전자서명완료일시(당일) -> 계약일
      mODCDocuMngVO.contYmd = PSDateUtil.fn_CurrentDate()
      if (this.itemData.eltrnSaveData.frtmPrmPadMthCd === '1' || this.itemData.eltrnSaveData.frtmPrmPadMthCd === '9') { // 9 신용카드 (ASR240400333_24.05 장한나)
        mODCDocuMngVO.contYmd = this.itemData.eltrnSaveData.dfraCnsntYmd
      }
      // 보험거래문서디지털화: 고지입력정보내용 추가 mODCDocuMngVO.zaDsclsInptInfoCntnt = ' // 고지입력정보내용
      console.log('fn_DCDocuMngVOSetting>>>pvmnotice::::' + this.itemData.eltrnSaveData.dsclsMatrTlgmCntnt)
      let _zaDsclsInptInfoCntnt = {}

      _zaDsclsInptInfoCntnt.formData = JSON.parse(this.itemData.eltrnSaveData.dsclsMatrTlgmCntnt)
      
      if (this.nundwYn == 'Y') { // 선심사 정보 적합여부 값 및 userId 세팅(2024.02.13. 선심사PJT)
        _zaDsclsInptInfoCntnt.nundwNrmYn = this.nundwNrmYn
        _zaDsclsInptInfoCntnt.userEno = PSServiceManager.getUserInfo('userId')

        // 고객추가고지 정보는 전송항목에서 삭제할 것. 관련데이터 모두 초기화 처리함.
        for (let j = 0; j < _zaDsclsInptInfoCntnt.formData.length; j++) {
          let tmpInput = _zaDsclsInptInfoCntnt.formData[j].Input
          Object.keys(tmpInput).forEach((key) => {
            
            if (tmpInput[key] === '고객추가고지') { // 고객추가고지가 있는 질병상세항목에 있는 CD, CD_NM, ID, Title, NUNDW 태그는 초기화 처리
              tmpInput[key] = ''
              
              let tagNm = key.substring(0,8)
              let keyNm = tagNm + 'NUNDW'

              tmpInput[keyNm] = ''

              keyNm = tagNm + 'ID'
              tmpInput[keyNm] = ''
            }
          })
        }
      }

      mODCDocuMngVO.zaDsclsInptInfoCntnt = JSON.stringify(_zaDsclsInptInfoCntnt)
      console.log('mODCDocuMngVO.zaDsclsInptInfoCntnt:::: ' + mODCDocuMngVO.zaDsclsInptInfoCntnt)
    } catch (error) {
      this.fn_trace('error', '전자서명 완료 Data :fn_DCDocuMngVOSetting 오류', mODCDocuMngVO)
      return null
    } // end try..catch
    return mODCDocuMngVO
  },

  /*-----------------------------------------------------------------------------------------------
  * LMS 관련 처리영역
  ------------------------------------------------------------------------------------------------*/
  
  /************************************************************************************************
   * Function명  : fn_LmsSendObjValue
   * 설명        : 전자서명 완료
   ************************************************************************************************/
  fn_LmsSendObjValue () {
    // LMS 정보 저장
    this.lmsObject.lmsSlctCd = this.itemData.eltrnSaveData.ofrAcpnGudnLmsObjYn // LMS 문자동의 여부
    this.lmsObject.eltrnDoctId = this.itemData.baseData.eltrnDoctId // 전자문서 ID
    this.lmsObject.vuchNo = this.itemData.baseData.eltrnDoctId // 영수증 번호
    this.lmsObject.prdtNm = this.planNm // 상품명
    this.lmsObject.prcd = this.itemData.external.externalnumId // 상품코드 (약관조회용 Unique Key)
    this.lmsObject.custNm = this.itemData.eltrnSaveData.mnContrNm // 고객명
    this.lmsObject.chnlCustId = this.itemData.eltrnSaveData.mobslChnlCustId// 채널고객ID
    this.lmsObject.celNo = this.itemData.contInfoData.celno // 고객 핸드폰
    let str = String(this.lmsObject['celNo']).replace(/\-/g, '')
    str = str + 'LMS'
    var input = SHA256.computeDigest(str)
    this.lmsObject.celNoHash = input // 휴대폰 번호 Hash
  },

  /************************************************************************************************
   * Function명  : fn_LmsSendComplete
   * 설명        : 전자서명 완료
   ************************************************************************************************/
  fn_LmsSendComplete (isServiceCall) {
    let lmsSndTransVO = {}
    // if (this.lmsObject.lmsSlctCd === 'Y') { // LMS 동의 여부
    // ASR240200373 종이약관 선택시 알림톡 발송 개발_우아미(240329_PJO) 
    // #07899 알림톡 발송을 위해 휴대폰번호 세팅 로직 변경  (07899 내용 : 약관/청약서/변액운용설명서 URL, 보험가입확인 버튼 ) 
    // !isServiceCall :eform 전달 vo 세팅로직 에서 호출됨 ( fn_DCDocuMngVOSetting -> fn_LmsSendComplete(false) )
    // lmsSlctCd 세팅    :전자적 방법에 의한 보험계약서류 수령동의 약관 동의 선택  / ofrAcpnGudnLmsObjYn = Y ->  lmsObject.lmsSlctCd )
    if (!isServiceCall || this.lmsObject.lmsSlctCd === 'Y') {
      lmsSndTransVO.chnlCustId = this.lmsObject.chnlCustId // 채널고객ID
      lmsSndTransVO.eltrnDoctId = this.lmsObject.eltrnDoctId // 전자문서ID
      lmsSndTransVO.vuchNo = this.lmsObject.eltrnDoctId // 영수증번호
      lmsSndTransVO.smsCelno = this.lmsObject.celNo // 휴대폰번호
      lmsSndTransVO.smsCelnoHash = this.lmsObject.celNoHash // 휴대폰번호 Hash
      lmsSndTransVO.zzaftRctmFg = this.itemData.eltrnSaveData.aftRctmHopYn // 출금동의서 존재여부(후입금 선택여부)
    } // end if
    if (!isServiceCall) {
      lmsSndTransVO.vainsPrdtYn = '' // 변액여부
    } // end if
    return lmsSndTransVO
  },

  /************************************************************************************************
   * Function명  : fn_AlertLmsComplete
   * 설명        : 전자약관 LMS 전송 완료
   ************************************************************************************************/
  fn_AlertLmsComplete (resultLmsSndTransVO) {
    
    if (resultLmsSndTransVO) {
      this.fn_trace('info', '전자약관 LMS 전송 완료')
      let rtnVal = resultLmsSndTransVO.rtnValue
      let tempMsgDesc = ''
      
      if (rtnVal === '00') {
        
        if (this.itemData.signerData.mobpCswfYn === 'Y') {
          tempMsgDesc = '고객님의 휴대폰으로 발송된\n계약서류를 확인해주세요.'
        } else {
          tempMsgDesc = '고객님의 휴대폰으로 발송된\n모바일 약관을 확인해주세요.'
        }

      } else if (rtnVal === '95') {
        
        tempMsgDesc = '기존 고객카드에 등록된 휴대폰 번호와 일치하지 않습니다. 수정 후, 재등록 해주시기 바랍니다.'

      } else { // 90 //99:시스템 오류가 발생했습니다. 관리자에게 문의하여 주세요
        
        if (this.itemData.signerData.mobpCswfYn === 'Y') {
          tempMsgDesc = '계약서류가 발송되지 않았습니다.\n관리자에게 문의하여 주세요.'
        } else {
          tempMsgDesc = '모바일 약관이 발송되지 않았습니다.\n관리자에게 문의하여 주세요.'
        } 

      } 
      
      this.fn_trace('info', tempMsgDesc + '[' + rtnVal + ']')
      this.fn_AlertMsg('lmsComplete', tempMsgDesc, '확인')
      
    } else {
      this.fn_trace('error', '전자약관 LMS 전송 실패')
    } // end else if
  },


  /*-----------------------------------------------------------------------------------------------
  * 생체인증 지문 처리 관련 영역
  ------------------------------------------------------------------------------------------------*/

   /************************************************************************************************
   * Function명  : fn_CheckInsrdBioComplete
   * 설명        :  피보험자 지문등록 여부 확인.
   *   if ( 기등록 ) {
        lv_vm.fn_ServiceData('U4') // 서명 정보 업데이트 이후 로직
      } else {
        lv_vm.fn_ComponentChange('7') // 지문촬영화면
      }
   ************************************************************************************************/
  fn_CheckInsrdBioComplete () {
    let lv_vm = this
    const trnstId = 'txTSSPS40S4'
    const auth = 'S'
    let pParams = {}
    pParams.liveAthntCustScCd = '02' // 고객구분코드=피보험자고정
    pParams.vuchId = this.itemData.eltrnSaveData.vuchId // 영수증ID
    pParams.elstInsrdPartnNo = this.itemData.eltrnSaveData.elstInsrdPartnNo // 피보험자 파트너번호
    lv_vm.fn_trace('info', '피보험자 지문등록 여부 확인 pParams = ' + pParams)

    this.post(lv_vm, pParams, trnstId, auth, {}, true)
      .then(function (response) {
        lv_vm.fn_trace('info', '피보험자 지문등록 여부 확인 response = ' + response.body)
        if (response.body && response.body.insrdRegYn === 'Y' ) {
          lv_vm.fn_trace('info', '피보험자 지문등록 여부 확인 = ' + response.body.insrdRegYn + ' (이미등록됨)')
          if (response.body.fofAcpnYn === 'Y' ) { // 등록단계에서 지접점수요청건
            lv_vm.lv_FofAcpnYn = true // 지접접수요청여부 (부인방지전에체크하여 부인방지Pass)
            lv_vm.reSignerData.isDcmWcnstOmit = 'Y' // 피보험자 서면동의서생략이 Y인경우 현장접수 불가
            lv_vm.itemData.signerData['scnAcpnPsbYn'] = 'N'
            lv_vm.itemData.bioForceFofAcpn = true // 강제지점접수 처리
            lv_vm.itemData.bioForceFofAcpnMessage = '지점에서 피보험자 서면동의서 원본 확인 후 접수 가능합니다.'
          }
          lv_vm.fn_ServiceData('U4') // 서명 정보 업데이트 이후 로직
        } else {
          lv_vm.fn_trace('info', '피보험자 지문등록 여부 확인 = ' + response.body.insrdRegYn + ' (미등록)')
          if (response.body && response.body.fofAcpnYn === 'Y' ) { // 지점접수처리여부
            lv_vm.fn_trace('info', '지점접수처리여부 확인 = ' + response.body.fofAcpnYn + ' (지접접수대상)')
            lv_vm.fn_Child186DEvent('FAIL1') // 지점접수처리이벤트
          } else {
            lv_vm.fn_trace('info', '지점접수처리여부 확인 = ' + response.body.fofAcpnYn + ' (지접접수비대상)')
            lv_vm.fn_ComponentChange('7') // 지문촬영화면
          }
        }
      })
     .catch(function (error) {
        window.vue.error(error)
        lv_vm.fn_trace('error', 'fn_CheckInsrdBioComplete 지점접수처리여부 확인 오류')
      })
  },

  /************************************************************************************************
   * Function명  : [생체인증추가] fn_Child186DEvent
   * 설명        : 피보험자 지문촬영 결과 이벤트
   *  SUCCESS : 피보험자의 지문 정상처리 (촬영,등록,분할)
   *     (1) fn_ServiceData('U4') 이후 프로세스 진행.
   *  FAIL : 피보험자의 지문 처리실패 (촬영,등록,분할)
   *     (1) 현장접수 불가처리
   *          this.scnAcpnPsbData.isDcmWcnstOmit === 'Y' 처리후
   *     (2) fn_ServiceData('U4') 이후 프로세스 진행.
   *  lv_FofAcpnYn === true : 지접접수요청 상태.
   *     (1) U4 CallBack에서 부인방지처리를 수행할지 여부를 확인하는 Flag가 된다.
   *  this.itemData.bioForceFofAcpn
   *     (1) PSScnAcpnUtils.js 에서 현장접수 불가 메시지에 사용됨.
   ************************************************************************************************/
  fn_Child186DEvent: function (eventType) {
    this.fn_trace('info', 'fn_Child186DEvent:: ' + eventType )
    if (eventType === 'SUCCESS') { // 정상처리 (지문등록,인증,분할)
      this.lv_FofAcpnYn = false
      this.fn_ServiceData('U4')
    } else if (eventType === 'FAIL1') { // 지문취득실패 (지문취득동의 실패, 촬영실패, 등록실패) : 지점처리진행
      this.lv_FofAcpnYn = true // 지접접수요청여부 (부인방지전에체크하여 부인방지Pass)
      this.reSignerData.isDcmWcnstOmit = 'Y' // 피보험자 서면동의서생략이 Y인경우 현장접수 불가
      this.itemData.signerData['scnAcpnPsbYn'] = 'N'
      this.itemData.bioForceFofAcpn = true // 강제지점접수 처리
      this.itemData.bioForceFofAcpnMessage = '지점에서 피보험자 서면동의서 원본 확인 후 접수 가능합니다.'
      this.fn_ServiceData('U4')
    } else if (eventType === 'FAIL2') { // 전자서명실패(부인방지) : 지점처리진행
      this.fn_ShowLoading(false, ' ') // 화면 로딩 처리
      //this.lv_FofAcpnShow = true // 지접점수처리 메시지확인창 오픈
      this.fn_AlertMsg('fofAcpn', this.lv_MsgFofAcpn, '재요청|지점접수') 
    }
  },
  
  /************************************************************************************************
   * Function명  : [생체인증추가] fn_FofAcpnResult
   * 설명        : 전자서명 부인방지처리 실패시 지접접수처리
   ************************************************************************************************/
  fn_FofAcpnResult: function (result) {
    if ( result ) { // 지점 접수
      this.fn_trace('info', '전자서명 부인방지처리로 지점접수처리')
      this.lv_FofAcpnYn = true // 지접접수요청여부 (부인방지전에체크하여 부인방지Pass)
      this.reSignerData.isDcmWcnstOmit = 'Y' // 피보험자 서면동의서생략이 Y인경우 현장접수 불가
      this.itemData.signerData['scnAcpnPsbYn'] = 'N'
      this.itemData.bioForceFofAcpn = true // 강제지점접수 처리
      this.itemData.bioForceFofAcpnMessage = '지점에서 피보험자 서면동의서 원본 확인 후 접수 가능합니다.'
      this.fn_ServiceData('I8')
    } else { // 재요청
      this.itemData.bioForceFofAcpn = false
      this.itemData.bioForceFofAcpnMessage = ''
      this.fn_ProcBioSign()
    }
  },
  
  /************************************************************************************************
   * Function명  : [생체인증추가] fn_ProcBioSign
   * 설명        : 피보험자 전자서명부인방지 처리
   ************************************************************************************************/
  fn_ProcBioSign: function () {
    let lv_Vm = this
    const trnstId = 'txTSSPS42U1'
    const auth = 'S'
    let pParams = {}
    pParams.vuchId = this.itemData.eltrnSaveData.vuchId // 영수증ID
    pParams.clctCnsltNo = this.itemData.eltrnSaveData.clctCnsltNo // 모집컨설턴트
    pParams.elstInsrdPartnNo = this.itemData.eltrnSaveData.elstInsrdPartnNo // 피보험자BPID
    pParams.liveAthntCustScCd = '02' // 고객구분(02=피보험자)
    if ( this.reSignerData && this.reSignerData.hasOwnProperty('fileHash') ) {
      pParams.contdHashCntnt = this.reSignerData.fileHash // 계약서파일해시
    } else {
      pParams.contdHashCntnt = ''
    }
    
    lv_Vm.fn_trace('info', 'fn_ProcBioSign.params', pParams)
    lv_Vm.fn_ShowLoading(true, '2') // 화면 로딩 처리

    this.post(lv_Vm, pParams, trnstId, auth)
    .then(function (response) {
        if (response.body) {
          lv_Vm.fn_trace('info', 'fn_ProcBioSign 부인방지처리', response.body)
          let isProcOk = response.body.isProcOk
          if (isProcOk === 'Y') { // 정상처리완료
            lv_Vm.itemData.bioForceFofAcpn = false
            lv_Vm.itemData.bioForceFofAcpnMessage = ''
            lv_Vm.fn_ServiceData('I8')
          } else {
            lv_Vm.fn_Child186DEvent('FAIL2')
          }
        }
    })
    .catch(function (error) {
      lv_Vm.fn_trace('error', 'fn_ProcBioSign 부인방지처리오류', error)
      window.vue.error(error)
    })
  },

  /************************************************************************************************
   * Function명  : [생체인증추가] fn_CheckBioSignComplete
   * 설명        : 피보험자 전자서명(부인방지처리) 여부 확인
   *               (1) 계피상이건의 지문부인방지처리 여부를 확인
   *               (2) curStatusCd === '03' (부인방지 미처리 단계) 일때 부인방지처리
   *               (3) curStatusCd != '03' (부인방지 처리단계) 기존 중단건 처리로직에 따름
   ************************************************************************************************/
  fn_CheckBioSignComplete: function () {
    let lv_vm = this
    const trnstId = 'txTSSPS40S4'
    const auth = 'S'
    let pParams = {}
    
    pParams.liveAthntCustScCd = '02' // 고객구분코드=피보험자고정
    pParams.vuchId = this.itemData.eltrnSaveData.vuchId // 영수증ID
    pParams.elstInsrdPartnNo = this.itemData.eltrnSaveData.elstInsrdPartnNo // 피보험자 파트너번호
    lv_vm.fn_trace('info', '계약서 부인방지처리 pParams = ' + JSON.stringify(pParams))
    
    this.post(lv_vm, pParams, trnstId, auth, {}, true)
    .then(function (response) {
      lv_vm.fn_trace('info', '계약서 부인방지처리 결과 ' + JSON.stringify(response.body))
      if (response.body && response.body.curStatusCd) {
        lv_vm.fn_CallBackCheckBioSignComplete( response.body.curStatusCd )
      } else {
        
        //전자서명 중단건 재처리 오류건에 대한 프로세스 수정 가능여부 확인
        lv_vm.fn_trace('info', '피보험자 지문등록 여부 확인 insrdRegYn :' + response.body.insrdRegYn + ', fofAcpnYn :' + response.body.fofAcpnYn)

        if (response.body && response.body.insrdRegYn === 'Y' ) {
          if (response.body.fofAcpnYn === 'Y' ) { // 등록단계에서 지접점수요청건
            lv_vm.lv_FofAcpnYn = true // 지접접수요청여부 (부인방지전에체크하여 부인방지Pass)
           
            lv_vm.itemData.isDcmWcnstOmit = lv_vm.reSignerData.isDcmWcnstOmit = 'Y' // 피보험자 서면동의서생략이 Y인경우 현장접수 불가
            lv_vm.itemData.signerData['scnAcpnPsbYn'] = 'N'
            lv_vm.itemData.bioForceFofAcpn = true // 강제지점접수 처리
            lv_vm.itemData.bioForceFofAcpnMessage = '지점에서 피보험자 서면동의서 원본 확인 후 접수 가능합니다.'
          }
        }
        lv_vm.fn_CallBackCheckBioSignComplete('00')
      }
    })
    .catch(function (error) {
      window.vue.error(error)
      lv_vm.fn_CallBackCheckBioSignComplete( '00' )
    })
  },

  /************************************************************************************************
   * Function명  : [생체인증추가] fn_CallBackCheckBioSignComplete
   * 설명        : 중단건(전송실패)에 대응 프로세스
   ************************************************************************************************/
  fn_CallBackCheckBioSignComplete: function (curStat) {
    if (curStat === '03') { // 현재상태가 지문분할완료 상태 (부인방지처리 미완료)
      this.itemData.bioForceFofAcpn = false
      this.itemData.bioForceFofAcpnMessage = ''
      this.fn_ProcBioSign() // 전자서명(부인방지) 재요청
    } else { // 기존 프로세스
      if (this.itemData.signerData['elstCnclnYmd'] === '99999999') { // 전자서명 1단계 오류 일떄
        PSScnAcpnUtil.fn_ScnAcpnService(this.itemData, this.fn_ScnAcpnChackServiceResultHandler) // 청약접수 가능 여부
      } else {
        if (this.reqNameID === 'TSSPS213D_3_ERR') {
          this.fn_ServiceData('I8') // 전자서명 설계 및 청약/접수 상세 정보 입력
        } else {
          this.fn_GetPdfFileList()
        }
      }
    }
  },

  /************************************************************************************************
  * Function명  : [생체인증추가] fn_PrcsLiveAthntRslt
  * 설명        : PQM-IF (F1PQL0400) 생체인증결과전송 서비스호출.
  *              PDF업로드 완료 & 문서변화서버 전자서명 완료인터페이스 이후 처리
  *              1588 Line  if (type === 'complete') { 에서 처리함
  ************************************************************************************************/
  fn_PrcsLiveAthntRslt: function () {
    let lv_vm = this
    const trnstId = 'txTSSPS43P1'
    const auth = 'S'
    let pParams = {}
    pParams.vuchId = this.itemData.eltrnSaveData.vuchId // 영수증ID
    this.post(lv_vm, pParams, trnstId, auth, {}, true).then(function (response) {
      let isProcOk = response.body.isProcOk
      lv_vm.fn_trace('info', 'PQM 생체인증결과 서비스호출결과', isProcOk)
      lv_vm.fn_ServiceData('H') // 191112. 계약일,접수일자 alert 표시 추가
    }).catch(function (error) {
      window.vue.error(error)
      lv_vm.fn_ServiceData('H') // 191112. 계약일,접수일자 alert 표시 추가
    })
  },

  /*-----------------------------------------------------------------------------------------------
  * 기타 Util 영역
  ------------------------------------------------------------------------------------------------*/

  /************************************************************************************************
   * Function명  : fn_AlertMsg
   * 설명        : 알람창 제어
   ************************************************************************************************/
  fn_AlertMsg (msgType, message, buttonLabel) {
    this.fn_ShowLoading(false, ' ') // 화면 로딩 처리
    this.msgDesc = message
    this.completeType = msgType
    let labelArray = String(buttonLabel).split('|')
    if (labelArray.length === 1) {
      //this.negativeButtonLabel = ''
      //this.positiveButtonLabel = labelArray[0]
      this.fn_BottomAlert(message, labelArray[0])
    } else {
      //this.negativeButtonLabel = labelArray[0]
      //this.positiveButtonLabel = labelArray[1]
      this.fn_BottomConfirm(message, labelArray[1], labelArray[0])
    } // end else if
    //this.isShowAlert = true
    
  },

  /******************************************************************************
  * Function명  : fn_BottomAlert
  * 설명        : 확인 팝업
  ******************************************************************************/
  fn_BottomAlert (contents, title_pos_btn) {
    const lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
      properties: {
        single: true,
        title: "알림",
        content: contents,
        title_pos_btn: title_pos_btn
      },
      listeners: {
        onPopupConfirm: () => {
          console.log('onPopupConfirm call')
          this.$bottomModal.close(lv_AlertPop)
          window.setTimeout(() => {
            this.fn_OnPositive(this.completeType)
          }, 500);
          
        },
        onPopupClose: () => {
          console.log('onPopupClose call')
          this.$bottomModal.close(lv_AlertPop)
        }
      }
    })
  },

  /******************************************************************************
  * Function명 : fn_BottomConfirm
  * 설명       : Confirm 팝업 호출
  ******************************************************************************/
  fn_BottomConfirm (contents, title_pos_btn, title_neg_btn) {
    const lv_ConfirmPop = this.$bottomModal.open(MSPBottomAlert, {
      properties: {
        title: '알림',
        content: contents,
        title_pos_btn: title_pos_btn,
        title_neg_btn: title_neg_btn
      },
      listeners: {
        onPopupConfirm: () => {
          this.fn_OnPositive(this.completeType)
          this.$bottomModal.close(lv_ConfirmPop);
        },
        onPopupClose: () => {
          this.fn_OnNegative(this.completeType)
          this.$bottomModal.close(lv_ConfirmPop);
        }
      }
    })
  },

  /************************************************************************************************
   * Function명  : fn_ShowLoading
   * 설명        : 화면 로딩 처리 공통 메소드
   ************************************************************************************************/
  fn_ShowLoading (isShow, showType = ' ') {
    this.isShowLoading = isShow // 화면 로딩
    if (this.isShowLoading) {
      window.vue.getStore('progress').dispatch('FULL')
      window.vue.getStore('progress').dispatch('SHOW')
    }
    else {
      window.vue.getStore('progress').dispatch('HIDE')
    }
    if (showType === '0') {
      this.isShowLoading1 = false // 진행 중
      this.isShowLoading2 = false // 처리 중
    } else if (showType === '1') {
      this.isShowLoading1 = true // 진행 중
      this.isShowLoading2 = false // 처리 중
    } else if (showType === '2') {
      this.isShowLoading1 = false // 진행 중
      this.isShowLoading2 = true // 처리 중
    } // end else if
  },

/************************************************************************************************
 * Function명  : fn_DebugMode
 * 설명        : 프로그램 흐름 및 에러 로그 추적 공통 함수
 ************************************************************************************************/
fn_DebugMode () {
  let isDebug = this.serverChoice !== 'production'    
  if (PSDateUtil.fn_CurrentDate() < '20230801') isDebug = true
  //if (isDebug) this.isDebugMode = !this.isDebugMode
},

/************************************************************************************************
 * Function명  : fn_trace
 * 설명        : 프로그램 흐름 및 에러 로그 추적 공통 함수
 ************************************************************************************************/
fn_trace (type, addMsg, outData, msgType = ' ', fromScreenId) {
  let logObject = {type: '', logMsg: addMsg, dataMsg: ''}
  if (type === 'info') { // 정보성
    logObject.type = '정보'
  } else if (type === 'error') { // 에러 발생
    this.fn_ShowLoading(false, ' ') // 화면 로딩 처리
    logObject.type = '오류'
  } // end else if
  
  // 로칼, 개발, 검증일때만 로그 작업 처리
  if (this.serverChoice !== 'production') {
    // 출력 값이 Object 일때 String 로 변경
    if (typeof (outData) === 'object') {
      logObject.dataMsg = JSON.stringify(outData)
    } else {
      logObject.dataMsg = outData
    } // end else if
    window.vue.getStore('psStore').dispatch('DEBUG_LOG_ADD', logObject)
    console.log('[' + logObject.type + '] ' + logObject.logMsg + '\n' + this.debugLog)
    if (logObject.dataMsg !== '' && logObject.dataMsg !== undefined) {
       console.log('debugLogData:::' + logObject.logMsg + ': ' + logObject.dataMsg + '\n' + this.debugLogData)
    } // end if
  } // end if

  
  let vuchId = ''
  if (this.itemData && this.itemData['baseData'] && this.itemData.baseData['eltrnDoctId']) {
    vuchId = this.itemData.baseData['eltrnDoctId']
  } else if (this.$route.params.itemData) {
    let baseData = this.$route.params.itemData
    if (baseData['moDCDocuMngVO'] && baseData.moDCDocuMngVO['eltrnDoctId']) {
      vuchId = baseData.moDCDocuMngVO['eltrnDoctId'] // 문서명(영수증번호)
    } else if (baseData['vuchId']) {
      vuchId = baseData['vuchId']
    }
  } else {
    vuchId = 'not Found VuchId'
  }

  let _screenId = fromScreenId || this.$options.screenId
  
  PSServiceManager.setDynaTraceLog(type, addMsg, _screenId, vuchId) // 20201222 PJO
},


  //--------------------------------------------------------------------------------
    _fn_End(){return}//코딩 종료 함수 마지막 메서드 함수 , 없음 주의

 },// methods

}//export default


</script>