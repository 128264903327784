/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS611P
 * 화면 설명: 청약 파트 청약접수 팝업
              AS-IS TSSPS184P 전환 화면
 */
<template>
  <ur-page-container class="msp" title="청약접수" :show-title="true" :topButton="true" type="subpage" @on-header-left-click="fn_HeaderBackBtnHandler">
    
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap"> 
      <ur-box-container alignV="start" componentid="" direction="row" class="con-area pb132">
        <template v-if="list01">
          <span class="title must fs18rem fwm mt30">신분증 진위여부확인</span>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="idCardType" class="mt10" :disabled="isActive" @input="fn_OpenIdcTof">
              <mo-segment-button v-for="(item, idx) in idCard" :value="item.key" :key="idx">{{item.label}}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>

          <div class="full">
            <div class="mt10" v-if="isActive">
              <mo-icon icon="msp-is-checked" class="fs26 mr4"></mo-icon><span class="crTy-bk7 fs16rem">인증 완료되었습니다.</span>
            </div>
          </div>

          <div class="mt20 full" v-if="authPass">
            <mo-button type="button" class="ns-btn-round h40 white msp-btn full" @click="fn_AuthPass">신분증인증통과</mo-button>
          </div>
        </template>

        <div class="mt50 full" v-if="list02">
          <span class="title must fs18rem fwm">친권자1</span>
          <mo-text-field v-model="name1" :rules="requiredRule1" clearable class="form-input-name full mt10" underline placeholder="이름을 입력해주세요" />
          <mo-text-field v-model="phone1" :rules="requiredRule2" clearable class="form-input-name full mt30" underline placeholder="연락처를 입력해주세요" @input="fn_CelFocusInput1" :maxlength="13"/>
        </div>

        <div class="mt50 full" v-if="list02">
          <span class="title fs18rem fwm">친권자2</span>
          <mo-text-field v-model="name2" clearable class="form-input-name full mt10" underline placeholder="이름을 입력해주세요" />
          <mo-text-field v-model="phone2" clearable class="form-input-name full mt30" underline placeholder="연락처를 입력해주세요" @input="fn_CelFocusInput1" :maxlength="13"/>
        </div>

        <div class="mt50 full" v-if="list04">
          <span class="title must fs18rem fwm">수익자 동의</span>
          <mo-button type="button" class="ns-btn-round h40 white msp-btn full mt10" @click="fn_GetAgreement" :disabled="isGet">동의하기</mo-button>
          <div class="mt10" v-if="isGet">
            <mo-icon icon="msp-is-checked" class="fs26 mr4"></mo-icon><span class="crTy-bk7 fs16rem">동의 완료되었습니다.</span>
          </div>
        </div>

        <div class="mt50 full" v-if="list05">
          <span class="title must fs18rem fwm">New-AEUS 입력</span> <!-- AEUS > New-AEUS   [2024.04.01 선심사 및 영업시스템 고도화 PJT] -->
          <mo-button type="button" class="ns-btn-round h40 white msp-btn full mt10" @click="fn_InputAeus" :disabled="isAeus1">입력하기</mo-button>
          <mo-button type="button" class="ns-btn-round h40 white msp-btn full mt10" @click="fn_InputAeusConfirm" :disabled="isAeus2">완료확인</mo-button>
          <div class="mt10" v-if="isAeus3">
            <mo-icon icon="msp-is-checked" class="fs26 mr4"></mo-icon><span class="crTy-bk7 fs16rem">입력 완료되었습니다.</span>
          </div>
        </div>

        <div class="mt50 full" v-if="list03">
          <span class="title must fs18rem fwm">가상계좌채번</span>
          <mo-button type="button" class="ns-btn-round h40 white msp-btn full mt10" @click="fn_ExeVirtualAccount" :disabled="isSuccess">신청하기</mo-button>
          <div class="mt10" v-if="isSuccess">
            <mo-icon icon="msp-is-checked" class="fs26 mr4"></mo-icon><span class="crTy-bk7 fs16rem">신청 완료되었습니다.</span>
          </div>
        </div>

        <div class="mt50 full" v-if="list06">
          <span class="title must fs18rem fwm">지정대리청구인1(대표)</span>
          <mo-button type="button" class="ns-btn-round h40 white msp-btn full mt10" @click="fn_AsgnProxyClamGetAgreement()" :disabled="isAsgnProxyClamGet">동의하기</mo-button>
          <div class="mt10" v-if="isAsgnProxyClamGet">
            <mo-icon icon="msp-is-checked" class="fs26 mr4"></mo-icon><span class="crTy-bk7 fs16rem">동의 완료되었습니다.</span>
          </div>
        </div>

        <div class="mt50 full" v-if="list07">
          <span class="title must fs18rem fwm">지정대리인2</span>
          <mo-button type="button" class="ns-btn-round h40 white msp-btn full mt10" @click="fn_AsgnProxyClamGetAgreement2()" :disabled="isAsgnProxyClamGet2">동의하기</mo-button>
          <div class="mt10" v-if="isAsgnProxyClamGet2">
            <mo-icon icon="msp-is-checked" class="fs26 mr4"></mo-icon><span class="crTy-bk7 fs16rem">동의 완료되었습니다.</span>
          </div>
        </div>
      </ur-box-container>
    </ur-box-container>
    <!-- Content 영역 end -->
    <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative noshadow">
      <div class="relative-div">
        <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round white" @click="fn_Cancel">취소</mo-button>
        <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_Confirm" :disabled="isComplete">확인</mo-button>
      </div>
    </ur-box-container>

  </ur-page-container>
   <!-- / root ur-page-container -->  
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import moment from 'moment'
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSConstants from '@/config/constants/psConstants'

import PSCommUtil from '~/src/ui/ps/comm/PSCommUtil'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import PSAeusUtil from '@/ui/ps/comm/PSAeusUtil.js' // AEUS 화면 이동
import MSPPS612P from '@/ui/ps/MSPPS612P' // 수익자 상이 동의팝업 AS-IS TSSPS185P
import MSPPS613P from '@/ui/ps/MSPPS613P' // 지정대리청구인 상이 동의팝업 AS-IS TSSPS187P
import MSPPS061P from '@/ui/ps/MSPPS061P' // 신분증 진위여부확인
import MSPPI840P from '@/ui/pi/MSPPI840P' // [선심사 및 영업시스템 고도화 PJT] New AEUS 입력

export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS611P', 
  screenId: 'MSPPS611P',
  isBackKeyHandle : true , // 팝업일때 디바이스 백키 직접 핸들 처리 여부 옵션
  components: {
    'MSPPI840P' : MSPPI840P, // [선심사 및 영업시스템 고도화 PJT] New AEUS 입력
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
    itemData: {type: Object}
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      requiredRule1: [
        v => !!v || '이름 항목은 필수 정보입니다.',
      ],
      requiredRule2: [
        v => !!v || '연락처 항목은 필수 정보입니다.',
      ],
      inputData: {}, // 받아온 전체 데이터
      gafData: {}, // 받아온 데이터중 계약자/피보험자/수익자/상품 데이터
      eltrnSaveData: {}, // 받아온 데이터중 Table 2,4 데이터
      list01: false, // 신분증 진위여부 확인 display 여부
      list02: false, // 친권자 연락처 display 여부
      list03: false, // 가상계좌 채번 display 여부
      list04: false, // 수익자 동의 display 여부
      list05: false, // AEUS 입력 display 여부
      list06: false, // 지정대리청구인 동의 display 여부
      list07: false, // 2021.06.22 ASR210501099_지대청 2인지정 현장접수 반영
      //isTSSPS185P: false, // 수익자 동의 팝업 display 여부
      //isTSSPS187P: false, // 지정대리청구인 동의 팝업 display 여부
      //isTSSPS187P2: false, // 2021.06.22 ASR210501099_지대청 2인지정 현장접수 반영
      name1: '',
      phone1: '',
      name2: '',
      phone2: '',
      isActive: false, // 앱 실행하기 버튼 disable 여부
      isSuccess: false, // 가상계좌 신청하기 버튼 disable 여부
      isGet: false, // 수익자 동의하기 버튼 disable 여부
      isAsgnProxyClamGet: false, // 지정대리청구인 동의하기 버튼 disable 여부
      isAsgnProxyClamGet2: false, // 2021.06.22 ASR210501099_지대청 2인지정 현장접수 반영
      isAeus1: false, // AEUS입력 버튼 disable 여부
      isAeus2: true, // AEUS완료 확인 버튼 disable 여부
      isAeus3: false, // AEUS완료 텍스트 visible 여부
      bnfrInfoData: {}, // 수익자 정보 - 185P에 넘겨줄 데이터
      asgnProxyClamzInfoData: {}, // 지정대리청구 정보
      asgnProxyClamzInfoData2: {}, // 2021.06.22 ASR210501099_지대청 2인지정 현장접수 반영
      zaVrtAccnNo: '', // 가상계좌번호
      isState: '', // 화면단에서 현재 액션에 상태를 저장하는 flag : A(신분증진위여부확인), B(친권자연락처), C(가상계좌채번), D(수익자동의), E1(AEUS입력), E2(AEUS입력)
      //isShowAlert: false, // fdp 컨펌 팝업
      msgDesc: 'ᆞ 가상계좌로 납입하시는 경우 계약자명과 송금인명은 반드시 동일해야합니다.<br /><br />ᆞ 가상계좌의 입금금액은 실납입보험료와 반드시 동일해야합니다.', // fdp 컨펌 팝업 메시지
      // DB 저장 정보
      idcTofYn: '', // 신분증진위여부
      idcTofCnfDtm: '', // 신분증진위확인일시
      prtrPesonNm01: '', // 친권자명01
      prtrPesonTelnoEncr01: '', // 친권자전화번호암호화01
      prtrPesonNm02: '', // 친권자명02
      prtrPesonTelnoEncr02: '', // 친권자전화번호암호화02
      bnkAccnInfoId: '', // 가상계좌ID
      bnfrCnsntCnfCd: '', // 수익자동의확인코드
      apcCnsntYn: '', // 지정대리청구인동의확인코드
      apcCnsntYn02: '', // 2021.06.22 ASR210501099_지대청 2인지정 현장접수 반영
      idCard: [{
        key: '1',
        label: '주민등록증'
      },
      {
        key: '2',
        label: '운전면허증'
      },
      {
        key: '3',
        label: '외국인등록증'
      }],
      idCardType: '',
      authPass: false // 운영계 외의 서버에서 인증 패스 할 수 있도록 하는 변수
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    // backButton event 등록
    //상단 isBackKeyHandle : true ,  팝업일때 디바이스 백키 직접 핸들 처리 여부 옵션 있어야 핸들링 가능
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler611P)

    // 검증계면 인증 패스 로직 유효 local/development/stage/production
    this.authPass = (process.env.NODE_ENV !== 'production')

    this.inputData = this.itemData
    this.gafData = this.itemData.gafData
    this.eltrnSaveData = this.itemData.eltrnSaveData
    // === 2021.06.XX 임시 테스트 start //
    // this.eltrnSaveData.apcCnsntYn02 = 'N'
    // this.eltrnSaveData.apcChnlCustId = '74680531338161401202'
    // this.eltrnSaveData.apcCustNm = '이재희'
    // this.eltrnSaveData.contrRltnCd = '01'
    // this.eltrnSaveData.apcChnlCustId02 = '89246914109003210202'
    // this.eltrnSaveData.apcCustNm02 = '나장남'
    // this.eltrnSaveData.contrRltnCd02 = '02'
    // this.eltrnSaveData.apcPrsonAplScCd = '02'
    // this.eltrnSaveData.apcCnsntYn = 'N'
    // this.eltrnSaveData.asgnProxyClamEntYn = 'Y'
    // this.eltrnSaveData.bnfrDfrncYn = 'Y'
    // this.eltrnSaveData.bnfrCnsntCnfCd = 'N'
    // === 2021.06.XX 임시 테스트 end //
    this.fn_InitPage() // 화면 초기 셋팅

  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
     // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler611P)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {
    isComplete () {
      let rst = false
      if (this.list01) {
        if (this.isActive) rst = this.isActive
        else return true
      }
      if (this.list02) {
        if (this.name1.trim().length > 0 && this.phone1.trim().length > 0) rst = (this.name1.trim().length > 0 && this.phone1.trim().length > 0)
        else return true
      }
      if (this.list03) {
        if (this.isSuccess) rst = this.isSuccess
        else return true
      }
      if (this.list04) {
        if (this.isGet) rst = this.isGet
        else return true
      }
      if (this.list05) {
        if (this.isAeus3) rst = this.isAeus3
        else return true
      }
      if (this.list06) {
        if (this.isAsgnProxyClamGet) rst = this.isAsgnProxyClamGet
        else return true
      }
      // 2021.06.22 ASR210501099_지대청 2인지정 현장접수 반영
      if (this.list07) {
        if (this.isAsgnProxyClamGet2) rst = this.isAsgnProxyClamGet2
        else return true
      }
      return !rst
    }
  },//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    
  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/
    /******************************************************************************
    * Function명 : fn_InitPage
    * 설명       : 페이지 초기 셋팅 함수
    ******************************************************************************/
    fn_InitPage () {
      if (this.eltrnSaveData) {
        // 화면 Display
        this.list01 = (this.eltrnSaveData.idcTofCnfObjYn === 'Y' && this.eltrnSaveData.idcTofCnfDtm.trim() === '') // 신분증진위확인대상여부
        // 2022.02.08 ASR220100585_친권인 연락처 사전정보입력 정보 자동반영(현장접수팝업삭제)
        this.list02 = (this.eltrnSaveData.prtrPesonGudnObjYn === 'Y' && (this.eltrnSaveData.prtrPesonNm01.trim() === '' || this.eltrnSaveData.prtrPesonTelnoEncr01.trim() === '')) // 친권자안내대상여부
        this.list03 = (this.eltrnSaveData.frtmPrmPadMthCd === '4' && this.eltrnSaveData.bnkAccnInfoId.trim() === '') // 계좌 유형 && 계좌 생성 확인
        this.list04 = (this.eltrnSaveData.bnfrDfrncYn === 'Y' && this.eltrnSaveData.bnfrCnsntCnfCd !== 'Y') // 수익자상이여부 && 수익자동의완료여부
        this.list05 = (this.eltrnSaveData.eusUndwCmpltYmd.trim() === '') // EUS계약심사완료일자 -> 비대상 : '00000000', 입력완료 : '조회한 해당일자', 입력필요 ' '
        //ASR240201001_간편지대청 도입(사랑온 접수)
        this.list06 = (this.eltrnSaveData.simpAsgnClamAgntCd01 !== 'B' && this.eltrnSaveData.asgnProxyClamEntYn === 'Y' && this.eltrnSaveData.apcCnsntYn !== 'Y') // 지정대리청구인
        this.list07 = (this.eltrnSaveData.simpAsgnClamAgntCd02 !== 'B' && this.eltrnSaveData.apcPrsonAplScCd === '02' && this.eltrnSaveData.apcCnsntYn02 !== 'Y') // 2021.06.22 ASR210501099_지대청 2인지정 현장접수 반영
        if (this.list04) { // 수익자 정보 셋팅
          this.bnfrInfoData = {}
          this.bnfrInfoData.vuchId = this.eltrnSaveData.vuchId // 영수증ID
          this.bnfrInfoData.elstPlanId = this.eltrnSaveData.elstPlanId // 설계ID
          this.bnfrInfoData.bnfrInfoList = this.inputData.bnfrInfoList // 수익자정보
        }
        if (this.list06) { // 지정대리청구인 정보 셋팅
          this.asgnProxyClamzInfoData = {}
          this.asgnProxyClamzInfoData.vuchId = this.eltrnSaveData.vuchId // 영수증ID
          this.asgnProxyClamzInfoData.elstPlanId = this.eltrnSaveData.elstPlanId // 설계ID
          this.asgnProxyClamzInfoData.InfoData = {
            apcChnlCustId: this.eltrnSaveData.apcChnlCustId, // 지정대리청구 모바일영업채널고객ID
            apcCustNm: this.eltrnSaveData.apcCustNm, // 지정대리청구명
            contrRltnCd: this.eltrnSaveData.contrRltnCd, // 계약자관계코드
            apcPrsonScCd: '01' // 지정대리인구분코드
          }
          this.fn_ServiceData('S4')
        }
        // 2021.06.22 ASR210501099_지대청 2인지정 현장접수 반영
        if (this.list07) { // 지정대리청구인2 정보 셋팅
          this.asgnProxyClamzInfoData2 = {}
          this.asgnProxyClamzInfoData2.vuchId = this.eltrnSaveData.vuchId // 영수증ID
          this.asgnProxyClamzInfoData2.elstPlanId = this.eltrnSaveData.elstPlanId // 설계ID
          this.asgnProxyClamzInfoData2.InfoData = {
            apcChnlCustId: this.eltrnSaveData.apcChnlCustId02, // 지정대리청구 모바일영업채널고객ID
            apcCustNm: this.eltrnSaveData.apcCustNm02, // 지정대리청구명
            contrRltnCd: this.eltrnSaveData.contrRltnCd02, // 계약자관계코드
            apcPrsonScCd: '02' // 지정대리인구분코드
          }
          this.fn_ServiceData('S5')
        }
        // DB 업데이트 항목 받아온 데이터
        this.idcTofYn = this.eltrnSaveData.idcTofYn // 신분증진위여부
        this.idcTofCnfDtm = this.eltrnSaveData.idcTofCnfDtm // 신분증진위확인일시
        this.name1 = this.eltrnSaveData.prtrPesonNm01.trim() // 친권자명01
        this.phone1 = this.eltrnSaveData.prtrPesonTelnoEncr01.trim() // 친권자전화번호암호화01
        this.name2 = this.eltrnSaveData.prtrPesonNm02.trim() // 친권자명02
        this.phone2 = this.eltrnSaveData.prtrPesonTelnoEncr02.trim() // 친권자전화번호암호화02
        this.bnkAccnInfoId = this.eltrnSaveData.bnkAccnInfoId // 가상계좌ID
        this.bnfrCnsntCnfCd = this.eltrnSaveData.bnfrCnsntCnfCd // 수익자동의확인코드
        this.apcCnsntYn = this.eltrnSaveData.apcCnsntYn // 지정대리청구인동의확인코드
        this.apcCnsntYn02 = this.eltrnSaveData.apcCnsntYn02 // 2021.06.22 ASR210501099_지대청 2인지정 현장접수 반영
      }
    },
    /******************************************************************************
    * Function명  : fn_ServiceData
    * 설명        : 서비스 호출 함수 정의
    ******************************************************************************/
    fn_ServiceData (serviceName) {
      let pParams = {}
      pParams.data = {}
      pParams.srnId = this.$options.screenId // 대표 화면 명
      switch (serviceName) {
        case 'S': // 가상계좌 채번
          pParams.appId = 'sli.ipi'
          pParams.trnstId = 'txTSSPS18P4'
          pParams.fnctScCd = 'U'
          pParams.serviceId = 'C339_F1CDI0021_S'
          pParams.reqVONm = 'sli.in.ea2.vo.a0001cd.EAF1CDI0021ReqVO'
          pParams.resVONm = 'sli.in.ea2.vo.a0001cd.EAF1CDI0021ResVO'
          pParams.data.bukrs = 'I100' // 회사코드
          pParams.data.bankl = '020' // 은행키 -> 초회보험료는 우리은행(020)만 가능
          pParams.data.ZA_VRT_ACCN_BUSN_SC_CD = '00' // 가상계좌업무구분코드
          pParams.data.ZA_VRT_ACCN_APL_PATH_CD = '06'// 가상계좌신청경로코드
          pParams.data.ZA_LST_REG_CUST_NO = this.eltrnSaveData.vuchId // 영수증 Id
          pParams.data.PARTNER = this.eltrnSaveData.elstPartnNo // 전자서명파트너번호
          pParams.data.ZA_LST_MNG_EPLY_NO = PSServiceManager.getUserInfo('userId') // FC 사번
          pParams.data.ZA_LST_MNG_DEPT_CD = PSServiceManager.getUserInfo('onpstFofOrgNo') // FC 소속 지점
          break
        case 'S1': // 등록계좌조회
          pParams.appId = 'sli.iic'
          pParams.trnstId = 'txTSSPS13S3'
          pParams.fnctScCd = 'S'
          pParams.serviceId = 'C339_F1MDI0013_S'
          pParams.reqVONm = 'sli.in.ea2.vo.a0001md.EAF1MDI0013VO'
          pParams.resVONm = 'sli.in.ea2.vo.a0001md.EAF1MDI0013VO'
          pParams.data.custNm = this.gafData.hoshInfoList[0].insrdNm || '' // 계약자 이름
          pParams.data.rrn = this.gafData.hoshInfoList[0].custRrnId || '' // 계약자 주민번호
          pParams.data.custAccnRltnCd = '' // 은행코드 - ''으로 들어가면 계좌 전부 조회함
          break
        case 'S2': // 체크팝업 결과 업데이트
          pParams.appId = 'tss.ps'
          pParams.fnctScCd = 'U'
          pParams.trnstId = 'txTSSPS99U8'
          pParams.data.idcTofYn = this.idcTofYn // 신분증진위여부
          pParams.data.idcTofCnfDtm = this.idcTofCnfDtm // 신분증진위확인일시
          if (this.isState === 'B') {
            pParams.data.prtrPesonNm01 = (this.name1 === '') ? ' ' : this.name1 // 친권자명01
            pParams.data.prtrPesonTelnoEncr01 = (this.phone1 === '') ? ' ' : this.phone1.replace(/-/g, '') // 친권자전화번호암호화01
            pParams.data.prtrPesonNm02 = (this.name2 === '') ? ' ' : this.name2 // 친권자명02
            pParams.data.prtrPesonTelnoEncr02 = (this.phone2 === '') ? ' ' : this.phone2.replace(/-/g, '') // 친권자전화번호암호화02
          } else {
            pParams.data.prtrPesonNm01 = this.eltrnSaveData.prtrPesonNm01 // 친권자명01
            pParams.data.prtrPesonTelnoEncr01 = this.eltrnSaveData.prtrPesonTelnoEncr01 // 친권자전화번호암호화01
            pParams.data.prtrPesonNm02 = this.eltrnSaveData.prtrPesonNm02 // 친권자명02
            pParams.data.prtrPesonTelnoEncr02 = this.eltrnSaveData.prtrPesonTelnoEncr02 // 친권자전화번호암호화02
          }
          pParams.data.bnkAccnInfoId = this.bnkAccnInfoId // 가상계좌ID
          pParams.data.bnfrCnsntCnfCd = this.bnfrCnsntCnfCd // 수익자동의확인코드
          pParams.data.vuchId = this.eltrnSaveData.vuchId // 영수증id
          pParams.data.apcCnsntYn = this.apcCnsntYn // 지정대리청구인동의확인코드
          pParams.data.apcCnsntYn02 = this.apcCnsntYn02 // 2021.06.22 ASR210501099_지대청 2인지정 현장접수 반영
          break
        case 'S3': // AEUS 입력 여부
          let sdrvDtVal = JSON.stringify({ZA_PLAN_ID: this.eltrnSaveData.elstPlanId})
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS10S8'))
          pParams.data = {}
          pParams.data.cmndSrvcNm = 'selEPD28'
          pParams.data.sdrvDtVal = sdrvDtVal
          break
        case 'S4':
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS13S4'))
          pParams.data = {}
          pParams.data.cnsltNo = PSServiceManager.getUserInfo('userId') // 모집컨설턴트 번호
          pParams.data.chnlCustId = this.eltrnSaveData.apcChnlCustId // 채널 고객 ID
          pParams.data.custNm = this.eltrnSaveData.apcCustNm // 고객명
          break
        case 'S5': // 2021.06.22 ASR210501099_지대청 2인지정 현장접수 반영
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS13S4'))
          pParams.data = {}
          pParams.data.cnsltNo = PSServiceManager.getUserInfo('userId') // 모집컨설턴트 번호
          pParams.data.chnlCustId = this.eltrnSaveData.apcChnlCustId02 // 채널 고객 ID
          pParams.data.custNm = this.eltrnSaveData.apcCustNm02 // 고객명
          break
        default:
          break
      }
      PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler)
    },
    /************************************************************************************************
     * Function명  : fn_ServiceResultHandler
     * 설명        : 서비스 호출 결과 함수 정의
     ************************************************************************************************/
    fn_ServiceResultHandler (event, serviceName) {
      let d = event.data
      switch (serviceName) {
        case 'S': // 가상계좌 채번
          if (d && d.za_VRT_ACCN_NO && d.za_VRT_ACCN_NO.trim().length > 0) {
            this.zaVrtAccnNo = d.za_VRT_ACCN_NO
            this.fn_ServiceData('S1')
          } else {
            this.getStore('confirm').dispatch('ADD', d.char100)
            this.isState = '' // 현재 상태 초기화
          }
          break
        case 'S1': // 등록계좌 조회
          let tmpArr = d.cmcustAccnInfoListVO || []
          let tmpArrLen = tmpArr.length
          if (tmpArrLen > 0) {
            for (let i = 0; i < tmpArrLen; i++) {
              if (this.zaVrtAccnNo === tmpArr[i].accnNo) {
                this.bnkAccnInfoId = tmpArr[i].accnId // 채번된 계좌와 등록된 계좌가 같다면 계좌 ID를 가지고 있는다
                this.fn_ServiceData('S2')
                break
              }
            }
          } else {
            this.getStore('confirm').dispatch('ADD', '등록계좌가 없습니다.')
            this.isState = '' // 현재 상태 초기화
          }
          break
        case 'S2': // 체크팝업 결과 업데이트
          if (d && d.trtRslt && d.trtRslt > 0) {
            if (this.isState === 'A') { // 신분증 진위여부
              this.isActive = !this.isActive
              this.isState = '' // 현재 상태 초기화
            } else if (this.isState === 'B') { // 친권자 연락처 저장
              this.$emit('confirmPopup')
            } else if (this.isState === 'C') { // 가상계좌 채번
              this.isSuccess = !this.isSuccess
              this.isState = '' // 현재 상태 초기화
            } else if (this.isState === 'D') { // 수익자 동의
              this.isGet = !this.isGet
              this.isState = '' // 현재 상태 초기화
            } else if (this.isState === 'AS') {
              this.isAsgnProxyClamGet = !this.isAsgnProxyClamGet
              this.isState = '' // 현재 상태 초기화
            } else if (this.isState === 'AS2') { // 2021.06.22 ASR210501099_지대청 2인지정 현장접수 반영
              this.isAsgnProxyClamGet2 = !this.isAsgnProxyClamGet2
              this.isState = '' // 현재 상태 초기화
            }
          } else {
            this.getStore('confirm').dispatch('ADD', event.msgComm.msgCd + ' ' + event.msgComm.msgDesc)
          }
          break
        case 'S3': // AUES 입력여부 확인
          // if (d && d.ZA_EUS_PRG_STAT_CD && d.ZA_EUS_PRG_STAT_CD === '1') { // AEUS 입력완료
          //   this.isAeus1 = this.isAeus2 = this.isAeus3 = true
          // } else {
          //   this.isAeus1 = this.isAeus2 = this.isAeus3 = false
          //   this.getStore('confirm').dispatch('ADD', 'AEUS 입력이 완료되지 않았습니다.')
          // }
          // 보험거래문서 디지털화 : 테스트 위해 AEUS 예외처리
          if (process.env.NODE_ENV !== 'production') {
            this.isAeus1 = this.isAeus2 = this.isAeus3 = true
          } else {
            if (d && d.ZA_EUS_PRG_STAT_CD && d.ZA_EUS_PRG_STAT_CD === '1') { // AEUS 입력완료
              this.isAeus1 = this.isAeus2 = this.isAeus3 = true
            } else {
              this.isAeus1 = this.isAeus2 = this.isAeus3 = false
              this.getStore('confirm').dispatch('ADD', 'AEUS 입력이 완료되지 않았습니다.')
            }
          }
          break
        case 'S4':
          if (d) {
            this.asgnProxyClamzInfoData.InfoData.data = d
          }
          break
        case 'S5': // 2021.06.22 ASR210501099_지대청 2인지정 현장접수 반영
          if (d) {
            this.asgnProxyClamzInfoData2.InfoData.data = d
          }
          break
        default:
          break
      }
    },
    /************************************************************************************************
     * Function명  : fn_ServiceFaultHandler
     * 설명        : 에러 이벤트
     ************************************************************************************************/
    fn_ServiceFaultHandler (event, serviceName) {
      let msg = event.msgDesc || event.msgComm
      this.getStore('confirm').dispatch('ADD', serviceName + ', ' + msg)
      this.isState = '' // 현재 상태 초기화
    },
    /************************************************************************************************
     * Function명  : fn_getTodyTime
     * 설명        : 로컬 현재 날짜시간 구하기
     ************************************************************************************************/
    fn_getTodyTime () {
      let n = new Date()
      let y = '' + n.getFullYear()
      let m = (n.getMonth() + 1 < 10) ? '0' + (n.getMonth() + 1) : '' + (n.getMonth() + 1)
      let d = (n.getDate() < 10) ? '0' + n.getDate() : '' + n.getDate()
      let hh = (n.getHours() < 10) ? '0' + n.getHours() : '' + n.getHours()
      let mm = (n.getMinutes() < 10) ? '0' + n.getMinutes() : '' + n.getMinutes()
      let ss = (n.getSeconds() < 10) ? '0' + n.getSeconds() : '' + n.getSeconds()
      return y + m + d + hh + mm + ss
    },
    /******************************************************************************
    * Function명 : fn_OpenIdcTof
    * 설명       : 신분증 진위여부확인 팝업
    ******************************************************************************/
    fn_OpenIdcTof () {
      let custRrnId = (this.gafData && this.gafData.hoshInfoList[0] && this.gafData.hoshInfoList[0].custRrnId) ? this.gafData.hoshInfoList[0].custRrnId : ''
      let custNm = (this.gafData && this.gafData.hoshInfoList[0] && this.gafData.hoshInfoList[0].insrdNm) ? this.gafData.hoshInfoList[0].insrdNm : ''

      if (custRrnId !== '') {
        let knbFrno = custRrnId.substring(0, 6)
        const pop = this.$bottomModal.open(MSPPS061P, {
          properties: {
            segment: this.idCardType,
            custNm: custNm,
            knbFrno: knbFrno
          },
          listeners: {
            checkResult: (isSuccess) => {
              this.$bottomModal.close(pop)

              if (isSuccess) {
                this.isState = 'A' // 현재 상태 저장
                this.idcTofYn = 'Y'
                this.idcTofCnfDtm = this.fn_getTodyTime()
                this.fn_ServiceData('S2')
              }
            }
          }
        })
      } else {
        this.getStore('confirm').dispatch('ADD', '계약자 주민등록번호가 없습니다.')
      }
    },
    /******************************************************************************
    * Function명 : fn_ExeVirtualAccount
    * 설명       : 가상계좌 채번 신청하기 함수
    ******************************************************************************/
    fn_ExeVirtualAccount () {
      //this.isShowAlert = true
      this.fn_BottomConfirm(this.msgDesc)
    },
    /******************************************************************************
    * Function명 : fn_OnPositive
    * 설명       : 가상계좌 채번 알림 확인 컨트롤 함수
    ******************************************************************************/
    fn_OnPositive () {
      //this.isShowAlert = false
      this.isState = 'C' // 현재 상태 저장
      this.fn_ServiceData('S')
    },
    /******************************************************************************
    * Function명 : fn_GetAgreement
    * 설명       : 수익자 동의하기 함수
    ******************************************************************************/
    fn_GetAgreement () {
      this.isState = 'D' // 현재 상태 저장
      //this.isTSSPS185P = true
      const popMSPPS612P = this.$bottomModal.open(MSPPS612P, {
        properties: {
          itemData: this.bnfrInfoData, // 수익자 정보 - 612P에 넘겨줄 데이터
        },
        listeners: {
          // 대상팝업 confirmPopup $emit 이벤트명 
          confirmPopup: (rtnData) => {
            this.bnfrCnsntCnfCd = 'Y'
            this.fn_ServiceData('S2')
            this.$bottomModal.close(popMSPPS612P) // 모달 닫기
          },
          // 대상팝업 cancelPopup $emit 이벤트명 
          cancelPopup: (rtnData) => {
            if (rtnData.allUserAuthFinish) { // 동의 모두 완료 후 취소 버튼 클릭 시
               this.bnfrCnsntCnfCd = 'Y'
               this.fn_ServiceData('S2')
            } else { // 동의 일부 완료 후 취소버튼 클릭 시
               this.bnfrInfoData.bnfrInfoList = rtnData.list
               this.isState = '' // 현재 상태 초기화
            }
            this.$bottomModal.close(popMSPPS612P) // 모달 닫기
          }
        }
      })
    },
    /******************************************************************************
    * Function명 : fn_AsgnProxyClamGetAgreement
    * 설명       : 지정대리청구인 동의하기 함수
    ******************************************************************************/
    fn_AsgnProxyClamGetAgreement () {
      this.isState = 'AS' // 현재 상태 저장
      //this.isTSSPS187P = true
      const popMSPPS613P = this.$bottomModal.open(MSPPS613P, {
        properties: {
          itemData: this.asgnProxyClamzInfoData, // 지정대리청구 정보 - 613P에 넘겨줄 데이터
        },
        listeners: {
          // 대상팝업 confirmPopup $emit 이벤트명 
          confirmPopup: (rtnData) => {
            this.apcCnsntYn = 'Y'
            this.fn_ServiceData('S2')
            this.$bottomModal.close(popMSPPS613P) // 모달 닫기
          },
          // 대상팝업 confirmPopup $emit 이벤트명 
          cancelPopup: (rtnData) => {
            if (rtnData) { // 동의 모두 완료 후 취소 버튼 클릭 시
              this.apcCnsntYn = 'Y'
              this.fn_ServiceData('S2')
            }
            this.$bottomModal.close(popMSPPS613P) // 모달 닫기
          }
        }
      })
    },
    /******************************************************************************
    * 2021.06.22 ASR210501099_지대청 2인지정 현장접수 반영
    * Function명 : fn_AsgnProxyClamGetAgreement2
    * 설명       : 지정대리청구인2 동의하기 함수
    ******************************************************************************/
    fn_AsgnProxyClamGetAgreement2 () {
      this.isState = 'AS2' // 현재 상태 저장
      
      const popMSPPS613P = this.$bottomModal.open(MSPPS613P, {
        properties: {
          itemData: this.asgnProxyClamzInfoData2, // 지정대리청구2 정보 - 613P에 넘겨줄 데이터
        },
        listeners: {
          // 대상팝업 confirmPopup $emit 이벤트명 
          confirmPopup: (rtnData) => {
            this.apcCnsntYn02 = 'Y'
            this.fn_ServiceData('S2')
            this.$bottomModal.close(popMSPPS613P) // 모달 닫기
          },
          // 대상팝업 confirmPopup $emit 이벤트명 
          cancelPopup: (rtnData) => {
            if (rtnData) { // 동의 모두 완료 후 취소 버튼 클릭 시
              this.apcCnsntYn02 = 'Y'
              this.fn_ServiceData('S2')
            }
            this.$bottomModal.close(popMSPPS613P) // 모달 닫기
          }
        }
      })
    },
    /******************************************************************************
    * Function명 : fn_InputAeus
    * 설명       : AEUS 입력하기 함수
    ******************************************************************************/
    fn_InputAeus () {
      //PSAeusUtil.setObjMsg(this.$options.screenId, this.eltrnSaveData.elstPlanId)
      // PSAeusUtil.AEUSOpenService(this.$options.screenId, this.eltrnSaveData.elstPlanId , function(){
      //   //PSCommUtil.fn_AppPortrait(null,false) //App강제 세로방향        
      // })
      // this.isAeus2 = false // AEUS 완료확인 버튼 활성화
      // [2024.04.01 선심사 및 영업시스템 고도화 PJT]
      let popupMSPPI840P = this.$bottomModal.open(MSPPI840P, {
        properties: {
          pPage: this.name,
          pParams: {
            planId: this.eltrnSaveData.elstPlanId,
            vuchNo: this.eltrnSaveData.vuchId
          }
        },
        listeners: {
          closePopup: () => {
            this.$bottomModal.close(popupMSPPI840P)
          },
          resultPopup: () => {
            this.$bottomModal.close(popupMSPPI840P).then(() => {
              // AEUS 완료확인 버튼 활성화
              this.isAeus2 = false  
            })
          }
        }
      },
      {
        properties: {
          noHeader: true
        }
      }) 
    },
    /******************************************************************************
    * Function명 : fn_InputAeus
    * 설명       : AEUS 완료확인하기 함수
    ******************************************************************************/
    fn_InputAeusConfirm () {
      this.fn_ServiceData('S3')
    },
    /******************************************************************************
    * Function명 : fn_CelFocusInput1
    * 설명       : 휴대폰번호 Input 이벤트 컨트롤 함수
    ******************************************************************************/
    fn_CelFocusInput1 (event) {
      if (this.phone1 && this.phone1.trim() !== '') {
        this.phone1 = this.$bizUtil.telNoWithHyphen(this.phone1)
      }
    },
    /******************************************************************************
    * Function명 : fn_CelFocusInput2
    * 설명       : 휴대폰번호 Input 이벤트 컨트롤 함수
    ******************************************************************************/
    fn_CelFocusInput2 (event) {
      if (this.phone2 && this.phone2.trim() !== '') {
        this.phone2 = this.$bizUtil.telNoWithHyphen(this.phone2)
      }
    },
    /************************************************************************************************
     * Function명  : fn_StrByteLength
     * 설명        : 친권자명 자리수 체크
     ************************************************************************************************/
    fn_StrByteLength (s) {
      return s.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, '$&$1$2').length
    },
    /******************************************************************************
    * Function명 : fn_Confirm
    * 설명       : 확인버튼 선택 시 호출
    ******************************************************************************/
    fn_Confirm () {
      if (this.name1 !== this.eltrnSaveData.prtrPesonNm01.trim() ||
          this.name2 !== this.eltrnSaveData.prtrPesonNm02.trim() ||
          this.phone1 !== this.eltrnSaveData.prtrPesonTelnoEncr01.trim() ||
          this.phone2 !== this.eltrnSaveData.prtrPesonTelnoEncr02.trim()) {
        this.name1 = this.name1.trim()
        this.name2 = this.name2.trim()
        this.phone1 = this.phone1.trim()
        this.phone2 = this.phone2.trim()
        // 2021.05.20 ASR210400542_사랑ON 친권인 글자수 확대 (15byte --> 30byte)
        if (this.fn_StrByteLength(this.name1) > 30 || this.fn_StrByteLength(this.name2) > 30) { // DB사이즈가 30byte
          this.getStore('confirm').dispatch('ADD', '친권자 성명은 한글 10자, 영문 30자 이하여야 합니다.')
        } else {
          this.isState = 'B' // 현재 상태 저장
          this.fn_ServiceData('S2')
        }
      } else {
        this.$emit('confirmPopup')
      }
    },
    /******************************************************************************
    * Function명 : fn_Cancel
    * 설명       : 취소버튼 선택 시 호출
    ******************************************************************************/
    fn_Cancel () {
      this.$emit('cancelPopup')
    },
    /******************************************************************************
    * Function명 : fn_AuthPass
    * 설명       : 신분증 인증통과
    ******************************************************************************/
    fn_AuthPass () {
      this.isState = 'A' // 현재 상태 저장
      this.idcTofYn = 'Y'
      this.idcTofCnfDtm = this.fn_getTodyTime()
      this.fn_ServiceData('S2')
    },

    /************************************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ************************************************************************************************/
    fn_DevicBackBtnHandler611P() {
      //if(PSCommUtil.fn_IsActiveModal()) return
      this.fn_HeaderBackBtnHandler()
    },

    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : 헤더 이전버튼 서비스 호출 함수
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
      this.fn_BottomAlert('청약접수를 종료하시겠습니까?') 
    },// fn_HeaderBackBtnHandler

    /******************************************************************************
    * Function명  : fn_BottomAlert
    * 설명        : 확인 팝업
    ******************************************************************************/
    fn_BottomAlert (contents) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title: "알림",
          content: contents,
          title_pos_btn: '확인',
          title_neg_btn: '취소'
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(this.lv_AlertPop)
            this.fn_Cancel()
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      })
    },

    /******************************************************************************
    * Function명  : fn_BottomConfirm
    * 설명        : Confirm 팝업
    ******************************************************************************/
    fn_BottomConfirm (contents) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: false,
          title: "알림",
          content: contents,
          title_pos_btn: "확인",
          title_neg_btn: "취소"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.fn_OnPositive()
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      })
    },


  /*---------------------------------------------------------------------------------
  * Data Service 핸들러 영역
  ---------------------------------------------------------------------------------*/


  
  /*---------------------------------------------------------------------------------
  * 기타 Util 영역
  ---------------------------------------------------------------------------------*/



  //--------------------------------------------------------------------------------
    _fn_End(){return}//코딩 종료 함수 마지막 메서드 함수 , 없음 주의
 },// methods

}//export default


</script>